import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {useAppDispatch} from 'store/hooks';
import {getExtrasOption} from 'store/venue/actions';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';

export function useCommonExtras() {
  const dispatch = useAppDispatch();

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const isLoading = useSelector((state) =>
    _get(state, 'venue.resourcesFormLoading'),
  );
  const error = useSelector((state) => _get(state, 'venue.extrasError'));

  useEffect(() => {
    if (extrasOption.length || isLoading || error) return;

    dispatch(
      getExtrasOption([
        EResourcesType.EQUIPMENT,
        EResourcesType.FOOD_AND_BEVERAGE,
        EResourcesType.BEDROOM,
      ]),
    );
  }, [dispatch, error, extrasOption.length, isLoading]);

  return [extrasOption];
}
