import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';
import InputRadioButton from '../../../components/NW2FormItem/components/Input/InputRadioButton';

import {NW2BookingInviteAttendees} from '../components/NW2BookingInviteAttendees';

import {useAppSelector} from 'store/hooks';
import {EAccommodationType} from 'types/dto/IPublicVenue';

import {EBookingConfirmationModal} from '../types';
import {getShowCancelBookingButton} from 'utils/venueUtils';
import {
  Container,
  StyledNW2Button,
} from '../components/NW2BookingConfirmationActionLinks/NW2BookingConfirmationActionLinks.styles';
import {useActions} from 'hooks/useActions';

import {fontSizeMd, offsetDef, offsetXLg} from 'styles/configs';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {CancellationPolicyBlock} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import {ERoomType} from 'types/dto/ERoomType.type';
import CancelPolicyWarnCard from 'view/components/CancelPolicyWarnCard/CancelPolicyWarnCard';
import {ECancelPolicyInfoCardComponent} from 'view/components/CancelPolicyInfoCard/types';

enum ERTCCancelOptions {
  CANCEL_REQUEST_TO_CHANGE = 'CANCEL_REQUEST_TO_CHANGE',
  CANCEL_BOOKING = 'CANCEL_BOOKING',
}

const RadioButtonsWrapper = styled.div`
  & > *:not(& > *:last-child) {
    margin-bottom: ${offsetDef};
  }
`;

const Subtitle = styled.div`
  margin-bottom: ${offsetXLg};
  font-size: ${fontSizeMd};
`;

export const useBookingConfirmationModal = (): {
  isModalShowed: boolean;
  onModalShow: (arg: EBookingConfirmationModal) => void;
  onModalClose: () => void;
  modalTitle: string | null;
  modalBody: ReactNode | null;
  modalFooter: ReactNode | null;
} => {
  const navigate = useNavigate();

  const {
    cancelOrderAction,
    cancelRTCOrderAction,
    cancelRTCOrderAllAction,
    requestOfferDecline,
  } = useActions();

  const bookingOrderId = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderId,
  );
  const bookingOrderNumber = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderNumber,
  );
  const bookingStatus = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.bookingStatus,
  );
  const isRfp = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.isRfp,
  );
  const orderDays = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderDays,
  );

  const unitBookings = orderDays && orderDays[0].unitBookings;
  const requestId = useAppSelector(({offers}) => offers.offerDetails.id);

  const isCancellationFreeOfCharge = !!useAppSelector(
    ({customer}) => customer.customerBookingOrder?.refundAmount,
  );

  const isWorkSpace =
    unitBookings &&
    unitBookings[0]?.unitInfo?.roomType === ERoomType.WORK_SPACE;

  const {venueDetails} = useVenueDetailsData();

  const capacitySingleRoom =
    (unitBookings && unitBookings[0]?.participants) || 1;

  const bookingIdSingleRoom = unitBookings && unitBookings[0]?.unitBookingId;
  const isPublicVenue =
    venueDetails.accommodationType === EAccommodationType.VENUE;

  const [showedModal, setShowedModal] =
    useState<EBookingConfirmationModal | null>(null);

  const [chosenCancelOption, setChosenCancelOption] =
    useState<ERTCCancelOptions>(ERTCCancelOptions.CANCEL_REQUEST_TO_CHANGE);

  const query = useMemo(() => new URLSearchParams(location.search), []);
  const isCancelPopupOpened =
    query.get('openCancelPopup') === 'true' &&
    getShowCancelBookingButton(bookingStatus);

  useEffect(() => {
    if (isCancelPopupOpened) {
      setShowedModal(EBookingConfirmationModal.CANCEL_BOOKING_MODAL);
    }
  }, [isCancelPopupOpened]);

  const onModalShow = (modalId: EBookingConfirmationModal) => {
    setShowedModal(modalId);
  };

  const onModalClose = useCallback(() => {
    if (isCancelPopupOpened) {
      query.set('openCancelPopup', 'false');
      navigate({
        search: query.toString(),
      });
    }
    setShowedModal(null);
  }, [navigate, isCancelPopupOpened, query]);

  const onCancelBooking = useCallback(() => {
    if (!bookingOrderId) return;
    cancelOrderAction(bookingOrderId);
    onModalClose();
  }, [bookingOrderId, cancelOrderAction, onModalClose]);

  const onCancelRequestToChange = useCallback(() => {
    if (!bookingOrderId) return;
    cancelRTCOrderAction(bookingOrderId);
    onModalClose();
  }, [bookingOrderId, cancelRTCOrderAction, onModalClose]);

  const onCancelRequestToChangeAllBooking = useCallback(() => {
    if (!bookingOrderNumber) return;
    cancelRTCOrderAllAction(bookingOrderNumber);
    onModalClose();
  }, [bookingOrderNumber, cancelRTCOrderAllAction, onModalClose]);

  const onDeclineRequest = useCallback(() => {
    if (!requestId) return;
    requestOfferDecline(requestId);
    onModalClose();
  }, [onModalClose, requestId, requestOfferDecline]);

  const {modalTitle, modalBody, modalFooter} = useMemo(() => {
    const rtcCancelMap = {
      [ERTCCancelOptions.CANCEL_REQUEST_TO_CHANGE]: {
        actionButtonText: 'Cancel changes',
        label: 'Cancel change request',
        value: ERTCCancelOptions.CANCEL_REQUEST_TO_CHANGE,
        onCancelHandler: onCancelRequestToChange,
      },
      [ERTCCancelOptions.CANCEL_BOOKING]: {
        actionButtonText: 'Cancel booking',
        label: 'Cancel the entire booking',
        value: ERTCCancelOptions.CANCEL_BOOKING,
        onCancelHandler: onCancelRequestToChangeAllBooking,
      },
    };

    switch (showedModal) {
      case EBookingConfirmationModal.SHARE_BOOKING: {
        return {
          modalTitle: 'share booking',
          modalBody: (
            <NW2BookingInviteAttendees
              bookingOrderId={bookingIdSingleRoom}
              capacity={capacitySingleRoom || 1}
              onClose={onModalClose}
            />
          ),
        };
      }

      case EBookingConfirmationModal.CANCEL_BOOKING_MODAL:
        return {
          modalTitle: `Cancel ${isRfp ? 'request-to-book' : 'booking'}`,
          modalBody: (
            <div>
              <p>
                <span>{`You are about to cancel this ${
                  isRfp ? 'request-to-book' : 'booking'
                }.`}</span>
                <b> This action is irreversible.</b>
              </p>
              {isPublicVenue &&
                (isWorkSpace ? (
                  !isCancellationFreeOfCharge && (
                    <CancelPolicyWarnCard
                      componentType={
                        ECancelPolicyInfoCardComponent.ACTION_CARD_CLOSABLE
                      }
                      unitData={unitBookings}
                    />
                  )
                ) : (
                  <CancellationPolicyBlock
                    orderNumber={bookingOrderNumber}
                    isSimpleView
                    isCanceledStatus={isRfp}
                  />
                ))}
            </div>
          ),
          modalFooter: (
            <Container>
              <StyledNW2Button
                buttonType='secondary'
                onClick={onCancelBooking}
                size='small'
                inline
              >
                Cancel booking
              </StyledNW2Button>
              <NW2Button
                buttonType='primary'
                onClick={onModalClose}
                size='small'
                inline
              >
                Keep booking
              </NW2Button>
            </Container>
          ),
        };

      case EBookingConfirmationModal.CANCEL_RTC_BOOKING_MODAL:
        return {
          modalTitle: `Cancelling booking`,
          modalBody: (
            <div>
              <Subtitle>
                Would you like to cancel the latest booking changes that you
                requested or the entire booking?
              </Subtitle>
              <RadioButtonsWrapper>
                {Object.values(rtcCancelMap).map(({label, value}) => (
                  <InputRadioButton
                    key={value}
                    name='rtcCancelOption'
                    checked={chosenCancelOption === value}
                    label={label}
                    value={value}
                    onChange={() => {
                      setChosenCancelOption(value);
                    }}
                  />
                ))}
              </RadioButtonsWrapper>
            </div>
          ),
          modalFooter: (
            <Container>
              <StyledNW2Button
                buttonType='secondary'
                onClick={rtcCancelMap[chosenCancelOption].onCancelHandler}
                size='small'
                inline
              >
                {rtcCancelMap[chosenCancelOption].actionButtonText}
              </StyledNW2Button>
              <NW2Button
                buttonType='primary'
                onClick={onModalClose}
                size='small'
                inline
              >
                Keep booking
              </NW2Button>
            </Container>
          ),
        };

      case EBookingConfirmationModal.CANCEL_REQUEST_MODAL:
        return {
          modalTitle: 'Cancel request',
          modalBody: (
            <div>
              <div>
                <span>You are about to cancel this request.</span>
                <br />
                <b> This action is irreversible.</b>
              </div>
            </div>
          ),
          modalFooter: (
            <Container>
              <StyledNW2Button
                buttonType='secondary'
                onClick={onDeclineRequest}
                size='small'
                inline
              >
                Cancel request
              </StyledNW2Button>
              <NW2Button
                buttonType='primary'
                onClick={onModalClose}
                size='small'
                inline
              >
                Keep request
              </NW2Button>
            </Container>
          ),
        };

      default: {
        return {
          modalTitle: null,
          modalBody: null,
          modalFooter: null,
        };
      }
    }
  }, [
    bookingIdSingleRoom,
    bookingOrderNumber,
    capacitySingleRoom,
    chosenCancelOption,
    isCancellationFreeOfCharge,
    isPublicVenue,
    isRfp,
    isWorkSpace,
    onCancelBooking,
    onCancelRequestToChange,
    onCancelRequestToChangeAllBooking,
    onDeclineRequest,
    onModalClose,
    showedModal,
    unitBookings,
  ]);

  return {
    isModalShowed: !!showedModal,
    onModalShow,
    onModalClose,
    modalTitle,
    modalBody,
    modalFooter,
  };
};
