import {DateTime} from 'luxon';

import {IDay} from 'types/dto/IDay.type';
import {
  ITimeData,
  SEARCH_DEFAULT_END_TIME,
  SEARCH_DEFAULT_START_TIME,
} from 'types/dto/ISearch.types';
import DateUtils, {getISOString} from 'utils/dateUtils';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EEventType} from 'types/venue';

export const TIME_START_HOUR = '9';
export const TIME_START_MINUTES = '0';
export const TIME_END_HOUR = '20';
export const TIME_END_MINUTES = '0';

export const defaultTimeSet = {
  timeStartHour: TIME_START_HOUR,
  timeStartMinutes: TIME_START_MINUTES,
  timeEndHour: TIME_END_HOUR,
  timeEndMinutes: TIME_END_MINUTES,
};

export const defaultOperationalTimes: IDay[] = [
  {
    id: 0,
    open: false,
    dayOfWeek: 'MONDAY',
    active: false,
    ...defaultTimeSet,
  },
  {
    id: 0,
    open: false,
    dayOfWeek: 'TUESDAY',
    active: false,
    ...defaultTimeSet,
  },
  {
    id: 0,
    open: false,
    dayOfWeek: 'WEDNESDAY',
    active: false,
    ...defaultTimeSet,
  },
  {
    id: 0,
    open: false,
    dayOfWeek: 'THURSDAY',
    active: false,
    ...defaultTimeSet,
  },
  {
    id: 0,
    open: false,
    dayOfWeek: 'FRIDAY',
    active: false,
    ...defaultTimeSet,
  },
  {
    id: 0,
    open: false,
    dayOfWeek: 'SATURDAY',
    active: false,
    ...defaultTimeSet,
  },
  {
    id: 0,
    open: false,
    dayOfWeek: 'SUNDAY',
    active: false,
    ...defaultTimeSet,
  },
];

const dateNow = DateTime.now();

export const checkInTime = getISOString(dateNow.set(SEARCH_DEFAULT_START_TIME));
export const checkOutTime = getISOString(dateNow.set(SEARCH_DEFAULT_END_TIME));

const DEFAULT_MR_DAYS_INCREMENT = 7;

export const getDefaultCheckIn = (roomType: ERoomType) => {
  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;

  if (isMeetingRoom) {
    return DateUtils.getISOFromDate(
      DateUtils.addDays(checkInTime, DEFAULT_MR_DAYS_INCREMENT),
    );
  }

  return checkInTime;
};

export const getDefaultCheckOut = (roomType: ERoomType) => {
  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;

  if (isMeetingRoom) {
    return DateUtils.getISOFromDate(
      DateUtils.addDays(checkOutTime, DEFAULT_MR_DAYS_INCREMENT),
    );
  }

  return checkOutTime;
};

export const getDefaultTimeData = (startDate: string): ITimeData => {
  const initialStartDate = DateTime.fromISO(startDate);

  return {
    timeStart: getISOString(initialStartDate.set(SEARCH_DEFAULT_START_TIME)),
    timeEnd: getISOString(initialStartDate.set(SEARCH_DEFAULT_END_TIME)),
    eventType: EEventType.DAY,
  };
};
