import DateUtils, {getDateTime, getRoomTimeRangeText} from 'utils/dateUtils';

import {
  ITimeData,
  SEARCH_DEFAULT_END_TIME,
  SEARCH_DEFAULT_START_TIME,
  TDateType,
} from 'types/dto/ISearch.types';
import {
  TDay,
  IData,
  TGroupRooms,
  TMeetingRoomItem,
  TSearchCriteriaExtra,
  TSearchVenuesDay,
  TSelectedOptions,
  TSummaryExtra,
} from 'types/search';
import {
  EBedroomExtras,
  EBedroomExtrasNames,
  EDefaultExtras,
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
} from 'types/dto/IExtras.type';
import {
  TBedroomsCatering,
  TBedroomsCateringItem,
} from './components/AddAccommodationRequest/types';
import {getAccommodationData} from './components/AddAccommodationRequest/helpers';
import {getISOString} from 'utils/dateUtils';
import {EEventType, ERoomSchemaNames} from 'types/venue';
import {BEDROOM_EXTRAS_NAMES_TO_ID, oppositeIds} from 'constants/bedrooms';
import {getAccommodationParticipants} from './components/utils';
import {ELocale} from 'types/locale';

const {findMinMaxTime, normalizeDateToBackendFormat} = DateUtils;

export const getDayTimeRangeText = (
  rooms: {
    timeStart: TDateType | string;
    timeEnd: TDateType | string;
  }[],
  locale = ELocale.EN_UK,
) => {
  if (!rooms.length) return '';
  const timeStart = findMinMaxTime(rooms, 'timeStart', false);
  const timeEnd = findMinMaxTime(rooms, 'timeEnd', true);

  return getRoomTimeRangeText(timeStart, timeEnd, locale);
};

const makeSummaryExtrasData = (extrasData: TSelectedOptions | null) => {
  if (!extrasData) return null;

  const optionsObjById = extrasData.options.reduce((prev, {id, name}) => {
    return {...prev, [id]: name};
  }, {} as Record<string, string>);

  return Object.entries(extrasData.data).reduce((prev, [textId, count]) => {
    const id = textId.slice(0, -1);
    const extraName = optionsObjById[id];
    const countText = id === EDefaultExtras.wifi.toString() ? '-' : `x${count}`;
    return extraName && extrasData.ids.includes(+id)
      ? [...prev, [extraName, countText]]
      : prev;
  }, [] as any);
};

export const makeSummaryData = (meetingRequestData: TDay[]) =>
  meetingRequestData.map(
    ({
      startDate,
      endDate,
      rooms,
      foodBeverageData,
      isSectionExpanded,
      accommodationData,
      isPreMeet,
      isPostMeet,
      participants,
    }) => {
      const foodBeverage = makeSummaryExtrasData(foodBeverageData);
      const accommodation = makeSummaryExtrasData(accommodationData);

      const roomsData = rooms.length
        ? rooms.reduce(
            (
              prev,
              {timeStart, timeEnd, participants, seatsSetup, equipmentData},
            ) => {
              const equipments = makeSummaryExtrasData(equipmentData);
              return [
                ...prev,
                {
                  checkIn: timeStart,
                  checkOut: timeEnd,
                  participants,
                  seatsSetup,
                  equipments,
                },
              ];
            },
            [] as TSummaryExtra[],
          )
        : [];

      return {
        startDate,
        endDate,
        roomsData,
        foodBeverage,
        accommodation,
        isPreMeet,
        isPostMeet,
        isSectionExpanded,
        dayParticipants: participants,
      };
    },
  );

const setExtraPayloadData = (
  options: TSelectedOptions,
  extraType?: EResourcesType,
): TSearchCriteriaExtra[] =>
  options?.ids
    ? options.ids.map((extraId) => ({
        extraId,
        code: options.options.find(({id}) => id === extraId)
          ?.code as EResourcesCode,
        quantity: options.data[`${extraId}_`],
        ...(extraType ? {extraType} : {}),
      }))
    : [];

interface IMakeMultiSearchPayload {
  meetingRequestData: TDay[];
  timeDataFromStore?: ITimeData[];
  bedroomsCatering?: TBedroomsCatering;
  filteredBedroomExtras?: IExtrasOption[];
}
export const makeMultiSearchPayload = ({
  meetingRequestData,
  timeDataFromStore,
  bedroomsCatering,
  filteredBedroomExtras,
}: IMakeMultiSearchPayload) => {
  const timeData: ITimeData[] = [];
  const updRequestData: TDay[] = [];

  const makeData = ({
    requestData,
    rooms,
    foodBeverageData,
    accommodationData,
    startDate,
    endDate,
    maxParticipants,
    dayIdx,
  }: {
    requestData: TSearchVenuesDay[];
    rooms: TMeetingRoomItem[];
    foodBeverageData: TSelectedOptions | null;
    accommodationData: TSelectedOptions | null;
    startDate: string;
    endDate: string;
    maxParticipants: number;
    dayIdx: number;
  }): TSearchVenuesDay[] => [
    ...requestData,
    {
      foodAndBeverage: foodBeverageData?.ids
        ? setExtraPayloadData(foodBeverageData)
        : [],
      bedrooms:
        accommodationData?.ids &&
        bedroomsCatering?.[startDate] &&
        filteredBedroomExtras
          ? setExtraPayloadData(
              getAccommodationData({
                accommodationData,
                bedroomsCateringDay: bedroomsCatering[startDate],
                filteredBedroomExtras,
              }) as TSelectedOptions,
              EResourcesType.BEDROOM,
            )
          : [],
      checkIn: startDate,
      checkOut: endDate,
      maxParticipants,
      eventType: EEventType.DAY,
      roomFilters: rooms.map(
        (
          {participants, timeEnd, timeStart, equipmentData, seatsSetup},
          roomIdx,
        ) => ({
          checkIn: normalizeDateToBackendFormat(timeStart),
          checkOut: normalizeDateToBackendFormat(timeEnd),
          capacity: participants,
          setupStyle: seatsSetup,
          position:
            meetingRequestData.reduce((acc, day, calcDayIdx) => {
              if (calcDayIdx < dayIdx) {
                acc += day.rooms.length;
              }
              return acc;
            }, 0) + roomIdx,
          extras: equipmentData.ids ? setExtraPayloadData(equipmentData) : [],
        }),
      ),
    },
  ];

  const makeArrivalsData = ({
    requestData,
    accommodationData,
    date,
    eventType,
  }: {
    requestData: TSearchVenuesDay[];
    accommodationData: TSelectedOptions | null;
    date: string;
    eventType: EEventType;
  }): TSearchVenuesDay[] => [
    ...requestData,
    {
      checkIn: normalizeDateToBackendFormat(
        getISOString(getDateTime(date).set(SEARCH_DEFAULT_START_TIME)),
      ),
      checkOut: normalizeDateToBackendFormat(
        getISOString(getDateTime(date).set(SEARCH_DEFAULT_END_TIME)),
      ),
      eventType,
      bedrooms:
        accommodationData?.ids &&
        bedroomsCatering?.[date] &&
        filteredBedroomExtras
          ? setExtraPayloadData(
              getAccommodationData({
                accommodationData,
                bedroomsCateringDay: bedroomsCatering[date],
                filteredBedroomExtras,
              }) as TSelectedOptions,
              EResourcesType.BEDROOM,
            )
          : [],
    },
  ];

  const multiSearchPayload = meetingRequestData?.reduce(
    (
      requestData,
      {
        rooms,
        foodBeverageData,
        accommodationData,
        isPostMeet,
        isPreMeet,
        startDate,
        endDate,
        participants,
      },
      dayIdx,
    ) => {
      const isPreOrPostMeet = isPreMeet || isPostMeet;

      //TODO: major change, delete after testing new Search template logic
      // if (!rooms?.length && !isPreOrPostMeet) {
      //   return requestData;
      // }

      if (timeDataFromStore?.[dayIdx]) timeData.push(timeDataFromStore[dayIdx]);

      updRequestData.push(meetingRequestData[dayIdx]);

      if (isPreOrPostMeet) {
        return makeArrivalsData({
          requestData,
          accommodationData,
          date: startDate,
          eventType: isPreMeet ? EEventType.PRE_ARRIVAL : EEventType.POST_EVENT,
        });
      }

      return makeData({
        requestData,
        rooms,
        foodBeverageData,
        accommodationData,
        startDate,
        endDate,
        maxParticipants: participants,
        dayIdx,
      });
    },
    [] as TSearchVenuesDay[],
  );

  return {
    timeData,
    multiSearchPayload,
    requestData: updRequestData,
  };
};

export const makeFastSearchFilterDaysPayload = (
  multiSearchPayload: TSearchVenuesDay[],
  setupStyle?: ERoomSchemaNames,
) =>
  multiSearchPayload.map((day) => ({
    ...day,
    roomFilters: day.roomFilters?.map((room) => ({
      ...room,
      extras: [],
      ...(setupStyle ? {setupStyle} : {}),
    })),
  }));

export const makeGroupsFastSearchFilterDaysPayload = ({
  multiSearchPayload,
  groupsRooms,
  extrasOption,
}: {
  multiSearchPayload: TSearchVenuesDay[];
  groupsRooms: TGroupRooms;
  extrasOption: IExtrasOption[];
}) =>
  multiSearchPayload.map((item) => ({
    ...item,
    maxParticipants: 0,
    roomFilters: [],
    bedrooms: Object.entries(groupsRooms)
      .map(([key, value]) => {
        let code = '';

        if (key === 'single' && value.isEnabled) {
          code = value.withBreakfast
            ? EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST
            : EResourcesCode.SINGLE_BEDROOM;
        }

        if (key === 'double' && value.isEnabled) {
          code = value.withBreakfast
            ? EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST
            : EResourcesCode.DOUBLE_BEDROOM;
        }

        if (!code) return false;

        const extraId = extrasOption.find((item) => item.code === code)?.id;

        return {
          extraId,
          code,
          quantity: value.qty,
          extraType: EResourcesType.BEDROOM,
        };
      })
      .filter(Boolean) as TSearchCriteriaExtra[],
  }));

export const convertExtraNameToEnum = (name?: string) => {
  if (!name) return '';
  const generalReg = /\s|-/g;
  const roundBracketsReg = /[(|)]/g;
  return name
    .toLowerCase()
    .replaceAll(generalReg, '_')
    .replaceAll(roundBracketsReg, '');
};

export const getPreselectedExtrasIds = (
  extraNames: string[],
  allExtra: IExtrasOption[],
) =>
  allExtra
    .filter(({name}) => extraNames.includes(convertExtraNameToEnum(name)))
    .map(({id}) => id);

export const datePlusDays = (date: string, daysOffset: number) => {
  return getISOString(getDateTime(date).plus({days: daysOffset}));
};

export const getDayCopy = (
  meetingRequestData: TDay[],
  dayIndex: number,
  daysOffset: number,
): TDay => {
  const isPreMeet = daysOffset === -1;
  const extraDayData = meetingRequestData[dayIndex];

  //accommodationData for pre or post events
  const daysMeetingRequestData = meetingRequestData.filter(
    ({isPreMeet, isPostMeet}) => !isPreMeet && !isPostMeet,
  );
  const firstOrLastDay =
    daysMeetingRequestData[
      isPreMeet ? dayIndex : daysMeetingRequestData.length - 1
    ];

  const participants = getAccommodationParticipants(firstOrLastDay);

  return {
    ...extraDayData,
    startDate: datePlusDays(extraDayData.startDate, daysOffset),
    foodBeverageData: null,
    accommodationData: firstOrLastDay.accommodationData,
    isSectionExpanded: true,
    isPreMeet,
    participants,
    isPostMeet: !isPreMeet,
    rooms: [],
  };
};

export const getTimeDataItem = (
  timeDataItem: ITimeData,
  daysOffset: number,
): ITimeData => ({
  timeStart: datePlusDays(timeDataItem.timeStart, daysOffset),
  timeEnd: datePlusDays(timeDataItem.timeEnd, daysOffset),
  eventType: daysOffset === 1 ? EEventType.POST_EVENT : EEventType.PRE_ARRIVAL,
});

export const updateFormDataByCatering = (
  formData: Record<string, any>,
  singleRoomDay: TBedroomsCateringItem,
  doubleRoomDay: TBedroomsCateringItem,
): IData => {
  const getCateringId = (day: TBedroomsCateringItem) => {
    return day.isEnabled
      ? BEDROOM_EXTRAS_NAMES_TO_ID[
          (day.preName as EBedroomExtrasNames) ?? day.name
        ]
      : undefined;
  };

  const newIds = [
    getCateringId(singleRoomDay),
    getCateringId(doubleRoomDay),
  ].filter(Boolean) as number[];

  const numberedKeysEntries = Object.entries(formData).filter(([key]) =>
    key.includes('_'),
  );

  const newFormData = numberedKeysEntries.reduce((acc, [key, value]) => {
    const numberKey = +key.replace('_', '');

    if (!newIds.includes(numberKey)) {
      const newKey = oppositeIds[numberKey as EBedroomExtras];
      return {...acc, [`${newKey}_`]: value};
    }

    return {...acc, [key]: value};
  }, {});

  return {...newFormData, accommodation: newIds};
};
