import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useFormState} from 'react-final-form';
import styled from 'styled-components';

import NW2SummaryBlock from 'view/components/NW2SummaryBlock';
import {LinkTerms} from 'view/components/NW2SummaryBlock/components/LinkTerms';
import {ButtonConfirm} from './ButtonConfirm';
import {useBookingSummaryContent} from 'view/venue/components/NW2BookingSummary/hooks';

import {useAppSelector} from 'store/hooks';
import {IUnit} from 'types/dto/IUnit.types';
import {ICustomerBookedUnits, ISummaryExtra} from 'types/dto/IBooking.types';
import {
  setPreviewTotalAmount,
  setPreviewTotalDrawerVisible,
} from 'store/bookingsCustomer/bookingsCustomerSlice';
import {getPreviewEditTotalPrice} from '../helpers';
import {findRfpStatus} from 'utils/venueUtils';
import DRAWER_CONFIG from 'view/components/NW2SummaryBlock/NW2SummaryBlock.config';

import {
  blackColor,
  nw2TotalDrawerZIndex,
  offsetDef,
  offsetXSm,
} from 'styles/configs';

const FooterMobile = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${blackColor};
  height: ${DRAWER_CONFIG.TOTAL_FOOTER_HEIGHT}px;
  padding: ${offsetXSm} ${offsetDef};
  z-index: ${Number(nw2TotalDrawerZIndex) + 1};
`;

const FooterMobileInner = styled.div`
  height: 100%;
`;

interface IProps {
  checkInDate?: string;
  checkOutDate?: string;
  currency: string;
  venueAddress: string;
  venueName: string;
  venueCoverImage: {
    url: string;
    name: string;
  };
  venueUnits: IUnit[];
  previewUnits: ICustomerBookedUnits[];
  isOffice: boolean;
  isSubmitActive: boolean;
}

const TotalBlock = ({
  currency,
  venueUnits,
  previewUnits,
  venueCoverImage,
  venueName,
  venueAddress,
  isOffice,
  isSubmitActive,
}: IProps) => {
  const dispatch = useDispatch();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const bookingOrderTotalPrice = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.totalPrice,
  );

  const isDrawerShowed = useAppSelector(
    ({bookingsCustomer}) => bookingsCustomer.isTotalDrawerVisible,
  );

  const formState = useFormState();
  const {submitting} = formState;

  const getPreviewExtras = useCallback(() => {
    const extras: ISummaryExtra[] = [];
    previewUnits.forEach((venueUnit) => {
      venueUnit.unitBookings.forEach((unit) => {
        unit.chosenExtras?.forEach((extra) => {
          extras.push(extra);
        });
      });
    });
    return extras;
  }, [previewUnits]);

  const getPreviewTotalPriceMemo = useCallback(
    () => getPreviewEditTotalPrice(previewUnits),
    [previewUnits],
  );

  const extras = getPreviewExtras();

  const extrasCount = extras.length;

  const totalAmount = getPreviewTotalPriceMemo();

  useEffect(() => {
    dispatch(setPreviewTotalAmount({value: totalAmount, currency}));
  }, [dispatch, totalAmount, currency]);

  const onToggleDrawer = useCallback(() => {
    dispatch(setPreviewTotalDrawerVisible(!isDrawerShowed));
  }, [dispatch, isDrawerShowed]);

  const isRfp = venueUnits?.some(findRfpStatus);

  const {
    headerSection,
    totalPriceSection,
    unitsSection,
    totalDiffPriceSection,
  } = useBookingSummaryContent({
    currency,
    isRfp,
    unitBookings: previewUnits,
    isTotalPriceVisible: !isOffice,
    totalPrice: totalAmount,
    prevTotalPrice: bookingOrderTotalPrice || 0,
    venueAddress,
    venueName,
    venueCoverImage,
    submitting,
    showTimeRangeAndParticipants: true,
    editMode: true,
  });

  const buttonConfirm = (
    <ButtonConfirm
      isMobile={isMobile}
      isOffice={isOffice}
      isDrawerShowed={isDrawerShowed}
      extrasCount={extrasCount}
      isSubmitActive={isSubmitActive}
    />
  );

  const termsText = isRfp
    ? 'Cancellation policy becomes binding once the request is confirmed by the venue.' +
      ' By proceeding you accept the HRS '
    : 'By proceeding you accept HRS ';

  return (
    <>
      <NW2SummaryBlock
        isMobile={isMobile}
        isDrawerShowed={isDrawerShowed}
        setIsDrawerShowed={onToggleDrawer}
        headerSection={headerSection}
        totalPriceSection={
          <>
            {totalPriceSection}
            {!isOffice && isMobile && <LinkTerms text={termsText} />}
          </>
        }
        totalDiffPriceSection={totalDiffPriceSection}
        buttonConfirm={buttonConfirm}
        footerMobileContent={
          <FooterMobile>
            <FooterMobileInner>{buttonConfirm}</FooterMobileInner>
          </FooterMobile>
        }
        unitsSection={unitsSection}
        linkTermsText={termsText}
      />
    </>
  );
};

export default TotalBlock;
