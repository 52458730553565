import React, {FC} from 'react';
import styled from 'styled-components';

import AddUserButton from '../AddUserButton';
import UsersTable from '../UsersTable';
import Pagination from '../Pagination';

import {offsetXLg} from 'constants/styleVars';
import {BlockTitle} from '../../../../components/Typography';

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${offsetXLg};
`;

const CompanyUsers: FC<{companyId: number}> = ({companyId}) => {
  return (
    <>
      <ButtonBlock>
        <AddUserButton companyId={companyId} />
      </ButtonBlock>
      <BlockTitle>Associated Employees</BlockTitle>
      <UsersTable />
      <Pagination />
    </>
  );
};

export default CompanyUsers;
