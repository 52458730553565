import styled from 'styled-components';

import {
  borderRadiusMLg,
  fontSizeMd,
  fontSizeSm,
  fontSizeXXLg,
  fontWeightBold,
  lineHeightLg,
  NW2ErrorLight500Color,
  NW2Warn50Color,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXXXSm,
} from 'constants/styleVars';
import {Wrapper} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';

export const StyledWrapper = styled(Wrapper)`
  overflow: hidden auto;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
`;

export const HowLongBox = styled.div`
  display: flex;
  gap: ${offsetSm};
  padding: ${offsetSm} ${offsetDef};
  background-color: ${NW2Warn50Color};
  border-radius: ${borderRadiusMLg};
  margin-bottom: ${offsetXLg};
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const PromoTitle = styled.h3`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  margin: 0 0 ${offsetDef};
`;

export const PromoText = styled.p`
  font-size: ${fontSizeSm};
  line-height: ${fontSizeXXLg};
  margin: 0 0 ${offsetXLg};

  &:last-of-type {
    margin: 0;
  }
`;

export const AmenitiesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${offsetXLg};
`;

export const PricesContainer = styled.div`
  padding: 0 ${offsetXLg};
`;

export const ModalExitBody = styled.div`
  display: grid;
  grid-template-columns: ${offsetDef} 1fr;
  grid-column-gap: ${offsetDef};
  color: ${NW2ErrorLight500Color};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
`;

export const ModalExitIcon = styled.div`
  width: ${offsetDef};
  padding-top: ${offsetXXXSm};

  > div {
    color: inherit;
  }

  svg {
    width: 100%;
  }
`;

export const ModalExitActions = styled.div`
  display: flex;
  gap: ${offsetDef};
  justify-content: flex-end;
  padding: ${offsetXLg} 0 0;
`;
