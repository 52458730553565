import React from 'react';

import {
  Content,
  Section,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import {StyledSpan} from 'view/components/Typography';
import {TextContentUSA} from './TextContentUSA';
import {TextContentEU} from './TextContentEU';

import {
  fontSizeXLg,
  fontWeightBold,
  NW2FontSizeH5Xs,
} from 'constants/styleVars';

interface IProps {
  country?: string;
}
const TextContent = ({country}: IProps) => {
  const isUSLocale = country === 'United States' || country === 'Canada';

  return (
    <Content>
      <Section>
        <StyledSpan fontSize={NW2FontSizeH5Xs} fontWeight={fontWeightBold}>
          Contract of Cooperation
        </StyledSpan>
      </Section>
      <Section>
        <StyledSpan fontSize={fontSizeXLg} fontWeight={fontWeightBold}>
          HRS Contractual Terms
        </StyledSpan>
      </Section>

      {isUSLocale ? <TextContentUSA /> : <TextContentEU />}
    </Content>
  );
};

export default TextContent;
