import React, {useEffect} from 'react';

import NW2DateSection from '../NW2SearchDatePicker/NW2DateSection';

import {useAppSelector} from 'store/hooks';
import {SEARCH_GROUPS_DATE} from '../../constants';
import {useClickOutside} from 'hooks/useClickOutside';
import {useGroupsDatePicker} from './useGroupsDatePicker';
import {GroupsDateRange} from './GroupsDateRange';
import {GroupsDatePickerMobile} from './GroupsDatePickerMobile';
import {
  DropdownWrapper,
  GroupsDateBox,
  GroupsDateContainer,
} from './GroupsDatePicker.styles';

interface IProps {
  onFocusHandler: () => void;
}
export function GroupsDatePicker({onFocusHandler}: IProps) {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const searchFocusedInput = useAppSelector(({app}) => app.searchFocusedInput);
  const isHeaderCollapsed = useAppSelector(({app}) => app.headerCollapsed);

  const {
    startDate,
    endDate,
    onDateChange,
    selectedDate,
    isOpened,
    onToggleDatePicker,
    nightsCount,
    preSelectionDate,
  } = useGroupsDatePicker();

  const [containerRef] = useClickOutside(isOpened, () => {
    if (!isMobile) {
      onToggleDatePicker();
    }
  });

  useEffect(() => {
    if (isOpened && isHeaderCollapsed) {
      onFocusHandler();
    }
  }, [isHeaderCollapsed, isOpened, onFocusHandler]);

  useEffect(() => {
    if (searchFocusedInput === SEARCH_GROUPS_DATE && !isOpened)
      onToggleDatePicker();
  }, [isOpened, onToggleDatePicker, searchFocusedInput]);

  return (
    <GroupsDateContainer ref={containerRef}>
      <GroupsDateBox>
        <GroupsDateRange
          startDate={
            preSelectionDate
              ? preSelectionDate?.startDate || null
              : selectedDate.startDate
          }
          endDate={
            preSelectionDate
              ? preSelectionDate?.endDate || null
              : selectedDate.endDate
          }
          nightsCount={nightsCount}
          onToggle={onToggleDatePicker}
          isIconHidden={isHeaderCollapsed}
          isFullWidth
        />
      </GroupsDateBox>

      {isMobile ? (
        <GroupsDatePickerMobile
          startDate={startDate}
          endDate={endDate}
          isOpened={isOpened}
          onDateChange={onDateChange}
          onToggleDatePicker={onToggleDatePicker}
          nightsCount={nightsCount}
        />
      ) : (
        isOpened && (
          <DropdownWrapper>
            <NW2DateSection
              startDate={startDate}
              endDate={endDate}
              onChange={onDateChange}
              monthsShown={2}
            />
          </DropdownWrapper>
        )
      )}
    </GroupsDateContainer>
  );
}
