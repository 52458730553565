import {
  setAccountReferences,
  setAccountReferencesLoading,
} from './paymentSlice';
import {TContainer} from 'app';
import {AppDispatch, RootState} from 'store/types';
import {IAccountReference} from './types';

export const getPaymentReferences =
  ({
    fKey,
    companyName,
    onSuccess,
  }: {
    fKey: string | number;
    companyName?: string;
    onSuccess?: (response: IAccountReference[]) => void;
  }) =>
  async (
    dispatch: AppDispatch,
    _: () => RootState,
    {paymentContainer}: TContainer,
  ) => {
    dispatch(setAccountReferencesLoading(true));

    return await paymentContainer.getReferenceInfo({
      payload: {fKey, companyName},
      onSuccess(response) {
        dispatch(setAccountReferences(response));
        dispatch(setAccountReferencesLoading(false));
        onSuccess?.(response);
      },
      onError() {
        console.error('Error getting payment account references');
        dispatch(setAccountReferencesLoading(false));
      },
    });
  };
