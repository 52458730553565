export const Routes = {
  // supplier routes
  bookingsListDesks: '/venue/bookings/workdesks',
  bookingsListRooms: '/venue/bookings/meetingrooms',
  createPublicVenue: '/venue/create',
  registerVenue: '/venues/registration',
  publicVenue: '/venue/profile',
  publicVenueSpaces: '/venue/spaces',
  publicVenueAvailability: '/venue/availability',
  endVenueRegistration: '/venue/endRegistration',
  termAndCondition: '/venue/TermAndCondition', // deprecated!
  supplierTermsAndConditions: '/supplierTermsAndConditions',
  supplierPrivacyPolicy: '/supplierPrivacyPolicy',
  supplierFAQ: '/supplierFAQ',
  resources: '/resources',
  /* update getUserRoleFromLocation below if new supplier route is added! */

  // customer routes
  mainLanding: '/',
  venuesList: '/venues-list',
  venueDetails: '/venue/:venueID/details',
  venuePreview: '/venue/:venueID/preview',
  bookVenuePreview: '/venue/:venueID/booking',
  bookConfirmation: '/booking/:orderNumber',
  bookEdit: '/booking/:orderNumber/edit',
  customerTermAndCondition: '/customer/TermAndCondition', // deprecated!
  corporateUserTermAndCondition: '/corporateUser/TermAndCondition', // It is a variation of regular customer. No separate cognito role.
  customerTermsAndConditions: '/customerTermsAndConditions',
  customerPrivacyPolicy: '/customerPrivacyPolicy',
  customerFAQ: '/customerFAQ',
  corporateUserTermsAndConditions: '/corporateUserTermsAndConditions', // It is a variation of regular customer. No separate cognito role.
  corporateUserPrivacyPolicy: '/corporateUserPrivacyPolicy', // It is a variation of regular customer. No separate cognito role.
  corporateUserFAQ: '/corporateUserFAQ', // It is a variation of regular customer. No separate cognito role.
  customerId: '/customer',
  customerBookings: '/customer/bookings',

  // Agent routes
  agentView: '/agent/lookup',
  agentSupplierSpaces: '/agent/venue/:venueID/spaces',
  agentSupplierProfile: '/agent/venue/:venueID/profile',
  agentSupplierAvailability: '/agent/venue/:venueID/availability',
  agentSupplierServices: '/agent/venue/:venueID/services',
  agentSupplierBookingDetails: '/agent/supplierBookingDetails/:id',

  // offers
  offerRequest: '/venue/:venueID/request',
  offerRequestOverview: '/request-overview/:id',
  OfferRequestGroupOverview: '/group-overview/:id',
  offerReview: '/offer/:id/review',
  requestDecline: '/requests/declining',
  requestDeclineSuccess: '/requestDeclineSuccess',
  shortListOverview: '/shortlist-overview',
  compareOverview: '/compare-overview/:groupId',

  // admin routes
  companiesOverview: '/admin/companiesOverview',
  corporateCompanyAccount: '/admin/companyAccount',
  unmanagedAccounts: '/admin/unmanagedAccounts',
  announcements: '/admin/announcements',
  /* update getUserRoleFromLocation below if new admin route is added! */

  // propertyManager routes
  propertyManagerProfile: '/propertyManager/profile',
  createCorporateVenue: '/propertyManager/create',
  corporateVenueSpaces: '/propertyManager/spaces',
  corporateVenue: '/propertyManager/corporateVenue',
  /* update getUserRoleFromLocation below if new common route is added! */

  // common routes
  register: '/register',
  page404: '/404',
  userChangePassword: '/user/changePassword',
  userChangePasswordSuccess: '/user/changePassword/success',
  userForgotPassword: '/user/forgotPassword',
  userForgotPasswordSuccess: '/user/forgotPassword/success',
  userCompleteRegistration: '/user/endRegistration',
  userCompleteRegistrationSuccess: '/user/endRegistration/success',
  userAccountVerificationDashboard: '/user/accountVerificationDashboard',
  login: '/login',
  forgotPassword: '/forgotPassword',
  imprint: '/imprint',
  cookiePolicy: '/cookiePolicy',
  faq: '/faq',
  privacyPolicy: '/privacyPolicy',
  termsAndConditions: '/termsAndConditions',
  cancellationPolicy: '/venue/cancellationpolicy',
  /* update getUserRoleFromLocation below if new common route is added! */
} as const;
