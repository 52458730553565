import React from 'react';

import {EBookingStatus} from 'types/booking';
import {
  StyledBottomSection,
  CancellationTitle,
  ChargeMessage,
} from './NW2BookingConfirmationStatus.styles';

type TProps = {
  status: EBookingStatus;
  message: string;
};

const ChargeSection = ({status, message}: TProps) => {
  const isTitleShowed =
    status !== EBookingStatus.RFP_EXPIRED &&
    status !== EBookingStatus.RFP_DECLINED;

  return (
    <StyledBottomSection>
      <div>
        {isTitleShowed && (
          <CancellationTitle>Cancellation fee</CancellationTitle>
        )}
        <ChargeMessage>{message}</ChargeMessage>
      </div>
    </StyledBottomSection>
  );
};

export default ChargeSection;
