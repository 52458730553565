import queryString from 'query-string';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import useSearchData from 'view/venue/hooks/useSearchData';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {EVenueUnitStatus, IUnit} from 'types/dto/IUnit.types';
import {setSpaceDetailsId} from 'store/venues/actions';
import {IUnitResponse} from 'types/dto/IPublicVenue';
import {useAppSelector} from 'store/hooks';
import {ERoomType} from 'types/dto/ERoomType.type';

const useNavigateToBookingPreview = (unit: IUnit | IUnitResponse) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<{id: string; venueID: string}>();

  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);

  const isWorkSpace = roomType === ERoomType.WORK_SPACE;

  const {querySearchData} = useSearchData();

  const {venueUnits} = useVenueDetailsData();

  const isNotAvailable = isWorkSpace
    ? (unit as IUnit)?.unitStatus !== EVenueUnitStatus.AVAILABLE &&
      !querySearchData.multiRooms
    : false;

  const venueID = params.id || params.venueID;
  const unitIds = querySearchData.multiRooms
    ? venueUnits.map(({unitId}) => unitId).join(',')
    : isWorkSpace
    ? (unit as IUnit)?.id
    : unit.unitId;

  return () => {
    if (isNotAvailable) return;

    dispatch(setSpaceDetailsId(null));

    navigate({
      pathname: `/venue/${Number(venueID)}/booking`,
      search: `unitIds=${unitIds}&${queryString.stringify(querySearchData)}`,
    });
  };
};

export default useNavigateToBookingPreview;
