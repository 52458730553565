import React, {useEffect} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import NW2Loader from 'view/components/NW2Loader/NW2Loader';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {setRedirectLink} from 'store/app/appSlice';
import {useActions} from 'hooks/useActions';
import {EGroupStatus, IRequestV2} from 'types/offer';
import {LoaderWrapper} from '../NW2BookingConfirmation/NW2BookingConfirmation.styles';
import {clearGroupDetails, clearOfferDetails} from 'store/offers/offersSlice';
import {getGroupDetails} from 'store/offers/apiActions';
import {OfferRequestSecondStep} from './components/OfferRequestSecondStep/OfferRequestSecondStep';

export function OfferRequestOverview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const isOffer = params.get('isOffer') === 'true';

  const {getOfferRequestById, getOfferById} = useActions();

  const {id} = useParams<{
    id: string;
  }>();

  const offerDetails = useAppSelector(
    ({offers}) => offers.offerDetails,
  ) as IRequestV2;
  const groupDetails = useAppSelector(({offers}) => offers.groupDetails);
  const groupStatus = groupDetails?.groupStatus as EGroupStatus;

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  useEffect(() => {
    if (!id || id === offerDetails.id) return;

    if (isOffer) {
      getOfferById({id});
      return;
    }

    getOfferRequestById(id, () => getOfferById({id}));
  }, [getOfferById, getOfferRequestById, id, isOffer, offerDetails.id]);

  //get group details
  useEffect(() => {
    if (offerDetails.groupId && !groupDetails) {
      dispatch(getGroupDetails(offerDetails.groupId));
    }
  }, [dispatch, groupDetails, id, offerDetails.groupId]);

  // redirect to offer step 3
  useEffect(() => {
    if (offerDetails.offerId) {
      navigate(`/offer/${offerDetails.offerId}/review`);
    }
  }, [navigate, offerDetails.offerId]);

  //works for page leave, to have correct 'state' at next page visit, preserve state at page reload
  useEffect(() => {
    return () => {
      dispatch(setRedirectLink(''));
      dispatch(clearGroupDetails());
      dispatch(clearOfferDetails());
    };
  }, [dispatch]);

  if (id !== offerDetails.id || (offerDetails.groupId && !groupDetails))
    return (
      <LoaderWrapper isMobile={isMobile}>
        <NW2Loader label='Loading' />
      </LoaderWrapper>
    );

  return (
    <OfferRequestSecondStep
      groupStatus={groupStatus}
      isRequest={!isOffer}
      isOffer={Boolean(isOffer)}
      id={id}
    />
  );
}
