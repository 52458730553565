import React, {ReactNode} from 'react';
import {Form, FormSpy} from 'react-final-form';
import {DebouncedFunc} from 'lodash';

import NW2Button from 'view/components/NW2Button';
import usePrimaryContactInformation from './components/usePrimaryContactInformation';
import useContacts from '././components/useContacts';
import useSocialMedia from './components/useSocialMedia';
import NMMSection from 'view/components/NMMSection';

import {EVenueContactItemType, IVenue} from 'types/venue';
import {Container, FormActions} from './ContactsDetails.styles';
import {EVenueProfileContactsSections} from '../../types';
import {offsetXLg, fontSizeSm, offsetXXLg} from 'constants/styleVars';

const SECTION_MARGIN = `0 0 ${offsetXXLg}`;

export type TFormProps = {
  title: string;
  onSubmit: (values: Record<string, string>) => Promise<void>;
  initialValues: Record<string, unknown>;
  editForm: ReactNode;
  showForm: ReactNode;
  isEditorShowed: boolean;
  onEditorShow: () => void;
  onEditorHide: () => void;
  isFormDirty: boolean;
  isFormSending: boolean;
  processFormFields: DebouncedFunc<({dirtyFields}: any) => void>;
};

type TProps = {
  venue: IVenue;
  sectionName: EVenueProfileContactsSections;
  containerTitle?: ReactNode;
};

type TVenueProfilePages = {
  [key in EVenueProfileContactsSections]: TFormProps;
};

const FormSection = ({venue, sectionName, containerTitle}: TProps) => {
  const forms: TVenueProfilePages = {
    [EVenueProfileContactsSections.PRIMARY_CONTACT_INFO]: {
      ...usePrimaryContactInformation(venue),
    },
    [EVenueProfileContactsSections.SOCIAL_MEDIA]: {
      ...useSocialMedia(venue),
    },
    [EVenueProfileContactsSections.DIRECTOR]: {
      ...useContacts(
        venue,
        EVenueProfileContactsSections.DIRECTOR,
        EVenueContactItemType.DIRECTOR,
      ),
    },
    [EVenueProfileContactsSections.MANAGER]: {
      ...useContacts(
        venue,
        EVenueProfileContactsSections.MANAGER,
        EVenueContactItemType.MANAGER,
      ),
    },
    [EVenueProfileContactsSections.SALES_MANAGER]: {
      ...useContacts(
        venue,
        EVenueProfileContactsSections.SALES_MANAGER,
        EVenueContactItemType.SALES_MANAGER,
      ),
    },
    [EVenueProfileContactsSections.RESERVATIONS_MANAGER]: {
      ...useContacts(
        venue,
        EVenueProfileContactsSections.RESERVATIONS_MANAGER,
        EVenueContactItemType.RESERVATIONS_MANAGER,
      ),
    },
    [EVenueProfileContactsSections.BANQUET_MANAGER]: {
      ...useContacts(
        venue,
        EVenueProfileContactsSections.BANQUET_MANAGER,
        EVenueContactItemType.BANQUET_MANAGER,
      ),
    },
    [EVenueProfileContactsSections.MARKETING_MANAGER]: {
      ...useContacts(
        venue,
        EVenueProfileContactsSections.MARKETING_MANAGER,
        EVenueContactItemType.MARKETING_MANAGER,
      ),
    },
    [EVenueProfileContactsSections.RESERVATION_CONFIRMATION]: {
      ...useContacts(
        venue,
        EVenueProfileContactsSections.RESERVATION_CONFIRMATION,
        EVenueContactItemType.RESERVATION_CONFIRMATION,
        true,
      ),
    },
    [EVenueProfileContactsSections.RECEPTION]: {
      ...useContacts(
        venue,
        EVenueProfileContactsSections.RECEPTION,
        EVenueContactItemType.RECEPTION,
        true,
      ),
    },
  };

  const allContent = forms[sectionName];
  const {
    title,
    onSubmit,
    initialValues,
    editForm,
    showForm,
    isEditorShowed,
    onEditorShow,
    onEditorHide,
    isFormDirty,
    isFormSending,
    processFormFields,
  } = allContent;

  return (
    <NMMSection
      id={sectionName}
      title={title}
      isEditOn={isEditorShowed}
      onEdit={onEditorShow}
      sectionMargin={SECTION_MARGIN}
      titleFontSize={fontSizeSm}
      titleMarginBottom={offsetXLg}
      containerTitle={containerTitle}
    >
      {isEditorShowed ? (
        <Container>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                {editForm}

                <FormActions>
                  <NW2Button
                    buttonType='secondary'
                    onClick={onEditorHide}
                    size='responsiveAdaptive'
                    data-testid='form-cancel-button'
                  >
                    Cancel
                  </NW2Button>
                  <NW2Button
                    type='submit'
                    buttonType='primary'
                    loading={isFormSending}
                    disabled={!isFormDirty}
                    size='responsiveAdaptive'
                    data-testid='form-save-button'
                  >
                    Save changes
                  </NW2Button>
                </FormActions>

                <FormSpy
                  subscription={{dirtyFields: true}}
                  onChange={processFormFields}
                />
              </form>
            )}
          />
        </Container>
      ) : (
        showForm
      )}
    </NMMSection>
  );
};

export default FormSection;
