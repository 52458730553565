import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';
import {TMarkerLocation} from 'types/venue';
import {setIsVenuesListOpened} from 'store/venues/actions';
import {RootState} from 'store/types';

import {
  blackColor,
  fontSizeXSm,
  fontWeightExtraBold,
  lineHeightSm,
  mdBp,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';

type TProps = {
  map: google.maps.Map | null;
  getVenuesForGoogleMap: (
    location: TMarkerLocation & {map: google.maps.Map},
  ) => void;
};

const Button = styled.button`
  position: absolute;
  top: ${offsetDef};
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  align-items: center;
  background: ${blackColor};
  color: ${whiteColor};
  padding: ${offsetXSm} ${offsetDef};
  border-radius: 100px;
  outline: none;
  border: none;
  z-index: 1;
  cursor: pointer;
  font-weight: ${fontWeightExtraBold};

  @media (min-width: ${mdBp}px) {
    top: ${offsetXLg};
  }

  * {
    cursor: pointer;
  }
`;

const iconSize = '20px';
const ButtonIcon = styled(Button)`
  grid-gap: ${offsetXXSm};
  grid-template-columns: ${iconSize} 1fr;
  min-width: 170px;
`;

const Text = styled.span`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

const IconButton = styled(Icon)`
  width: ${iconSize};
  height: ${iconSize};
`;

const NW2MapRefetchVenues = ({map, getVenuesForGoogleMap}: TProps) => {
  const dispatch = useDispatch();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const googleMapRefetch: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.googleMapRefetch'),
  );

  const isVenuesListOpened = useSelector((state: RootState) =>
    _get(state, 'venuesReducer.isVenuesListOpened'),
  );

  const onClick = () => {
    if (map) {
      const mapCenter = map.getCenter();
      if (mapCenter) {
        const lat = mapCenter.lat();
        const lng = mapCenter.lng();
        getVenuesForGoogleMap({lat, lng, map});
      }
    }
  };

  const onShowMap = () => {
    dispatch(setIsVenuesListOpened(false));
  };

  if (isVenuesListOpened && isMobile) {
    return (
      <Button onClick={onShowMap}>
        <Text>Show on map</Text>
      </Button>
    );
  }

  if (googleMapRefetch) {
    return (
      <ButtonIcon onClick={onClick}>
        <IconButton icon='SEARCH' white />
        <Text>Search this area</Text>
      </ButtonIcon>
    );
  }

  return null;
};

export default NW2MapRefetchVenues;
