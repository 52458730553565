// Sometimes backend errors should be handled in a component,
// rather than on interceptors level.
// Responses with below reasonCode will be ignored by interceptors,
// so errors will be handled on component level
export enum EIgnoreInterceptorCodes {
  UNAUTHORIZED_DATA_ERROR = 'INV-401', // 401. User is not logged in.
  UNAUTHORIZED_NO_ACCESS_DATA_ERROR = 'INV-403', // 403. User has no access.
  UNAUTHORIZED_FETCH_VENUE_DETAILS_DATA_ERROR = 'GTW-SEC-008', // 401. User is not logged in.
  FORBIDDEN_ACCESS_TO_VENUE_DETAILS_ERROR = 'GTW-SEC-009', // 403. User has no access to this venue.
  AUTHORIZATION_VALIDATION_FAILED = 'INV-SEC-001',
  AUTHORIZATION_FAILED = 'INV-SEC-002',
  PASSWORD_INVALID = 'INV-SEC-003',
  TOKEN_INVALID = 'INV-SEC-004',
  ACCOUNT_ALREADY_CONFIRMED = 'INV-SEC-005',
  ACCOUNT_ALREADY_REGISTERED = 'INV-ACC-010',
  BAD_REQUEST_INVALID_TOKEN = 'INV-ENC-003',
}
