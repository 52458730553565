import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import {useAppSelector} from 'store/hooks';

import {AnchorLink} from './components/AnchorLink';
import {BackToOffersBlock} from './components/BackToOffersBlock';
import {
  debounceTime,
  ESectionIds,
  menuItemsDefault,
  offerNavigationHeight,
  SectionDefaultPosition,
  TAnchorContentSections,
} from './helpers';
import {
  AnchorWrapper,
  NavigationBlock,
  NavigationInnerBlock,
} from './AnchorNavigationBlock.styles';
import {ERoomType} from 'types/dto/ERoomType.type';

type TSectionPosition = {
  top: number;
  bottom: number;
};

type TRef = {
  activeTitle: null | string;
  isBackToOffers: boolean | null;
  menuItems: Record<string, TSectionPosition> | null;
  venueList: TSectionPosition;
  isLinkClicked: boolean;
};

type TProps = {
  anchorContentSections: TAnchorContentSections[];
  roomType: ERoomType;
  hasBedroom: boolean;
};

export function AnchorNavigationBlock({
  anchorContentSections,
  roomType,
  hasBedroom,
}: TProps) {
  const [activeTitle, setActiveTitle] = useState<string | ESectionIds>(
    ESectionIds.VENUE_DETAILS,
  );
  const [isBackToOffers, setIsBackToOffers] = useState(false);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const isDetailsNoOffersShowed: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.isDetailsNoOffersShowed'),
  );

  const ref = useRef<TRef>({
    activeTitle: null,
    isBackToOffers: false,
    venueList: SectionDefaultPosition,
    menuItems: null,
    isLinkClicked: false,
  });

  const handleScroll = useCallback(() => {
    const curPos = window.scrollY;
    const refCurrent = ref.current;
    let curSection = null;
    for (const section in refCurrent.menuItems) {
      curSection =
        curPos >= refCurrent.menuItems[section].top &&
        refCurrent.menuItems[section].bottom >= curPos
          ? section
          : curSection;
      if (curSection === section) {
        break;
      }
    }

    if (curSection !== refCurrent.activeTitle && !refCurrent.isLinkClicked) {
      refCurrent.activeTitle = curSection || ESectionIds.VENUE_DETAILS;
      setActiveTitle(curSection || ESectionIds.VENUE_DETAILS);
    }
    if (curPos >= refCurrent.venueList.bottom && !refCurrent.isBackToOffers) {
      refCurrent.isBackToOffers = true;
      setIsBackToOffers(true);
    }
    if (curPos <= refCurrent.venueList.bottom && refCurrent.isBackToOffers) {
      refCurrent.isBackToOffers = false;
      setIsBackToOffers(false);
    }
    refCurrent.isLinkClicked = false;
  }, []);

  const getAnchorPoints = useCallback(() => {
    const curScroll = window.scrollY;
    const venueListEl = document.getElementById(ESectionIds.VENUE_LIST);

    ref.current.menuItems = menuItemsDefault(anchorContentSections);

    for (const key in ref.current.menuItems) {
      const el = document.getElementById(key);
      if (!el) break;

      const elRect = el.getBoundingClientRect();
      ref.current.menuItems[key] = {
        top: elRect.top + curScroll - offerNavigationHeight,
        bottom: elRect.bottom + curScroll - offerNavigationHeight,
      };
    }

    if (!venueListEl) return;
    const clientRectVenueListRef = venueListEl.getBoundingClientRect();
    const venueListRefTop = clientRectVenueListRef.top + curScroll;
    const venueListRefBottom = clientRectVenueListRef.bottom + curScroll;

    ref.current.venueList = {
      top: venueListRefTop,
      bottom: venueListRefBottom,
    };
  }, [anchorContentSections]);

  useEffect(() => {
    const root = document.getElementById('root');
    const observer = new MutationObserver(
      _debounce(getAnchorPoints, debounceTime),
    );
    if (!root) return;
    observer.observe(root, {
      childList: true,
      subtree: true,
    });
    const debouncedHandleScroll = _debounce(handleScroll, debounceTime);
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [getAnchorPoints, handleScroll]);

  const handleAnchorLinkClick = (sectionId: ESectionIds) => {
    setActiveTitle(sectionId);
    ref.current.activeTitle = sectionId;
    ref.current.isLinkClicked = true;
  };

  const isGroups = roomType === ERoomType.GROUPS;

  if (isDetailsNoOffersShowed && isMobile && !isGroups) {
    // hide on mobile if no offers
    return null;
  }

  const shouldShowNavBlock =
    !isBackToOffers && isMobile && roomType === ERoomType.WORK_SPACE;

  return (
    <NavigationBlock isMobile={isMobile} isBackToOffers={shouldShowNavBlock}>
      <NavigationInnerBlock>
        {!isMobile && (
          <AnchorWrapper>
            {anchorContentSections.map(({contentTitle, sectionId}) => (
              <AnchorLink
                key={contentTitle + sectionId}
                contentTitle={contentTitle}
                sectionId={sectionId}
                activeTitle={activeTitle}
                onClick={handleAnchorLinkClick}
              />
            ))}
          </AnchorWrapper>
        )}
        {isMobile ||
        (isBackToOffers && !isDetailsNoOffersShowed) ||
        (isBackToOffers && isGroups) ? (
          <BackToOffersBlock hasBedroom={hasBedroom} />
        ) : null}
      </NavigationInnerBlock>
    </NavigationBlock>
  );
}
