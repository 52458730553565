import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2ModalMeetingsTwoColumns from 'view/components/NW2ModalMeetingsTwoColumns/NW2ModalMeetingsTwoColumns';
import NW2MeetingRequestsList from './components/MeetingRequestsList';
import SummaryRequestBlock from './components/SummaryRequestBlock';
import useSearchTemplateData from './hooks/useSearchTemplateData';

import {getExtrasOption} from 'store/venue/actions';
import {checkIsExtraByTypePresent} from './components/utils';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';

type TProps = {
  onClose: () => void;
  resultPageMode?: boolean;
};

const SearchTemplatePopup = ({onClose, resultPageMode}: TProps) => {
  const dispatch = useAppDispatch();

  const timeData = useAppSelector(({search}) => search.initialTimeData);
  const allExtrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const meetingRoomData = useSearchTemplateData(timeData, resultPageMode);

  const {FOOD_AND_BEVERAGE, EQUIPMENT, BEDROOM} = EResourcesType;

  const firstDayDate = timeData[0].timeStart;
  const lastDayDate = timeData[timeData.length - 1].timeEnd;

  const isFoodBeverageExtrasPresent = checkIsExtraByTypePresent(
    allExtrasOption,
    FOOD_AND_BEVERAGE,
  );

  const isEquipmentExtrasPresent = checkIsExtraByTypePresent(
    allExtrasOption,
    EQUIPMENT,
  );

  useEffect(() => {
    // todo check with useCommonExtras
    if (isFoodBeverageExtrasPresent && isEquipmentExtrasPresent) return;

    dispatch(getExtrasOption([FOOD_AND_BEVERAGE, EQUIPMENT, BEDROOM]));
  }, [
    dispatch,
    isFoodBeverageExtrasPresent,
    isEquipmentExtrasPresent,
    EQUIPMENT,
    FOOD_AND_BEVERAGE,
    BEDROOM,
  ]);

  return (
    <NW2ModalMeetingsTwoColumns
      title='Let us know your meeting needs'
      onToggle={onClose}
      colLeft={
        <NW2MeetingRequestsList
          firstDayDate={firstDayDate}
          lastDayDate={lastDayDate}
          {...meetingRoomData}
        />
      }
      colRight={
        <SummaryRequestBlock
          meetingRequestData={meetingRoomData.meetingRequestData}
          bedroomsCatering={meetingRoomData.bedroomsCatering}
          closePopupHandler={onClose}
          setIsSectionExpanded={meetingRoomData.setIsSectionExpanded}
        />
      }
    />
  );
};

export default SearchTemplatePopup;
