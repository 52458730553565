export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];
export const EMPTY_FUNC = () => {};

export const HRS_LOGO_URL = 'https://hotelpartner.hrs.com/?intcmp=btcweb';

export const VENUE_LIST_LIMIT = 500;
export const VENUE_LIST_ITEMS_PER_PAGE = 25;
export const INITIAL_RADIUS = 0;

export const COOKIES_POLICY_URL =
  'https://www.hrs.com/web3/cookie_policy.do?activity=index';
export const POLICIES_GOOGLE_URL = 'https://policies.google.com/privacy?hl=en';
export const HRS_GDPR_URL = 'https://gdpr.hrs.com/hrs';
export const NEWWORK_SUPPORT_EMAIL = 'support.newwork@hrs.com';
export const PRODUCT_SECURITY_EMAIL = 'product.security@hrs.com';
export const HRS_WIDERSPRUCH_EMAIL = 'widerspruch@hrs.de';
export const HRS_DATENSCHUTZ_EMAIL = 'datenschutz@hrs.de';
export const HRS_SUPPORT_EMAIL = 'support@hrs.de';
export const ISSUES_NEWWORK_EMAIL = 'issues.newwork@hrs.com';

export const defaultAntdFormLayout = {
  labelCol: {span: 6},
  wrapperCol: {span: 24},
};

export const defaultAutoSize = {minRows: 4, maxRows: 4};

export const minimumParticipantsNumber = 1;
export const maximumParticipantsNumber = 9999;
export const maximumCounterNumber = 9999;

export const notificationDuration = 4;

export const DEFAULT_GOOGLE_MAP_ZOOM = 11;

export const CANCEL_POLICY_MEETING_ROOM_CAPACITY = 10;

export const HEADER_HEIGHT_MD = 80;
export const HEADER_HEIGHT = 64;
export const MODAL_HEADER_HEIGHT = 64;

export const FOOTER_ID = 'footer';
export const LOCATION_ID = 'location';
export const DATE_ID = 'date';
export const PARTICIPANTS_ID = 'participants';
export const SEATING_STYLE_ID = 'seating-style';

export const MODAL_TWO_COLS_RIGHT_COL_ID = 'modal-two-cols-right';

export const AMENITIES_COLUMN_WIDTH = '210px';

// images
export const minimumImageFileSize = '50 KB';
export const maximumImageFileSize = '15 MB';
export const validImageFormats = 'JPG, JPEG, PNG';
export const IMAGE_MAX_SIZE = 15728640; // 15mb
export const IMAGE_MIN_SIZE = 51200; // 50kb
export const MAX_FILES_LIMIT = 20;

// Bookings
export const NOT_PROVIDED_BY_BOOKER = 'Not provided by booker';

// Requests and Offers
export const OPTION_DAYS_OFFSET_FROM_TODAY = 3;
export const OPTION_DAYS_BEFORE_MEETING = 1;
export const OPTION_DATE_DEFAULT_OFFSET = 7;
export const OPTION_DATE_AFTER_DEFAULT_OFFSET = 8;
// export const OPTION_DATE_DEFAULT_OFFSET_BEFORE_MEETING = 1;
export const OPTION_DATE_NOTE = `The provided date and time are based on the venue's time zone. Please check the time for your local time zone.`;

// Multisearch mask
export const MIN_EXTRAS_NUMBER = 1;
export const MAX_EXTRAS_NUMBER = 9999;

// Groups
export const MINIMAL_GROUP_QTY = 10;

// Dynamic Cancellation Policy
export const FULL_FREE_OF_CHARGE = 100;

export const US_FORMAT_CURRENCIES = ['USD', 'CAD', 'GBP'];
