import React from 'react';

import BillingAddressView from 'view/components/BillingAddress/BillingAddressView';
import VenueDetailsCard from 'view/venue/NW2BookingConfirmation/components/VenueDetailsCard';
import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import AboutMeeting from 'view/venue/NW2BookingConfirmation/components/AboutMeeting';
import NW2BookingConfirmationUnitsBlock from 'view/venue/NW2BookingConfirmation/components/NW2BookingConfirmationUnitsBlock';
import {OfferRequestReviewUnits} from 'view/venue/Offer/components/OfferRequestReviewUnits/OfferRequestReviewUnits';
import OfferOptionDate from 'view/venue/Offer/components/OfferOptionDate/OfferOptionDate';
import ItemPaymentInformation from 'view/venue/components/LeftSideContainer/ItemPaymentInformation';

import {WhatIsNext} from 'view/venue/Offer/components/WhatIsNext/WhatIsNext';
import {Bedrooms} from 'view/venue/NW2BookingPreview/components/Bedrooms/Bedrooms';

import {useAppSelector} from 'store/hooks';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EBookingStatus} from 'types/booking';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {StyledLeftSideContainer} from 'view/venue/components/LeftSideContainer/LeftSideContainer.styles';
import {
  EGroupStatus,
  EOfferStatus,
  ERequestStatus,
  IOfferRequestDay,
} from 'types/offer';
import {CancellationPolicyBlock} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import {ERoomType} from 'types/dto/ERoomType.type';
import BookingConfirmCancellationPolicy from '../BookingConfirmCancellationPolicy/BookingConfirmCancellationPolicy';
import {IPaymentInfo} from 'types/bookingOrders';
import {EFeatureToggles} from 'constants/featureToggles';
import {useFeatureToggle} from 'hooks/useFeatureToggle';
// import {BookingTermsAndConditions} from '../../../BookingTermsAndConditions/BookingTermsAndConditions';

type TProps = {
  orderNumber?: string;
  bookingStatus?: EBookingStatus | ERequestStatus | EOfferStatus;
  billingAddress?: ICustomerBillingAddress;
  meetingName?: string;
  customerDeleted?: boolean;
  isOffer?: boolean;
  isOfferOrRequest?: boolean;
  isGroupRequestPage?: boolean;
  groupStatus?: EGroupStatus | null;
  accommodationType?: EAccommodationType;
  preArrivals?: IOfferRequestDay[];
  postEvents?: IOfferRequestDay[];
  roomType?: ERoomType;
  paymentInfo?: IPaymentInfo;
};

function BookingConfirmationBlockList({
  orderNumber,
  accommodationType,
  bookingStatus,
  billingAddress,
  customerDeleted,
  meetingName,
  isOffer,
  isOfferOrRequest,
  preArrivals,
  postEvents,
  isGroupRequestPage,
  groupStatus,
  roomType,
  paymentInfo,
}: TProps) {
  const isPublicVenue = accommodationType === EAccommodationType.VENUE;
  const isVenueContactsHidden = bookingStatus !== EBookingStatus.CONFIRMED;

  const orderStatus = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.bookingStatus,
  );

  const isCancellationPolicyHidden =
    !isPublicVenue ||
    isOfferOrRequest ||
    (orderStatus &&
      [
        EBookingStatus.PASSED,
        EBookingStatus.RFP_DECLINED,
        EBookingStatus.RFP_EXPIRED,
        EBookingStatus.RTC_EXPIRED,
      ].includes(orderStatus));

  const isCanceledStatus =
    orderStatus === EBookingStatus.CANCELED ||
    orderStatus === EBookingStatus.RFP_CANCELLED;

  const cancellationPolicyTitle = isCanceledStatus
    ? 'Cancellation policy applied'
    : 'Modification & Cancellation conditions';

  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;

  const [isHrsPayEnabled] = useFeatureToggle(EFeatureToggles.HRS_PAY);

  const isRequestPending =
    bookingStatus === ERequestStatus.REQUEST_PENDING ||
    groupStatus === EGroupStatus.GROUP_REQUEST_PENDING;

  return (
    <StyledLeftSideContainer>
      <LeftSideItem
        item={{
          title: 'Venue contact details',
          children: <VenueDetailsCard />,
        }}
        isHidden={isVenueContactsHidden || isOfferOrRequest}
      />
      {
        <LeftSideItem
          item={{
            title: cancellationPolicyTitle,
            children:
              roomType === ERoomType.WORK_SPACE ? (
                <BookingConfirmCancellationPolicy />
              ) : (
                <CancellationPolicyBlock
                  orderNumber={orderNumber}
                  isCanceledStatus={isCanceledStatus}
                  isRTBText={orderStatus === EBookingStatus.RFP_PENDING}
                />
              ),
          }}
          isHidden={isCancellationPolicyHidden}
        />
      }
      <LeftSideItem
        item={{
          title: 'About meeting',
          children: <AboutMeeting meetingName={meetingName} />,
        }}
        isHidden={!meetingName}
      />
      {bookingStatus === ERequestStatus.REQUEST_PENDING && (
        <>
          <LeftSideItem
            item={{
              title: 'Option date',
              children: <OfferOptionDate />,
            }}
          />
        </>
      )}

      {!!preArrivals?.length && (
        <Bedrooms rooms={preArrivals} title='PRE-ARRIVAL' />
      )}

      {isOfferOrRequest ? (
        <OfferRequestReviewUnits
          isOffer={isOffer}
          isGroupRequestPage={isGroupRequestPage}
          hasPreArrivals={!!preArrivals?.length}
          hasPostEvents={!!postEvents?.length}
        />
      ) : (
        <NW2BookingConfirmationUnitsBlock
          hasPreArrivals={!!preArrivals?.length}
          hasPostEvents={!!postEvents?.length}
        />
      )}

      {!!postEvents?.length && (
        <Bedrooms rooms={postEvents} title='POST-EVENT' />
      )}

      <>
        <ItemPaymentInformation
          paymentInfo={paymentInfo}
          billingAddress={billingAddress}
          isHidden={isOffice || !isHrsPayEnabled}
        />

        <LeftSideItem
          item={{
            title: 'Billing address',
            children: (
              <BillingAddressView
                billingAddress={billingAddress}
                showIcon={bookingStatus !== EOfferStatus.OFFER_CONFIRMED}
              />
            ),
          }}
          isHidden={!billingAddress || customerDeleted || isOfferOrRequest}
        />
      </>

      {isRequestPending && (
        <>
          {/* todo waiting for BE task CNCT-4376
            <LeftSideItem
              item={{
                title: 'Terms and Conditions',
                children: <BookingTermsAndConditions />,
              }}
            />
          */}

          <LeftSideItem
            item={{
              title: 'What’s next',
              children: <WhatIsNext isGroupRequest={isGroupRequestPage} />,
            }}
          />
        </>
      )}
    </StyledLeftSideContainer>
  );
}

export default BookingConfirmationBlockList;
