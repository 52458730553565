import React, {ChangeEvent, useEffect} from 'react';
import {useForm} from 'react-final-form';
import styled from 'styled-components';

import {NW2FormItemRadioButton} from 'view/components/NW2FormItem/NW2FormItem';
import CorporatePaymentBlock from './components/CorporatePaymentBlock';
import TextValue from 'view/components/TextValue';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {EBookingFormFields} from 'types/venue';
import {fontSizeMd, offsetSm} from 'constants/styleVars';
import {
  setPaymentType,
  setCorporateBillingAddress,
} from 'store/payment/paymentSlice';
import {getPaymentReferences} from 'store/payment/apiActions';
import {EPaymentType} from 'types/bookingOrders';
import {Group} from 'styles/basicStyledComponents/group';

const Title = styled.div`
  margin-bottom: ${offsetSm};
`;

const PaymentMethodsBox = styled.div`
  > div {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const PAYMENT_METHODS = [
  {text: 'Direct payment to venue', value: EPaymentType.DIRECT_PAYMENT},
  {text: 'Corporate payment', value: EPaymentType.CORPORATE_HRS_PAY},
];

export const BookingPayment = () => {
  const dispatch = useAppDispatch();

  const form = useForm();

  const corporateAccountId = useAppSelector(({app}) => app.corporateAccountId);
  const paymentType = useAppSelector(({payment}) => payment.paymentType);
  const isLoggedIntoSystem = useAppSelector(
    ({app}) => app.user.isLoggedIntoSystem,
  );
  const accountReferences = useAppSelector(
    ({payment}) => payment.accountReferences,
  );

  const isCorporatePayment = paymentType === EPaymentType.CORPORATE_HRS_PAY;

  useEffect(() => {
    if (isLoggedIntoSystem && !paymentType) {
      // init paymentType
      dispatch(
        setPaymentType(
          corporateAccountId
            ? EPaymentType.CORPORATE_HRS_PAY
            : EPaymentType.DIRECT_PAYMENT,
        ),
      );
    }
  }, [corporateAccountId, dispatch, isLoggedIntoSystem, paymentType]);

  useEffect(() => {
    return () => {
      dispatch(setPaymentType(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!corporateAccountId || !isCorporatePayment) return;
    dispatch(
      getPaymentReferences({
        fKey: corporateAccountId,
        onSuccess: (resp) => {
          if (!resp.length) {
            dispatch(setPaymentType(EPaymentType.DIRECT_PAYMENT));
          }
        },
      }),
    );
  }, [corporateAccountId, dispatch, isCorporatePayment]);

  const onPaymentTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const paymentType = e.target.value as EPaymentType;
    dispatch(setPaymentType(paymentType));

    if (paymentType === EPaymentType.DIRECT_PAYMENT) {
      form.change(EBookingFormFields.PAYMENT_INFO, '');
      dispatch(setCorporateBillingAddress(null));
    }
  };

  if (
    !corporateAccountId ||
    (corporateAccountId && !accountReferences.length)
  ) {
    return (
      <Group direction='column'>
        <TextValue fontSize={fontSizeMd} bold>
          Make your payment directly to the venue.
        </TextValue>
        <TextValue fontSize={fontSizeMd}>
          The venue will verify your payment method after your booking has been
          confirmed, and they will contact you directly to arrange for it.
        </TextValue>
      </Group>
    );
  }

  return (
    <>
      <Title>
        <b>Select your desired payment method</b>
      </Title>

      <PaymentMethodsBox>
        {PAYMENT_METHODS.map((opt, index) => (
          <NW2FormItemRadioButton
            key={`${opt.value}-${index}`}
            name={EBookingFormFields.PAYMENT_TYPE}
            label={opt.text}
            value={opt.value}
            onChange={onPaymentTypeChange}
            isChecked={paymentType === opt.value}
          />
        ))}
      </PaymentMethodsBox>

      {isCorporatePayment && <CorporatePaymentBlock />}
    </>
  );
};
