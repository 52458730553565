import React, {useCallback, useMemo, useState} from 'react';

import {OfferCreateOnboardedVenue} from './OfferCreateOnboardedVenue';
import {OfferCreateAlternativeVenue} from './OfferCreateAlternativeVenue';

import {offsetXXLg} from 'constants/styleVars';
import {useSpaceImages} from './hooks/useSpaceImages';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {setOfferDetails} from 'store/offers/offersSlice';
import {
  ECreateOfferFormFields,
  ICommonProps,
  IRequestMeeting,
  TCreateOfferExtraItem,
  TCreateOfferItem,
  TFormState,
} from './types';
import {EEventType} from 'types/venue';
import {useRequestBedrooms} from '../../hooks/useRequestBedrooms';
import {
  getFilteredExtrasByType,
  getFilteredUnitsByEventType,
} from 'utils/venueUtils';
import {usePreviewPrices} from './hooks/usePreviewPrices';
import {EResourcesType} from 'types/dto/IExtras.type';
import {getBedroomsFromState} from './helpers';
import {useCommonExtras} from 'view/venue/hooks/useCommonExtras';

interface IProps {
  onToggleShow: () => void;
  onCloseOrderDetails: () => void;
}
export function OfferCreate({onToggleShow, onCloseOrderDetails}: IProps) {
  const dispatch = useAppDispatch();

  const requestDetails = useAppSelector(({offers}) => offers.offerDetails);

  const [activeUnitId, setActiveUnitId] = useState('');
  const [formState, setFormState] = useState<TFormState>({});
  const [itemsToOffer, setItemsToOffer] = useState<TCreateOfferItem[]>([]);
  const [formStatus, setFormStatus] = useState<'started' | 'finished' | ''>('');
  const [page, setPage] = useState(0);

  const activeForm = useMemo(
    () => formState[activeUnitId] || {},
    [activeUnitId, formState],
  );

  const handleSetItemsToOffer = useCallback(
    ({
      unitId,
      extras,
      bedrooms,
      eventType,
    }: {
      unitId?: number;
      extras?: TCreateOfferExtraItem[];
      bedrooms?: TCreateOfferExtraItem[];
      eventType?: EEventType;
    }) => {
      setItemsToOffer((prev) => {
        const item = prev.find((item) => item.requestItemId === activeUnitId);

        if (item) {
          // update if existed
          return [
            ...prev.filter((item) => item.requestItemId !== activeUnitId),
            {
              eventType,
              requestItemId: activeUnitId,
              ...(unitId ? {unitId} : item.unitId ? {unitId: item.unitId} : {}),
              ...(extras ? {extras} : item.extras ? {extras: item.extras} : {}),
              ...(bedrooms
                ? {bedrooms}
                : item.bedrooms
                ? {bedrooms: item.bedrooms}
                : {}),
            },
          ];
        }

        // add new
        return [
          ...prev,
          {
            eventType,
            requestItemId: activeUnitId,
            ...(unitId ? {unitId} : {}),
            ...(extras ? {extras} : {}),
            ...(bedrooms ? {bedrooms} : {}),
          },
        ];
      });
    },
    [activeUnitId],
  );

  const handleScrollToUnit = (unitId: string) => {
    // scroll to next unit preview
    const elem = document.getElementById(unitId);
    const wrapperElement = document.getElementById('preview-bar');

    if (elem) {
      const OFFSET = parseInt(offsetXXLg);

      setTimeout(() => {
        wrapperElement?.scrollTo({
          top:
            elem.getBoundingClientRect().top +
            wrapperElement.scrollTop -
            OFFSET,
          behavior: 'smooth',
        });
      }, 100); // scroll doesn't work correctly without timeout
    }
  };

  const {filesState, handleResetImages, handleSetFiles} = useSpaceImages({
    activeUnitId,
  });

  const handleClose = () => {
    handleResetImages();
    dispatch(setOfferDetails({}));
    onCloseOrderDetails();
    onToggleShow();
  };

  // requested data
  const requestDays = useMemo(
    () => requestDetails.days || [],
    [requestDetails.days],
  );

  const requestRooms = useMemo(
    () => getFilteredUnitsByEventType(requestDays),
    [requestDays],
  );

  const requestMeetings = useMemo(() => {
    const result: IRequestMeeting[] = [];

    requestRooms.forEach((room) => {
      if (room.items?.length) {
        const {items, eventType, bedrooms, foodAndBeverage} = room;

        items?.forEach((item, idx) =>
          result.push({
            ...item,
            eventType,
            ...(idx === 0
              ? {
                  bedrooms,
                  foodAndBeverage,
                }
              : {}),
          }),
        );
      } else {
        // @ts-ignore
        result.push(room);
      }
    });

    return result;
  }, [requestRooms]);

  const {preArrivals, postEvents} = useRequestBedrooms(true);

  const requestUnits = useMemo(
    () =>
      [...preArrivals, ...requestMeetings, ...postEvents] as IRequestMeeting[],
    [postEvents, preArrivals, requestMeetings],
  );

  const requestedUnitDetails = requestUnits.find(
    ({id}) => id === activeUnitId,
  ) as unknown as IRequestMeeting;

  // prices
  const [extrasOption] = useCommonExtras();

  const firstMeetingRoomId = requestMeetings[0]?.id;

  const equipmentPrices =
    formState[activeUnitId]?.[ECreateOfferFormFields.PRICES_EQUIPMENT];
  const cateringPrices =
    formState[activeUnitId]?.[ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE];

  const bedroomsPrices = getBedroomsFromState(formState, activeUnitId);
  const hasEquipment = !!Object.keys(equipmentPrices || {}).length;
  const hasCatering = !!Object.keys(cateringPrices || {}).length;

  const filteredExtrasOptions = useMemo(
    () => [
      ...getFilteredExtrasByType(
        requestedUnitDetails?.extras || [],
        EResourcesType.EQUIPMENT,
        extrasOption,
      ),
      ...getFilteredExtrasByType(
        requestedUnitDetails?.foodAndBeverage || [],
        EResourcesType.FOOD_AND_BEVERAGE,
        extrasOption,
      ),
      ...getFilteredExtrasByType(
        requestedUnitDetails?.bedrooms || [],
        EResourcesType.BEDROOM,
        extrasOption,
      ),
    ],
    [
      extrasOption,
      requestedUnitDetails?.bedrooms,
      requestedUnitDetails?.extras,
      requestedUnitDetails?.foodAndBeverage,
    ],
  );

  const isCurrentDayWithoutMeetings =
    requestedUnitDetails?.items && !requestedUnitDetails.items.length;

  const prices = usePreviewPrices({
    allExtrasOptions: extrasOption,
    extrasOptions: filteredExtrasOptions,
    activeForm,
    activeUnitId,
    activeUnit: requestedUnitDetails || {},
    requestUnits,
    equipmentPrices,
    cateringPrices,
    bedroomsPrices,
    equipmentFirstUnitPrices:
      formState[firstMeetingRoomId]?.[ECreateOfferFormFields.PRICES_EQUIPMENT],
    cateringFirstUnitPrices:
      formState[firstMeetingRoomId]?.[
        ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE
      ],
    isMeeting:
      requestedUnitDetails?.eventType === EEventType.DAY &&
      !isCurrentDayWithoutMeetings,
  });

  const commonProps: ICommonProps = {
    activeUnitId,
    activeForm,
    page,
    handleSetFiles,
    handleScrollToUnit,
    handleClose,
    handleSetItemsToOffer,
    formState,
    formStatus,
    setActiveUnitId,
    setPage,
    setFormState,
    itemsToOffer,
    setItemsToOffer,
    setFormStatus,
    postEvents,
    preArrivals,
    requestMeetings,
    requestDays,
    requestRooms,
    requestUnits,
    prices,
    hasEquipment,
    hasCatering,
  };

  const isAlternative = useMemo(
    () =>
      requestRooms
        .filter(({items}) => items && items.length)
        .flatMap(({items}) => items)
        .some((item) => !item?.unitId),
    [requestRooms],
  );

  return isAlternative ? (
    <OfferCreateAlternativeVenue
      filesState={filesState}
      handleResetImages={handleResetImages}
      {...commonProps}
    />
  ) : (
    <OfferCreateOnboardedVenue {...commonProps} />
  );
}
