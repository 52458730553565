import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import NW2Button from 'view/components/NW2Button/NW2Button';
import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';
import useVenueId from 'view/venue/hooks/useVenueId';
import SpaceDetailsContent from './components/SpaceDetailsContent';
import SpaceDetailsWipEditForm from './components/SpaceDetailsWipEditForm';
import StatusAndPreviewContent from './components/StatusAndPreviewContent';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {getSpaceById} from 'view/venue/helpers/spaces';
import {setSpaceDetailsId} from 'store/venues/actions';
import {
  clearSpaceDeletionStatus,
  getPublicVenueById,
} from 'store/venue/actions';

import {ISpacesData, ISpacesDataItem, TVenueFloors} from 'types/venue';
import {ESpaceDetailsContentSections} from './types';
import {ModalActions} from './NMMSpacesDetails.styles';
import {offsetDef, offsetXXLg, offsetXXXXLg} from 'constants/styleVars';
import {useCorporateOffices} from 'hooks/useCorporateOffices';
import {getCorporateOffices} from 'store/corporateOffice/apiActions';

type TProps = {
  spaces: ISpacesData[];
  wip: ISpacesDataItem[];
  floors: TVenueFloors;
  isExternalVenue: boolean;
};

export const EDIT_SPACE_FORM_WIP = 'editSpaceFormWip';

export function NMMSpaceDetails({
  spaces,
  wip,
  floors,
  isExternalVenue,
}: TProps) {
  const dispatch = useAppDispatch();
  const {venueId} = useVenueId();
  const {corporateAccountId} = useCorporateOffices();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);
  const spaceDetailsId: number | null = useSelector((state) =>
    _get(state, 'venuesReducer.spaceDetailsId'),
  );

  // wip sections validation
  const [errorSections, setErrorSections] = useState<
    Set<ESpaceDetailsContentSections>
  >(new Set());

  const handleCheckSectionFields = useCallback(
    (name: ESpaceDetailsContentSections, condition: boolean) => {
      if (condition) {
        setErrorSections((prev) => new Set(prev.add(name)));
      } else {
        setErrorSections(
          (prev) => new Set([...prev].filter((s) => s !== name)),
        );
      }
    },
    [],
  );

  const [isWipCloseModalShowed, setWipCloseModalShowed] = useState(false);
  const onWipModalClose = () => {
    setWipCloseModalShowed(false);
  };

  if (!spaceDetailsId) return null;

  const completedSpace = getSpaceById(spaces, spaceDetailsId);
  const space: ISpacesDataItem =
    completedSpace || wip.find(({id}) => id === spaceDetailsId);

  if (!space) return null;

  const isWip = !completedSpace;

  const closeSpaceDetails = () => {
    dispatch(setSpaceDetailsId(null));
    if (isExternalVenue) {
      dispatch(getPublicVenueById(venueId));
    } else {
      dispatch(getCorporateOffices(Number(corporateAccountId), false));
    }
    dispatch(clearSpaceDeletionStatus());
  };

  const closeWipSpaceDetails = () => {
    setWipCloseModalShowed((prev) => !prev);
    document.body.style.overflow = '';
  };

  const onSubmitWip = () => {
    const formElem = document.getElementById(EDIT_SPACE_FORM_WIP);
    // { cancelable: true } required for Firefox
    // https://github.com/facebook/react/issues/12639#issuecomment-382519193
    formElem?.dispatchEvent(
      new Event('submit', {cancelable: true, bubbles: true}),
    );
  };

  return (
    <>
      <NW2ModalTwoColumns
        title='Space details'
        isShowed
        onToggleShow={isWip ? closeWipSpaceDetails : closeSpaceDetails}
        customPadding={`${offsetXXXXLg} ${offsetXXLg} ${offsetDef}`}
        colLeft={
          <>
            <StatusAndPreviewContent
              isWip={isWip}
              errorSections={errorSections}
            />

            {isDesktop && isWip && (
              <NW2Button
                onClick={onSubmitWip}
                buttonType='primary'
                disabled={!!errorSections.size}
              >
                Save changes
              </NW2Button>
            )}
          </>
        }
        colRight={
          isWip ? (
            <SpaceDetailsWipEditForm
              space={space}
              floors={floors}
              isExternalVenue={isExternalVenue}
              errorSections={errorSections}
              handleCheckSectionFields={handleCheckSectionFields}
            />
          ) : (
            <SpaceDetailsContent
              space={space}
              floors={floors}
              isExternalVenue={isExternalVenue}
            />
          )
        }
        footer={
          !isDesktop &&
          isWip && (
            <NW2Button
              onClick={onSubmitWip}
              buttonType='primary'
              disabled={!!errorSections.size}
            >
              Save changes
            </NW2Button>
          )
        }
      />

      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isWipCloseModalShowed}
        header='Attention!'
        modalWidth={400}
        drawerHeight='auto'
        body={
          <>
            You have not finished entering all of the mandatory fields. Please
            note that if you leave now all of your latest changes will be lost.
          </>
        }
        footer={
          <ModalActions>
            <NW2Button
              buttonType='secondary'
              onClick={() => {
                setWipCloseModalShowed(false);
                dispatch(setSpaceDetailsId(null));
              }}
              size='small'
            >
              Leave
            </NW2Button>
            <NW2Button
              buttonType='primary'
              onClick={onWipModalClose}
              size='small'
            >
              Continue editing
            </NW2Button>
          </ModalActions>
        }
        onClose={onWipModalClose}
        headerPaddingBottom='0'
      />
    </>
  );
}

export default NMMSpaceDetails;
