import React, {useEffect, useMemo, useRef, KeyboardEvent} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Form} from 'react-final-form';
import {Spin} from 'antd';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import NW2Button from 'view/components/NW2Button/NW2Button';
import Icon from 'view/components/Icon';
import BookPreviewComponents from 'view/venue/NW2BookingPreview/components/BookPreviewComponents';
import {NW2BookingTotal} from './components/NW2BookingTotal';
import {useIntersectionObserver} from 'hooks/useIntersectionObserver';

import {findCoverImage} from 'utils/helpers';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {useBookingInitialData} from './hooks/useBookingInitialData';
import useFlutterWebView from 'utils/bridgeBTC/useFlutterWebView';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import useSearchData from 'view/venue/hooks/useSearchData';
import useOrderSubmit from 'view/venue/hooks/useOrderSubmit';
import LocalStorageService from 'infra/common/localStorage.service';

import {TImage} from 'types/app';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {
  StyledHeader,
  StyledMain,
  StyledTitle,
  StyledWrapper,
} from './NW2BookingPreview.styles';
import {useVenueDetailsData} from '../hooks/useVenueDetailsData';
import {useDeskBookingInitialData} from './hooks/useDeskBookingInitialData';
import {ERoomType} from 'types/dto/ERoomType.type';
import {useQuery} from 'hooks/useQuery';
import useParamsVenueId from '../hooks/useParamsVenueId';
import {useCustomerOrderUnits} from '../hooks/useCustomerOrderUnits';
import {
  cleanCustomerSelectedPackages,
  updateCustomerSelectedPackages,
} from 'store/customer/customerSlice';
import {getPackages} from 'store/packages/apiActions';
import {addPackageBookingPreview} from 'store/bookingsCustomer/apiActions';
import {EVenueType} from 'types/dto/IPublicVenue';
import {useMultiDayVenueDetailsData} from '../NW2VenueDetails/useMultiDayVenueDetails';

const NW2BookingPreview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locationData = useLocation();
  const {paramsVenueId} = useParamsVenueId();

  const {roomType = ''} = useQuery();

  const orderNumber = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderNumber,
  );
  const details = useAppSelector(({venueDetails}) => venueDetails.details);
  const packages = useAppSelector(({packages}) => packages.packages);

  const {previewUnits} = useCustomerOrderUnits();

  const isMeetingRoom =
    roomType === ERoomType.MEETING_ROOM || roomType === ERoomType.GROUPS;

  const {venueDetails, isRfp} = useVenueDetailsData();

  const {currency, documents, location, name, accommodationId, id} =
    venueDetails;

  const venueAddress = getAddressStringFromLocation(location);
  const venueCoverImage = useMemo(
    () => findCoverImage((documents || []) as TImage[]),
    [documents],
  );

  useEffect(() => {
    if (!orderNumber) return;
    const query: any = new URLSearchParams(locationData.search);

    /**
     * sets url param venueId to the booking confirmation page
     * accommodationId for MR
     * id for WD
     */
    query.set('venueId', String(accommodationId || id));
    navigate({
      pathname: `/booking/${orderNumber}`,
      search: query.toString(),
    });
  }, [
    dispatch,
    orderNumber,
    navigate,
    accommodationId,
    locationData.search,
    id,
  ]);

  const {paramsVenueIds} = useParamsVenueId();
  const venueId = String(paramsVenueIds[0] ? paramsVenueIds : paramsVenueId);

  useMultiDayVenueDetailsData(venueId, false);

  const {onGoToVenueDetails, formInitialValues} = useBookingInitialData({
    id: paramsVenueId,
    isMeetingRoom,
  });

  const {formInitialValues: formDeskInitialValues} =
    useDeskBookingInitialData();

  const isInstant = [EVenueType.INSTANT, EVenueType.INSTANT_CORPORATE].includes(
    venueDetails.type,
  );

  // handle sticky total behavior
  const headerRef = useRef(null);
  const headerEntry = useIntersectionObserver(headerRef, {});
  const isTotalSticky: boolean | undefined = !headerEntry?.isIntersecting;

  const {isBTCApp} = useFlutterWebView();
  const {onFormSubmit} = useOrderSubmit();

  const {isLoggedInUser} = useLoggedInUser();

  const {querySearchData} = useSearchData();

  useEffect(() => {
    if (!isLoggedInUser && !previewUnits.length) {
      const guestBookingPreviewData = {
        previewUnits,
        venueId: paramsVenueId,
        querySearchData,
      };

      LocalStorageService.setByKey(
        'bookingPreviewData',
        JSON.stringify(guestBookingPreviewData),
      );
    }
  }, [isLoggedInUser, paramsVenueId, previewUnits, querySearchData]);

  // TODO: REFACTOR
  useEffect(() => {
    if (details?.accommodationId) {
      const id = Number(details.accommodationId);
      dispatch(getPackages(id)); // todo check packages
      dispatch(cleanCustomerSelectedPackages());
    }
  }, [details?.accommodationId, dispatch]);

  // Applying package from multidetails
  useEffect(() => {
    if (
      details &&
      packages.length &&
      previewUnits.length &&
      details.days.some((d) => d.package)
    ) {
      details.days.forEach((day, index) => {
        if (day.package) {
          const unitToApplyPackage = day.rooms.find(
            (room) => room.position === day.package?.position,
          )?.units[0]?.unitId;

          const packageToApply = packages.find(
            (p) => p.id === day.package?.packageId,
          );

          if (unitToApplyPackage && packageToApply) {
            dispatch(
              updateCustomerSelectedPackages({
                newPackage: packageToApply,
                dayIndex: index,
                unitId: unitToApplyPackage,
              }),
            );

            dispatch(addPackageBookingPreview());
          }
        }
      });
    }
  }, [details, dispatch, packages, previewUnits.length]);

  useEffect(() => {
    return () => {
      LocalStorageService.removeItemByKey('bookingPreviewData');
    };
  }, []);

  // prevent keypress enter
  const onKeyPress = (e: KeyboardEvent) => {
    e.key === 'Enter' && e.preventDefault();
  };

  return (
    <Spin spinning={!accommodationId && !id}>
      <StyledWrapper>
        <NW2Container>
          <StyledHeader ref={headerRef}>
            <NW2Button
              buttonType='secondary'
              minimized
              size='small'
              onClick={onGoToVenueDetails}
              icon={<Icon transparent icon='NW2_MOVE_BACK' />}
            />
            <StyledTitle>Confirm & Book</StyledTitle>
          </StyledHeader>

          <Form
            onSubmit={onFormSubmit}
            initialValues={
              isMeetingRoom ? formInitialValues : formDeskInitialValues
            }
            mutators={{
              setValue: ([field, value], state, {changeValue}) => {
                changeValue(state, field, () => value);
              },
            }}
            keepDirtyOnReinitialize
            render={({handleSubmit}) => (
              <form onSubmit={handleSubmit} onKeyDown={onKeyPress}>
                <StyledMain>
                  <BookPreviewComponents
                    isShowLoginModal={!isLoggedInUser && !isBTCApp}
                  />

                  <NW2BookingTotal
                    currency={currency}
                    venueName={name}
                    venueCoverImage={venueCoverImage}
                    isRfp={!isInstant && (isMeetingRoom ? isRfp : false)}
                    isTotalSticky={isTotalSticky}
                    venueAddress={venueAddress}
                  />
                </StyledMain>
              </form>
            )}
          />
        </NW2Container>
      </StyledWrapper>
    </Spin>
  );
};

export default NW2BookingPreview;
