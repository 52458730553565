import React, {KeyboardEvent, useEffect, useMemo, useState} from 'react';
import {FormRenderProps, FormSpy} from 'react-final-form';
import {useSelector} from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _isEqualWith from 'lodash/isEqualWith';
import _get from 'lodash/get';
import {FormState} from 'final-form';

import PreviewComponents from 'view/venue/NW2BookingEdit/PreviewComponents';
import TotalBlock from 'view/venue/NW2BookingEdit/TotalBlock/TotalBlock';
import LocalStorageService from 'infra/common/localStorage.service';

import {getAddressStringFromLocation} from 'utils/stringUtils';
import {findCoverImage} from 'utils/helpers';
import {useAppSelector} from 'store/hooks';

import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EMPTY_ARRAY} from 'constants/app';
import {getCustomerBookedUnits} from 'store/customer/apiActions';
import {StyledMain} from 'view/venue/NW2BookingPreview/NW2BookingPreview.styles';
import {
  getBillingAddressDto,
  IEditFormState,
} from 'view/venue/NW2BookingEdit/helpers';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';

function FormComponent({
  handleSubmit,
  values,
  dirty,
}: FormRenderProps<IEditFormState>) {
  const packages = useAppSelector(({packages}) => packages.packages);
  const bookingOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder,
  );
  const customerBookedUnitsToReCalculate = useAppSelector(
    ({customer}) => customer.customerBookedUnitsToReCalculate,
  );
  const venueDetails = useSelector((state) =>
    _get(state, 'venuesReducer.venueDetails'),
  );
  const [isBookedUnitsDirty, setIsBookedUnitsDirty] = useState(false);

  //const {venueUnits} = useVenueDetailsData();
  const venueUnits = venueDetails.units;

  const isCustomerBookedUnitsToReCalculate =
    customerBookedUnitsToReCalculate.length;

  const customerBookedUnits = isCustomerBookedUnitsToReCalculate
    ? getCustomerBookedUnits(customerBookedUnitsToReCalculate, packages)
    : EMPTY_ARRAY;

  const isMultiRooms = customerBookedUnits.length > 1;

  const {
    currency,
    name,
    documents,
    location,
    // units: venueUnits,
    accommodationType,
  } = venueDetails;

  const venueAddress = getAddressStringFromLocation(location);
  const venueCoverImage = useMemo(() => findCoverImage(documents), [documents]);
  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;
  const cancellationPolicyText = useAppSelector(
    ({customer}) => customer.cancellationPolicyText,
  );

  function customizer(
    objValue: ICustomerBillingAddress,
    otherValue: ICustomerBillingAddress,
  ) {
    if (
      (objValue === null && otherValue === undefined) ||
      (objValue === undefined && otherValue === null)
    ) {
      return true;
    }
  }

  const billingAddressDto = getBillingAddressDto(values);
  const isBookedUnitsEqual = _isEqual(
    values?.orderDays,
    bookingOrder?.orderDays,
  );

  const isBillingAddressEqual = _isEqualWith(
    billingAddressDto,
    bookingOrder?.billingAddressDto,
    customizer,
  );
  useEffect(() => {
    if (!bookingOrder || !values.orderDays.length) return;

    if (!isBookedUnitsEqual || !isBillingAddressEqual)
      return setIsBookedUnitsDirty(true);
    setIsBookedUnitsDirty(false);
  }, [values, bookingOrder, isBookedUnitsEqual, isBillingAddressEqual]);

  const onKeyPress = (e: KeyboardEvent) => {
    e.key === 'Enter' && e.preventDefault();
  };

  const onFormChange = (formData: FormState<IEditFormState>) => {
    LocalStorageService.setByKey(
      'userData',
      JSON.stringify({
        ...formData.values,
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={onKeyPress}>
      <StyledMain>
        <PreviewComponents
          venueUnits={venueUnits}
          bookedUnits={customerBookedUnits}
          isMultiRooms={isMultiRooms}
          isOffice={isOffice}
          cancellationPolicyText={cancellationPolicyText}
        />
        <TotalBlock
          venueName={name}
          venueUnits={venueUnits}
          previewUnits={customerBookedUnits}
          venueCoverImage={venueCoverImage}
          venueAddress={venueAddress}
          currency={currency}
          isOffice={isOffice}
          isSubmitActive={dirty || isBookedUnitsDirty}
        />
      </StyledMain>

      <FormSpy subscription={{values: true}} onChange={onFormChange} />
    </form>
  );
}

export default FormComponent;
