import {combineReducers} from 'redux';

import app from './app/appSlice';
import bookingsSupplierDesks from './bookingsSupplierDesks/reducer';
import bookingsCustomer from './bookingsCustomer/bookingsCustomerSlice';
import bookingsSupplier from './bookingsSupplier/bookingsSupplierSlice';
import venuesReducer from './venues/reducer';
import venueReducer from './venue/reducer';
import payment from './payment/paymentSlice';
import customer from 'store/customer/customerSlice';
import search from './search/searchSlice';
import offers from 'store/offers/offersSlice';
import corporate from 'store/corporateOffice/corporateOfficeSlice';
import admin from './admin/adminSlice';
import venueDetails from './venueDetails/venueDetailsSlice';
import agent from './agent/agentSlice';
import packages from './packages/packagesSlice';
import workDesks from './workDesks/workDesksSlice';
import cancellationPolicy from './cancellationPolicy/cancellationPolicySlice';

// MAIN REDUCER WHICH BRINGS OTHERS TOGETHER
const rootReducer = combineReducers({
  app,
  admin,
  agent,
  bookingsCustomer,
  bookingsSupplier,
  bookingsSupplierDesks,
  cancellationPolicy,
  corporate,
  customer,
  offers,
  packages,
  payment,
  search,
  venuesReducer,
  venue: venueReducer,
  venueDetails,
  workDesks,
});

export default rootReducer;
