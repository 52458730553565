import React, {ButtonHTMLAttributes, memo, SyntheticEvent} from 'react';

import NW2Button from 'view/components/NW2Button';
import {TButtonSize} from 'view/components/NW2Button/types';

import {ButtonsWrapper} from './NMMSubmitSection.styles';

interface IProps {
  cancelLabel?: string;
  submitLabel?: string;
  isLoading?: boolean;
  size?: TButtonSize;
  disabled?: boolean;
  gap?: string;
  margin?: string;
  justifyContent?: string;
  submitButtonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  handleSubmit?: (e?: SyntheticEvent) => void;
  handleCancel: (e?: SyntheticEvent) => void;
}

const NMMSubmitSection = ({
  cancelLabel = 'Cancel',
  submitLabel = 'Save changes',
  isLoading,
  disabled,
  size = 'small',
  submitButtonType = 'submit',
  gap,
  margin,
  handleCancel,
  handleSubmit,
  justifyContent = 'flex-start',
}: IProps) => (
  <ButtonsWrapper gap={gap} margin={margin} justifyContent={justifyContent}>
    <NW2Button
      loading={isLoading}
      size={size}
      onClick={handleCancel}
      data-testid='form-cancel-button'
    >
      {cancelLabel}
    </NW2Button>

    <NW2Button
      type={submitButtonType}
      buttonType='primary'
      disabled={isLoading || disabled}
      size={size}
      data-testid='form-save-button'
      onClick={handleSubmit}
    >
      {submitLabel}
    </NW2Button>
  </ButtonsWrapper>
);

export default memo(NMMSubmitSection);
