import React, {useCallback} from 'react';
import {use100vh} from 'react-div-100vh';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2VenuesListComponent from '../NW2VenuesListComponent/NW2VenuesListComponent';
import NW2Footer from 'view/common/NW2Footer';

import CONFIG from 'view/venue/NW2VenuesList/NW2VenuesList.config';
import {useAppSelector} from 'store/hooks';
import useFlutterWebView from 'utils/bridgeBTC/useFlutterWebView';
import {HEADER_HEIGHT, HEADER_HEIGHT_MD} from 'constants/app';
import {pluralize} from 'utils/helpers';
import {setIsVenuesListOpened} from 'store/venues/actions';
import {RootState} from 'store/types';
import {
  StyledControlItem,
  StyledDiv,
  StyledList,
  StyledListHeaderMobile,
  StyledListHeaderTitleMobile,
  StyledListHeaderTitleMobileCount,
  StyledListMobile,
  StyledListWrapper,
} from 'view/venue/NW2VenuesList/NW2VenuesList.styles';
import {IMergedVenue} from 'types/search';
import {shortListHeight} from 'constants/styleVars';

type TProps = {
  activeMarkerId: number;
  isGoogleMapExpanded: boolean;
  venuesList: IMergedVenue[];
  totalRecords: number;
  getVenuesWithPagination: () => void;
  onRequestForOfferClick: (venue: IMergedVenue) => () => void;
};
function NW2VenuesContainer({
  activeMarkerId,
  isGoogleMapExpanded,
  venuesList,
  totalRecords,
  getVenuesWithPagination,
  onRequestForOfferClick,
}: TProps) {
  const dispatch = useDispatch();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isListOpened = useSelector((state: RootState) =>
    _get(state, 'venuesReducer.isVenuesListOpened'),
  );
  const navbarHeight: number = useAppSelector(({app}) => app.navbarHeight);
  const loading: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.loading'),
  );
  const shortListItems = useAppSelector(({offers}) => offers.shortList.items);

  const toggleList = useCallback(() => {
    dispatch(setIsVenuesListOpened(!isListOpened));
  }, [isListOpened, dispatch]);

  const height = use100vh() || 0;
  const heightVh = height ? height + 'px' : '100vh';
  const {isBTCApp} = useFlutterWebView();

  // venue list drawer calculations
  const OFFSET = 16;
  const componentsHeights: number = isBTCApp
    ? CONFIG.buttonSearchHeight + OFFSET * 2
    : HEADER_HEIGHT_MD +
      HEADER_HEIGHT +
      navbarHeight +
      CONFIG.buttonSearchHeight +
      OFFSET * 2;

  const totalRecordsFoundText = loading
    ? 'Searching...'
    : `${pluralize('Venue', totalRecords)} found`;

  const drawerHeight = `calc(${heightVh} - ${componentsHeights}px)`;
  const translateYOpened = `calc(-${heightVh} + ${componentsHeights}px + ${
    shortListItems.length ? parseInt(shortListHeight) : 0
  }px)`;
  const translateYClosed = `${-CONFIG.drawerControlItemHeight}px`;

  const translateY = isListOpened ? translateYOpened : translateYClosed;

  const list = (
    <NW2VenuesListComponent
      onRequestForOfferClick={onRequestForOfferClick}
      activeMarkerId={activeMarkerId}
      venuesList={venuesList}
      totalRecords={totalRecords}
      getVenuesWithPagination={getVenuesWithPagination}
    />
  );

  return isMobile ? (
    <StyledListMobile
      height={drawerHeight}
      translateY={translateY}
      hasShortList={!!shortListItems.length}
    >
      <StyledListHeaderMobile>
        <StyledListHeaderTitleMobile onClick={toggleList}>
          <StyledControlItem>
            <span />
          </StyledControlItem>
          <StyledListHeaderTitleMobileCount>
            {totalRecordsFoundText}
          </StyledListHeaderTitleMobileCount>
        </StyledListHeaderTitleMobile>
        {/* TODO: commented for release purpose*/}
        {/*{isListOpened && (*/}
        {/*  <StyledSortMobile>*/}
        {/*    <StyledSortMobileItem>*/}
        {/*      <Icon icon='NW2_SORT' />*/}
        {/*      Sort by*/}
        {/*    </StyledSortMobileItem>*/}
        {/*    <StyledSortMobileItem>*/}
        {/*      <Icon icon='NW2_FILTER' />*/}
        {/*      Filter*/}
        {/*    </StyledSortMobileItem>*/}
        {/*    <StyledSortMobileItem>*/}
        {/*      <Icon icon='NW2_EDIT' />*/}
        {/*      London...*/}
        {/*    </StyledSortMobileItem>*/}
        {/*  </StyledSortMobile>*/}
        {/*)}*/}
      </StyledListHeaderMobile>

      {isListOpened && (
        <StyledListWrapper>
          <StyledDiv>{list}</StyledDiv>
          <NW2Footer showFooter showNavbar={false} />
        </StyledListWrapper>
      )}
    </StyledListMobile>
  ) : (
    <StyledList
      isMapExpanded={isGoogleMapExpanded}
      hasShortList={!!shortListItems.length}
    >
      {list}
    </StyledList>
  );
}

export default NW2VenuesContainer;
