import React, {useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import queryString from 'query-string';
import _get from 'lodash/get';

import NW2Button from 'view/components/NW2Button';
import HeaderSection from './components/HeaderSection';
import NW2SummaryBlock from 'view/components/NW2SummaryBlock';
import FooterMobileContent from './components/FooterMobileContent';
import UnitsSection from './components/UnitsSection';
import LocalStorageService from 'infra/common/localStorage.service';
import DateUtils, {getDateTime} from 'utils/dateUtils';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {makeMultiSearchPayload, makeSummaryData} from '../../utils';
import {Routes} from 'constants/routes';
import {clearVenuesResults, searchVenuesAction} from 'store/venues/actions';
import {getVenuesListQuerySearchData} from 'utils/queryUtils';
import {TDay} from 'types/search';
import {offsetXSm} from 'constants/styleVars';
import {getISOString} from 'utils/dateUtils';
import {TBedroomsCatering} from '../AddAccommodationRequest/types';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {
  setIsFastSearchEnabled,
  setMeetingRequestData,
  setMultiSearchTimeData,
  setSearchCriteria,
} from 'store/search/searchSlice';
import {useMultiSearchData} from 'view/venue/hooks/useMultiSearchData';
import {EEventType} from 'types/venue';
import {addShortList} from 'store/offers/offersSlice';
import {shortListDefaultState} from 'view/venue/Offer/customer/ShortList/constants';
import {checkExtrasData} from 'view/venue/Offer/helpers';
import {ITimeData} from 'types/dto/ISearch.types';
import {ERoomType} from 'types/dto/ERoomType.type';

type TProps = {
  meetingRequestData: TDay[];
  bedroomsCatering: TBedroomsCatering;
  closePopupHandler: () => void;
  setIsSectionExpanded: (
    dayIndex: number,
  ) => (isSectionExpanded: boolean) => void;
};

function SummaryRequestBlock({
  meetingRequestData,
  closePopupHandler,
  setIsSectionExpanded,
  bedroomsCatering,
}: TProps) {
  const [isDrawerShowed, setIsDrawerShowed] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const isGroupSearch = roomType === ERoomType.GROUPS;

  const timeDataFromStore = useAppSelector(
    ({search}) => search.initialTimeData,
  );
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const filteredBedroomExtras = extrasOption.filter(
    (item) => item.type === EResourcesType.BEDROOM,
  );

  const summaryData = makeSummaryData(meetingRequestData);

  const timeData: ITimeData[] = useMemo(
    () =>
      summaryData.map(({startDate, endDate, isPreMeet, isPostMeet}) => ({
        timeStart: startDate,
        timeEnd: endDate,
        eventType: isPreMeet
          ? EEventType.PRE_ARRIVAL
          : isPostMeet
          ? EEventType.POST_EVENT
          : EEventType.DAY,
      })),
    [summaryData],
  );

  const isVenuesListPage = pathname === Routes.venuesList;

  useMultiSearchData({meetingRequestData, timeData});

  const onSearchHandler = () => {
    const querySearchData = getVenuesListQuerySearchData({searchCriteria});
    const {multiSearchPayload, timeData, requestData} = makeMultiSearchPayload({
      meetingRequestData,
      timeDataFromStore,
      bedroomsCatering,
      filteredBedroomExtras,
    });

    // Save multi search data to localstorage in case page refresh
    LocalStorageService.setByKey(
      'multiSearchData',
      JSON.stringify({
        meetingRequestData: requestData,
        timeData,
      }),
    );

    dispatch(setIsFastSearchEnabled(false));

    //clear previous search list results before next search
    dispatch(clearVenuesResults());

    // this prevents extra data fetch when navigating from another page
    // as venue list also fetches data on first render
    if (isVenuesListPage) {
      dispatch(
        searchVenuesAction({
          ...searchCriteria,
          multiRooms: true,
          filterDays: multiSearchPayload,
        }),
      );
    }

    const startDate = DateUtils.normalizeDateToBackendFormat(
      timeData[0].timeStart,
    );
    const endDate = DateUtils.normalizeDateToBackendFormat(
      timeData[timeData.length - 1].timeEnd,
    );
    const endDateBySearchType = isGroupSearch
      ? getISOString(getDateTime(endDate).plus({days: 1}))
      : endDate;

    const search = {
      ...querySearchData,
      startDate,
      endDate: endDateBySearchType,
      page: 0,
      offerPage: 0,
      multiRooms: true,
    };
    dispatch(setSearchCriteria(search));
    dispatch(setMeetingRequestData(requestData));
    dispatch(setMultiSearchTimeData(timeData));

    /**
     * reset shortList items after NEW SEARCH & add search props for compare page
     * implemented in pair with useVenueSearchAction
     */
    dispatch(
      addShortList({
        ...shortListDefaultState,
        checkIn: startDate,
        checkOut: endDate,
        participants: querySearchData.meetingRoomCapacity,
        isMultiDay: true,
        ...checkExtrasData(requestData),
      }),
    );
    navigate({
      pathname: Routes.venuesList,
      search: queryString.stringify(search),
    });
    closePopupHandler();
  };

  const buttonConfirm = (
    <NW2Button buttonType='primary' fullWidth onClick={onSearchHandler}>
      Search now
    </NW2Button>
  );

  if (!summaryData.length) return null;

  const startDate = DateUtils.getDateWithoutHours(summaryData[0].startDate);
  const endDate = DateUtils.getDateWithoutHours(
    summaryData[summaryData.length - 1].startDate,
  );

  return (
    <NW2SummaryBlock
      isMobile={isMobile}
      viewPortSpacing={offsetXSm}
      isDrawerShowed={isDrawerShowed}
      setIsDrawerShowed={setIsDrawerShowed}
      headerSection={<HeaderSection />}
      unitsSection={
        <UnitsSection
          setIsSectionExpanded={setIsSectionExpanded}
          bedroomsCatering={bedroomsCatering}
          summaryData={summaryData}
        />
      }
      buttonConfirm={buttonConfirm}
      footerMobileContent={
        <FooterMobileContent
          startDate={startDate}
          endDate={endDate}
          buttonConfirm={buttonConfirm}
        />
      }
    />
  );
}

export default SummaryRequestBlock;
