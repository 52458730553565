import React, {CSSProperties, ReactNode} from 'react';

import Icon from 'view/components/Icon';
import StyledModal from 'view/components/Modal';

import {modalDefaultWidth, offsetXLg} from 'constants/styleVars';
import {
  ModalTitle,
  ModalGlobalStyle,
  StyledDrawer,
  StyledModalTitle,
} from './NW2ModalDrawer.styles';

interface IProps {
  isShowed: boolean;
  header: ReactNode;
  body?: ReactNode;
  footer?: ReactNode | null;
  modalRender?: ((node: ReactNode) => ReactNode) | undefined;
  modalBodyStyle?: CSSProperties;
  headerStyle?: CSSProperties;
  drawerHeight?: string;
  modalWidth?: string | number;
  modalHeight?: string;
  isMobile: boolean;
  onClose: () => void;
  children?: ReactNode;
  destroyOnClose?: boolean;
  headerPaddingBottom?: string;
  drawerMarginBottom?: string;
  drawerTextColor?: string;
}

function NW2ModalDrawer({
  isShowed,
  header,
  body,
  footer = null,
  modalRender,
  drawerHeight,
  modalWidth,
  modalHeight,
  modalBodyStyle,
  isMobile,
  onClose,
  children,
  destroyOnClose = true,
  headerPaddingBottom,
  drawerMarginBottom,
  headerStyle,
  drawerTextColor,
}: IProps) {
  return (
    <>
      {isMobile ? (
        <StyledDrawer
          title={<ModalTitle>{header}</ModalTitle>}
          footer={footer}
          onClose={onClose}
          open={isShowed}
          height={drawerHeight || '100%'} // height='auto' breaks scroll in case of overflowing content
          closeIcon={<Icon icon='NW2_CLOSE' />}
          placement='bottom'
          destroyOnClose={destroyOnClose}
          bodyStyle={modalBodyStyle}
          headerStyle={headerStyle}
          drawerTextColor={drawerTextColor}
          drawerMarginBottom={drawerMarginBottom}
        >
          {body || children}
        </StyledDrawer>
      ) : (
        <StyledModal
          width={modalWidth || modalDefaultWidth}
          modalHeight={modalHeight}
          title={<StyledModalTitle>{header}</StyledModalTitle>}
          open={isShowed}
          onCancel={onClose}
          footer={footer}
          bodyStyle={
            modalBodyStyle || {
              padding: offsetXLg,
            }
          }
          wrapClassName='modal-custom'
          centered
          modalRender={modalRender}
          destroyOnClose={destroyOnClose}
          headerPaddingBottom={headerPaddingBottom}
        >
          {body || children}
        </StyledModal>
      )}

      <ModalGlobalStyle />
    </>
  );
}

export default NW2ModalDrawer;
