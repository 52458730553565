import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import LocalStorageService from 'infra/common/localStorage.service';
import {getPreselectedExtrasIds} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import useSearchData from './useSearchData';
import {
  EDefaultExtras,
  EResourcesType,
  IExtrasOption,
} from 'types/dto/IExtras.type';
import {TDay} from 'types/search';
import {ERoomSchemaNames} from 'types/venue';
import {useAppSelector} from 'store/hooks';
import {ITimeData} from 'types/dto/ISearch.types';

interface IProps {
  meetingRequestData?: TDay[];
  timeData?: ITimeData[];
}
/**
 * this hook handles multiSearchData from localstorage & build it not exists
 * multiSearchData needs for correct setup of search payload
 */
export function useMultiSearchData({
  meetingRequestData,
  timeData,
}: IProps = {}) {
  const multiSearchData = LocalStorageService.getByKey('multiSearchData');

  const seatsSetup = useAppSelector(
    ({search}) => search.searchCriteria.seatingStyle as ERoomSchemaNames,
  );

  const {initialSearchData, querySearchData} = useSearchData();

  const allExtrasOptions: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const equipmentFilteredOptions = useMemo(
    () =>
      allExtrasOptions.filter(
        (extraItem) => extraItem.type === EResourcesType.EQUIPMENT,
      ),
    [allExtrasOptions],
  );

  const defaultEquipmentData = useMemo(() => {
    const preselectedEquipment = [
      EDefaultExtras.screen_for_video_projection,
      EDefaultExtras.wifi,
      EDefaultExtras.pinboard,
      EDefaultExtras.projector,
      EDefaultExtras.flipchart,
    ];

    const preselectedEquipmentIds = getPreselectedExtrasIds(
      preselectedEquipment,
      equipmentFilteredOptions,
    );

    return {
      options: equipmentFilteredOptions,
      ids: preselectedEquipmentIds,
      data: {
        equipment: preselectedEquipmentIds,
        ...preselectedEquipmentIds.reduce(
          (prev, curr) => ({...prev, [`${curr}_`]: 1}),
          {},
        ),
      },
    };
  }, [equipmentFilteredOptions]);

  useEffect(() => {
    if (
      !multiSearchData ||
      (multiSearchData &&
        !JSON.parse(multiSearchData)?.meetingRequestData.length)
    ) {
      const initialMeetingRequestData = initialSearchData.initialTimeData.map(
        ({timeStart, timeEnd}, index) => ({
          startDate: timeStart,
          endDate: timeEnd,
          participants: querySearchData.meetingRoomCapacity,
          rooms: [
            {
              timeStart,
              timeEnd,
              participants: querySearchData.meetingRoomCapacity,
              equipmentData: defaultEquipmentData,
              seatsSetup,
            },
          ],
          foodBeverageData: null,
          isSectionExpanded: !index,
        }),
      );

      // we should set multiSearchData if it not exists
      LocalStorageService.setByKey(
        'multiSearchData',
        JSON.stringify({
          meetingRequestData: meetingRequestData || initialMeetingRequestData,
          timeData: timeData || initialSearchData.initialTimeData,
        }),
      );
    }
  }, [
    defaultEquipmentData,
    seatsSetup,
    initialSearchData.initialTimeData,
    meetingRequestData,
    multiSearchData,
    querySearchData.meetingRoomCapacity,
    timeData,
  ]);

  return {
    defaultEquipmentData,
    defaultSeatsSetup: seatsSetup,
    equipmentFilteredOptions,
  };
}
