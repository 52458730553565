import {configureStore} from '@reduxjs/toolkit';
import axios from 'axios';

import rootReducer from './rootReducer';
import mainContainer from '../app/index';
import {
  errorInterceptor,
  requestErrorInterceptor,
  requestInterceptor,
  responseInterceptor,
} from 'utils/axiosInterceptors/axiosInterceptors';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: mainContainer,
      },
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

// Moving serverAgent to any other file leads to imports failure,
// either in app running or storybook launch!
export const serverAgent = axios.create();

// Request interceptor
serverAgent.interceptors.request.use(
  requestInterceptor(store),
  requestErrorInterceptor(store),
);

// Response interceptor
serverAgent.interceptors.response.use(
  responseInterceptor(store.dispatch),
  errorInterceptor(store.dispatch),
);

export default store;
