import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _get from 'lodash/get';

import Icon from 'view/components/Icon';
import Image from 'view/components/Image';
import NW2Button from 'view/components/NW2Button';
import TruncateText from 'view/components/TruncateText';
import Equipments from './Equipments';
import HeadInfo from './HeadInfo';
import RoomSetupModal from './RoomSetupModal';

import {TUnitInfo} from 'types/bookingOrders';
import {ERoomSchemaNames} from 'types/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IExtrasResponse} from 'types/dto/IExtras.type';
import {ISummaryUnit} from 'types/dto/IBooking.types';
import {TUnitCapacityStyle} from 'types/dto/IUnit.types';
import {setSpaceDetailsId} from 'store/venues/actions';
import {getRecalculatedUnits} from 'store/customer/apiActions';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {filterByAvailability} from 'utils/venueUtils';
import DateUtils from 'utils/dateUtils';
import {
  checkIsTimeRangeChanged,
  getUnitInfoState,
  getUpdatedExtras,
  IUnitInfoState,
  makeRequestCalculationDataForEquipments,
} from '../../helpers';

import {borderRadiusDef} from 'styles/configs';
import {UnitHead, UnitBox, UnitNameLink, UnitTitle} from '../Space.styles';
import {cleanCustomerSelectedPackages} from 'store/customer/customerSlice';
import {useVenueDetailsId} from 'view/venue/hooks/useVenueDetailsId';

type TProps = {
  dayIndex: number;
  unit: ISummaryUnit;
  roomCounter: string;
  equipmentList: IExtrasResponse[];
  isMultiDay: boolean;
  availableSeatPlans: TUnitCapacityStyle[] | undefined;
  setIsPresetEditModeOn: (value: boolean) => void;
  isPresetEditButtonDisabled: boolean;
};

const SpaceItem = ({
  unit,
  dayIndex,
  roomCounter,
  equipmentList,
  isMultiDay,
  availableSeatPlans,
  setIsPresetEditModeOn,
  isPresetEditButtonDisabled,
}: TProps) => {
  const customerBookedUnitsToReCalculate = useAppSelector(
    ({customer}) => customer.customerBookedUnitsToReCalculate,
  );

  /**
   * todo can't change to useVenueDetailsData because IMultiDayPublicVenue hasn't extras... clarify what to do
   * looks like we need to have useVenueDetailsExtras hook
   */
  const availableExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, 'venuesReducer.venueDetails.extras'),
  );

  const packages = useAppSelector(({packages}) => packages.packages);

  const orderNumber =
    useAppSelector(
      ({customer}) => customer.customerBookingOrder?.orderNumber,
    ) || '';

  const unitImageHeight = 127;
  const {normalizeDateToBackendFormat} = DateUtils;
  const dispatch = useAppDispatch();

  const {
    unitId = 0,
    unitInfo,
    chosenExtras = [],
    setupStyle,
    checkInDate,
    checkOutDate,
    participants,
    unitBookingId: itemUnitBookingId = '',
    chosenPackage: customerBookedPackage,
  } = unit;

  const customerSelectedPackages = useAppSelector(
    ({customer}) => customer.customerSelectedPackages,
  );
  const accommodationIdFromOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.accommodationId,
  );

  const [venueId] = useVenueDetailsId();

  const accommodationId = accommodationIdFromOrder || venueId;

  const {roomType, documents, unitName} = unitInfo as TUnitInfo;

  const availableExtrasByBookable = filterByAvailability<IExtrasResponse>(
    availableExtras,
    roomType,
  );

  const initialUnitInfoState = getUnitInfoState(
    itemUnitBookingId,
    customerBookedUnitsToReCalculate,
    availableExtrasByBookable,
    unit,
  );

  const [unitInfoState, setUnitInfoState] = useState(initialUnitInfoState);
  const [isSpaceEditMode, setIsSpaceEditMode] = useState(false);
  const [isRoomSetupModalShow, setIsRoomSetupModalShow] = useState(false);
  const [setupStylePreSet, setSetupStylePreSet] = useState(
    null as ERoomSchemaNames | null,
  );
  const [isPresetChanged, setIsPresetChanged] = useState(false);

  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;

  const isUnitHeadOffset = !isSpaceEditMode && !equipmentList.length;

  const coverImage = documents?.find((img) => img.isCover);

  const unitTitle = isMeetingRoom ? `Meeting room ${roomCounter}` : 'Work desk';

  const participantsValue = unitInfoState?.participants || participants || 1;
  const checkInDateValue = unitInfoState?.checkInDate || checkInDate;
  const checkOutDateValue = unitInfoState?.checkOutDate || checkOutDate;
  const setupStyleValue =
    setupStylePreSet ||
    unitInfoState?.setupStyle ||
    setupStyle ||
    ERoomSchemaNames.BLOCK;

  const isChangeSetupStyleButtonDisabled =
    !setupStylePreSet || setupStylePreSet === unitInfoState?.setupStyle;

  // buttons and links handlers

  const spaceSaveChangesButtonHandler = () => {
    const updatedData = makeRequestCalculationDataForEquipments(
      customerBookedUnitsToReCalculate,
      itemUnitBookingId,
      unitInfoState.equipments,
      customerSelectedPackages || [],
      unitInfoState as IUnitInfoState,
    );

    const onSuccessHandler = () => {
      setIsSpaceEditMode(false);
      setIsPresetEditModeOn(false);
    };

    dispatch(
      getRecalculatedUnits(
        orderNumber,
        accommodationId,
        updatedData,
        onSuccessHandler,
        packages,
      ),
    );
  };

  const spaceOpenEditButtonHandler = () => {
    setIsSpaceEditMode(true);
    setIsPresetEditModeOn(true);
  };

  const spaceCancelEditButtonHandler = () => {
    setIsSpaceEditMode(false);
    setSetupStylePreSet(null);
    setIsPresetEditModeOn(false);
    setUnitInfoState(initialUnitInfoState);
    dispatch(cleanCustomerSelectedPackages());
  };

  const onRoomSetupModalShow = () => {
    setIsRoomSetupModalShow(true);
  };

  const onRoomSetupModalClose = () => {
    setIsRoomSetupModalShow(false);
    setSetupStylePreSet(null);
    setUnitInfoState({
      ...unitInfoState,
      setupStyle: unitInfoState.setupStyle,
    });
  };

  const onRoomSetupModalSave = () => {
    setIsRoomSetupModalShow(false);
    setUnitInfoState({
      ...unitInfoState,
      setupStyle: setupStylePreSet as ERoomSchemaNames,
    });
  };

  const openSpaceDetails = () => {
    dispatch(setSpaceDetailsId(unitId));
  };

  // field handlers

  const onSetupStyleChange = (e: any) => {
    setSetupStylePreSet(e.target.value);
  };

  const onTimeStartChange = (value: string) => {
    setUnitInfoState({
      ...unitInfoState,
      checkInDate: normalizeDateToBackendFormat(value),
    });
  };

  const onTimeEndChange = (value: string) => {
    setUnitInfoState({
      ...unitInfoState,
      checkOutDate: normalizeDateToBackendFormat(value),
    });
  };

  const onParticipantsChange = (value: number) => {
    setUnitInfoState({...unitInfoState, participants: value});
  };

  const onEquipmentChangeQuantity = (
    extraId: number,
    quantityValue: number,
  ) => {
    const updatedEquipments = getUpdatedExtras(
      unitInfoState.equipments,
      extraId,
      quantityValue,
    );
    setUnitInfoState({...unitInfoState, equipments: updatedEquipments});
  };

  const checkIsTimeRangeChangedCallback = useCallback(
    () =>
      checkIsTimeRangeChanged(
        checkInDate,
        checkOutDate,
        checkInDateValue,
        checkOutDateValue,
      ),
    [checkInDate, checkOutDate, checkInDateValue, checkOutDateValue],
  );

  const checkIsUnitDataChanged = useCallback(() => {
    const omitKeys = ['checkInDate', 'checkOutDate'];
    const currentUnitInfoToRecalculateWithoutDate = _omit(
      unitInfoState,
      omitKeys,
    );
    const unitStateInfoWithoutDate = _omit(initialUnitInfoState, omitKeys);
    return !_isEqual(
      currentUnitInfoToRecalculateWithoutDate,
      unitStateInfoWithoutDate,
    );
  }, [unitInfoState, initialUnitInfoState]);

  useEffect(() => {
    const customerPackageById = customerSelectedPackages
      ? customerSelectedPackages[dayIndex].find((item) => item[0] === unitId)
      : null;

    const customerSelectedPackageData = customerPackageById
      ? customerPackageById[1]
      : null;

    const isUnitDataChanged = checkIsUnitDataChanged();
    const isTimeRangeChanged = checkIsTimeRangeChangedCallback();

    const isSelectedPackageTypeChanged =
      !!customerSelectedPackageData &&
      customerBookedPackage?.type !== customerSelectedPackageData.type;

    const isChanged =
      isUnitDataChanged || isTimeRangeChanged || isSelectedPackageTypeChanged;

    setIsPresetChanged(isChanged);
  }, [
    unitId,
    dayIndex,
    unitInfoState,
    initialUnitInfoState,
    checkIsTimeRangeChangedCallback,
    checkIsUnitDataChanged,
    customerBookedPackage,
    customerSelectedPackages,
  ]);

  return (
    <>
      <UnitHead key={`head_${unitId}`} noBottomOffset={isUnitHeadOffset}>
        <UnitTitle marginBottom={24}>
          <span>{unitTitle}</span>
          {!isSpaceEditMode && (
            <NW2Button
              minimized
              size='small'
              buttonType='tertiary'
              icon={<Icon transparent icon='NW2_EDIT' />}
              onClick={spaceOpenEditButtonHandler}
              disabled={isPresetEditButtonDisabled}
            />
          )}
        </UnitTitle>
        <UnitBox>
          <Image
            height={unitImageHeight}
            borderRadius={borderRadiusDef}
            src={coverImage?.url}
            alt='Unit photo'
          />
          <div>
            <UnitNameLink onClick={openSpaceDetails}>
              <TruncateText text={unitName} hideToggler />
            </UnitNameLink>
            <HeadInfo
              unit={unit}
              setupStyle={setupStyleValue}
              checkInDate={checkInDateValue}
              checkOutDate={checkOutDateValue}
              participants={participantsValue}
              isMeetingRoom={isMeetingRoom}
              isSpaceEditMode={isSpaceEditMode}
              isPackageSelected={Boolean(customerBookedPackage)}
              openRoomSetupModalHandler={onRoomSetupModalShow}
              onTimeStartChange={onTimeStartChange}
              onTimeEndChange={onTimeEndChange}
              onParticipantsChange={onParticipantsChange}
            />
          </div>
        </UnitBox>
      </UnitHead>
      <Equipments
        chosenExtras={chosenExtras}
        availableEquipments={equipmentList}
        equipmentsState={unitInfoState.equipments}
        unitId={unitId}
        dayIndex={dayIndex}
        isMultiDay={isMultiDay}
        isSpaceEditMode={isSpaceEditMode}
        spaceCancelEditButtonHandler={spaceCancelEditButtonHandler}
        spaceSaveChangesButtonHandler={spaceSaveChangesButtonHandler}
        onEquipmentChangeQuantity={onEquipmentChangeQuantity}
        isPresetChanged={isPresetChanged}
        customerBookedPackage={customerBookedPackage}
        participants={participantsValue}
      />
      <RoomSetupModal
        prevSetupStyle={setupStyleValue}
        onSetupStyleChange={onSetupStyleChange}
        availableSeatPlans={availableSeatPlans}
        isRoomSetupModalShow={isRoomSetupModalShow}
        onRoomSetupModalClose={onRoomSetupModalClose}
        onRoomSetupModalSave={onRoomSetupModalSave}
        isChangeSetupStyleButtonDisabled={isChangeSetupStyleButtonDisabled}
      />
    </>
  );
};

export default SpaceItem;
