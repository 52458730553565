import {ERoomType} from 'types/dto/ERoomType.type';
import {ALLOWED_DAYS_DIFFERENCE_FOR_RFP} from 'constants/days';
import {useGetSearchDateDifference} from './useGetSearchDateDifference';
import {useAppSelector} from 'store/hooks';

interface IProps {
  isCorporateVenue: boolean;
}
export function useIsRequestForOfferBtnVisible({
  isCorporateVenue,
}: IProps): [boolean] {
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);

  const [diffInDays] = useGetSearchDateDifference();

  if (diffInDays < ALLOWED_DAYS_DIFFERENCE_FOR_RFP) return [false];

  const isRequestForOfferBtnVisible =
    [ERoomType.MEETING_ROOM, ERoomType.GROUPS].includes(roomType) &&
    !isCorporateVenue;

  return [isRequestForOfferBtnVisible];
}
