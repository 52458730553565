import {useMemo} from 'react';

import {useAppSelector} from 'store/hooks';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IUnit} from 'types/dto/IUnit.types';
import {EVenueType, IUnitResponse} from 'types/dto/IPublicVenue';

/**
 * @type {<T extends IUnit | IUnitResponse = IUnit>} generic type T can be IUnit | IUnitResponse,
 * by default T is IUnit.
 *
 * @example
 * const {venueUnits} = useVenueDetailsData<IUnitResponse>();
 * const venueUnits: IUnitResponse[]
 */
export function useVenueDetailsData<T extends IUnit | IUnitResponse = IUnit>() {
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const venueDetailsWorkDesk = useAppSelector(
    ({workDesks}) => workDesks.workDesksDetails,
  );
  const venueDetailsMeetingRoom = useAppSelector(
    ({venueDetails}) => venueDetails.details,
  );
  const venueDetailsWorkDesksLoading: boolean = useAppSelector(
    ({workDesks}) => workDesks.isLoading,
  );
  const venueDetailsMeetingRoomsLoading = useAppSelector(
    ({venueDetails}) => venueDetails.isLoading,
  );

  const isWorkSpace = roomType === ERoomType.WORK_SPACE;

  const venueDetails: any = useMemo(() => {
    const details = isWorkSpace
      ? venueDetailsWorkDesk
      : venueDetailsMeetingRoom;
    return details || {};
  }, [isWorkSpace, venueDetailsMeetingRoom, venueDetailsWorkDesk]);

  const venueUnits: T[] = useMemo(
    () =>
      isWorkSpace
        ? (venueDetailsWorkDesk?.units as T[])
        : (venueDetailsMeetingRoom?.days
            .flatMap(({rooms}) => rooms)
            .flatMap(({units}) => units) as T[]),
    [isWorkSpace, venueDetailsMeetingRoom?.days, venueDetailsWorkDesk?.units],
  );

  return {
    venueDetails,
    venueUnits,
    isVenueDetailsLoading: isWorkSpace
      ? venueDetailsWorkDesksLoading
      : venueDetailsMeetingRoomsLoading,
    isRfp: venueDetails.type === EVenueType.RFP && !venueDetails.totalPrice,
    isAlternative: venueDetails.type === EVenueType.RFP_WITH_WIP,
  };
}
