import api from 'infra/common/http.service';
import apiContainerBuilder from 'app/apiContainerBuilder';

import makeAdminRepository from 'infra/repository/AdminRepository';
import makeBookingRepository from 'infra/repository/BookingsRepository';
import makePaymentRepository from 'infra/repository/PaymentRepository';
import makeVenueRepository from 'infra/repository/VenueRepository';
import makeVenuesRepository from 'infra/repository/VenuesRepository';
import makeWorkDesksRepository from 'infra/repository/WorkDeskRepository';
import makeOffersRepository from 'infra/repository/OffersRepository';
import makeAppRepository from 'infra/repository/AppRepository';
import makeAgentRepository from 'infra/repository/AgentRepository';

type TVenueContainer = ReturnType<typeof makeVenueRepository>;
type TBookingsContainer = ReturnType<typeof makeBookingRepository>;
type TVenuesContainer = ReturnType<typeof makeVenuesRepository>;
type TWorkDesksContainer = ReturnType<typeof makeWorkDesksRepository>;
type TAdminContainer = ReturnType<typeof makeAdminRepository>;
type TPaymentContainer = ReturnType<typeof makePaymentRepository>;
type TOffersContainer = ReturnType<typeof makeOffersRepository>;
type TAppContainer = ReturnType<typeof makeAppRepository>;
type TAgentContainer = ReturnType<typeof makeAgentRepository>;

const container = {
  venueContainer: apiContainerBuilder<TVenueContainer>({
    repository: makeVenueRepository(api),
  }),
  bookingsContainer: apiContainerBuilder<TBookingsContainer>({
    repository: makeBookingRepository(api),
  }),
  venuesContainer: apiContainerBuilder<TVenuesContainer>({
    repository: makeVenuesRepository(api),
  }),
  workDesksContainer: apiContainerBuilder<TWorkDesksContainer>({
    repository: makeWorkDesksRepository(api),
  }),
  adminContainer: apiContainerBuilder<TAdminContainer>({
    repository: makeAdminRepository(api),
  }),
  paymentContainer: apiContainerBuilder<TPaymentContainer>({
    repository: makePaymentRepository(api),
  }),
  offersContainer: apiContainerBuilder<TOffersContainer>({
    repository: makeOffersRepository(api),
  }),
  appContainer: apiContainerBuilder<TAppContainer>({
    repository: makeAppRepository(api),
  }),
  agentContainer: apiContainerBuilder<TAgentContainer>({
    repository: makeAgentRepository(api),
  }),
};

export type TContainer = typeof container;

export const {
  venueContainer,
  bookingsContainer,
  venuesContainer,
  adminContainer,
  paymentContainer,
  offersContainer,
  appContainer,
  agentContainer,
} = container;

export default container;
