import React, {ChangeEvent} from 'react';
import {Form, Input} from 'antd';

import TextPopover from '../TextPopover';

import {SubPageTitle} from 'view/components/Typography';
import {
  legalNameFieldRules,
  regNumFieldRules,
  taxIdFieldRules,
} from 'utils/antdFormFieldRules';

import {IVenueLegalContact} from 'types/venue';
import {CustomInputNumber, StyledWrapper} from '../ContactDetailsForm.styles';

type TProps = {
  handleCustomNumericFormFields: (
    path: string,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  legalContact: IVenueLegalContact;
  country: string;
};

const BusinessDetails = ({
  handleCustomNumericFormFields,
  legalContact,
  country,
}: TProps) => {
  return (
    <>
      <SubPageTitle>Business details</SubPageTitle>
      <Form.Item
        name='companyName'
        label='Legal business name'
        rules={legalNameFieldRules}
      >
        <StyledWrapper>
          <Input
            name='companyName'
            placeholder='Legal business name'
            onChange={handleCustomNumericFormFields('legalContact.companyName')}
            value={legalContact.companyName}
            allowClear
          />
          <TextPopover textIndex={1} />
        </StyledWrapper>
      </Form.Item>
      <Form.Item
        name='regNumber'
        label='Registration number'
        rules={country === 'United States' ? undefined : regNumFieldRules}
      >
        <StyledWrapper>
          <CustomInputNumber
            name='regNumber'
            placeholder='Registration number'
            onChange={handleCustomNumericFormFields(
              'legalContact.registrationNumber',
            )}
            value={legalContact.registrationNumber}
            allowClear
          />
          <TextPopover textIndex={2} />
        </StyledWrapper>
      </Form.Item>

      <Form.Item
        name='taxNumber'
        label='Company Tax ID'
        rules={taxIdFieldRules}
      >
        <StyledWrapper>
          <CustomInputNumber
            name='taxNumber'
            placeholder='Tax ID'
            onChange={handleCustomNumericFormFields('legalContact.taxNumber')}
            value={legalContact.taxNumber}
            allowClear
          />
          <TextPopover textIndex={3} />
        </StyledWrapper>
      </Form.Item>
    </>
  );
};

export default BusinessDetails;
