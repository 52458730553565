import styled from 'styled-components';
import {Spin} from 'antd';

import CONFIG from '../../NW2VenuesList.config';

import {
  googleMapZIndex,
  mdBp,
  offsetDef,
  offsetXLg,
  shortListHeight,
} from 'constants/styleVars';

export const Container = styled.div`
  height: 100%; // needed by mobile layout!
`;

export const NW2VenuesListMapItemDesktopContainer = styled.div`
  position: relative;
  bottom: -2px;
  z-index: 2;
`;

export const NW2VenuesListMapItemMobileContainer = styled.div<{
  isShortListEmpty?: boolean;
}>`
  position: absolute;
  bottom: calc(
    ${offsetXLg} + ${CONFIG.drawerControlItemHeight}px +
      ${({isShortListEmpty}) =>
        isShortListEmpty ? 0 : parseInt(shortListHeight)}px
  );
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: ${mdBp}) {
    bottom: ${offsetDef};
  }
`;

export const StyledSpin = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${googleMapZIndex};
`;
