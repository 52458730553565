import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import _get from 'lodash/get';

import NW2SummaryBlock from 'view/components/NW2SummaryBlock';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import MeetingRoomTotalPrice from '../../SpacesListBlock/components/MeetingRoomTotalPrice';

import {ActionButtons} from '../../ActionButtons/ActionButtons';
import {ESectionIds} from 'view/venue/NW2VenueDetails/components/AnchorBlock/helpers';
import BookVenueService from 'infra/common/bookVenueService';

import {useAppSelector} from 'store/hooks';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EAccommodationType, IUnitResponse} from 'types/dto/IPublicVenue';
import {StyledSpan} from 'view/components/Typography';
import {
  fontSizeSm,
  fontSizeXLg,
  fontSizeXSm,
  fontSizeXXSm,
  fontWeightBold,
  fontWeightExtraBold,
  mdBp,
  offsetDef,
  offsetSm,
  offsetXSm,
  offsetXXLg,
  shortListHeight,
} from 'constants/styleVars';
import useNavigateToBookingPreview from 'view/venue/NW2VenueDetails/hooks/useNavigateToBookingPreview';
import {useRequestForOffer} from 'view/venue/hooks/useRequestForOffer';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

const BackToOffersContainer = styled.div<{
  hasBedroom?: boolean;
  isSingleContent?: boolean;
}>`
  align-self: center;
  ${({hasBedroom, isSingleContent}) =>
    !hasBedroom &&
    css`
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-align: right;

      > button {
        margin-left: ${isSingleContent ? 0 : offsetDef};
      }
    `};

  @media (min-width: ${mdBp}px) {
    width: auto;
  }
`;

const HeaderContainer = styled.div`
  font-size: ${fontSizeSm};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  margin-top: ${offsetXSm};
  font-weight: ${fontWeightExtraBold};
  margin-bottom: ${offsetSm};
`;

const StyledPriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

type TProps = {
  hasBedroom: boolean;
};

export function BackToOffersBlock({hasBedroom}: TProps) {
  const selectedMeetingRoom = useAppSelector(
    ({venueDetails}) => venueDetails.selectedRoom,
  );
  const isMultiRoomsBookingNotAvailable = useSelector((state) =>
    _get(state, 'venuesReducer.isMultiRoomsBookingNotAvailable'),
  );
  const shortList = useAppSelector(({offers}) => offers.shortList.items);
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const searchedRoomType = useAppSelector(
    ({search}) => search.searchCriteria.roomType,
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const {venueDetails, venueUnits, isAlternative, isRfp} =
    useVenueDetailsData();

  const {accommodationType, currency, totalPrice} = venueDetails;

  const selectedUnitPrice = selectedMeetingRoom?.unitPrice;

  const isMeetingRoom = searchedRoomType === ERoomType.MEETING_ROOM;

  const isGroups = searchedRoomType === ERoomType.GROUPS;

  const redirectToBooking = useNavigateToBookingPreview(
    selectedMeetingRoom as IUnitResponse,
  );

  const bestPriceUnit = BookVenueService.getUnitByMinimumCost(
    venueUnits.filter((unit) => unit.roomType === searchedRoomType),
  );

  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;

  // shortList handlers
  const {isShortListed, onRequestForOfferClick} = useRequestForOffer();

  const onRedirectToBooking = () => {
    if (isMeetingRoom) {
      return redirectToBooking();
    }
    const venueListRef = document.getElementById(ESectionIds.VENUE_LIST);
    if (!venueListRef) return;
    const curScroll = window.scrollY;
    const OFFSET = parseInt(offsetXXLg);

    window.scrollTo({
      top: venueListRef.getBoundingClientRect().top + curScroll - OFFSET,
      behavior: 'smooth',
    });
  };

  const price = totalPrice || selectedUnitPrice;

  return (
    <>
      {(isMeetingRoom || isGroups) && isMobile ? (
        <NW2SummaryBlock
          isMobile={isMobile}
          isDrawerShowed={isModalVisible}
          setIsDrawerShowed={setIsModalVisible}
          offsetClosed={shortList.length && parseInt(shortListHeight)}
          footerPosition={shortList.length ? shortListHeight : '0'}
          unitsSection={<MeetingRoomTotalPrice currency={currency} />}
          headerSection={
            <HeaderContainer>
              <StyledTitle>DETAILS</StyledTitle>
            </HeaderContainer>
          }
          footerMobileContent={
            <BackToOffersContainer
              hasBedroom={hasBedroom}
              isSingleContent={isAlternative}
            >
              <div>
                {!isOffice && !hasBedroom && !isAlternative && !isGroups && (
                  <>
                    <StyledSpan fontSize={fontSizeXXSm} NW2Gray300 noWrap>
                      Price (inc. VAT):
                    </StyledSpan>
                    <StyledPriceBlock>
                      <StyledSpan
                        fontSize={fontSizeXLg}
                        fontWeight={fontWeightBold}
                        white
                      >
                        <PriceDisplay price={price} currency={currency} />
                        &nbsp;
                      </StyledSpan>
                    </StyledPriceBlock>
                  </>
                )}
              </div>

              <ActionButtons
                hasBedroom={hasBedroom}
                isOffice={isOffice}
                isShortListed={isShortListed}
                onRedirectToBooking={onRedirectToBooking}
                isMultiRoomsBookingNotAvailable={
                  isMultiRoomsBookingNotAvailable
                }
                onRequestForOfferClick={onRequestForOfferClick}
                isBothShowed={!isAlternative && !isRfp}
              />
            </BackToOffersContainer>
          }
        />
      ) : (
        <BackToOffersContainer>
          {!isOffice && !isAlternative && !isRfp && !hasBedroom && (
            <div>
              <StyledSpan fontSize={fontSizeXXSm} NW2Gray300 noWrap>
                {isMeetingRoom ? 'Price (inc. VAT):' : 'Starts from:'}
              </StyledSpan>

              <div>
                <StyledSpan
                  fontSize={fontSizeXLg}
                  fontWeight={fontWeightBold}
                  white
                >
                  <PriceDisplay
                    price={
                      (isMeetingRoom || isGroups) && price
                        ? price
                        : bestPriceUnit
                    }
                    currency={currency}
                  />
                  &nbsp;
                </StyledSpan>
                {!isMeetingRoom && (
                  <StyledSpan fontSize={fontSizeXSm} NW2Gray200>
                    / per stay
                  </StyledSpan>
                )}
              </div>
            </div>
          )}

          <ActionButtons
            hasBedroom={hasBedroom}
            isOffice={isOffice || !!totalPrice || !isMeetingRoom}
            isShortListed={isShortListed}
            onRedirectToBooking={onRedirectToBooking}
            isMultiRoomsBookingNotAvailable={isMultiRoomsBookingNotAvailable}
            onRequestForOfferClick={onRequestForOfferClick}
            isBothShowed={!isAlternative && !isRfp}
          />
        </BackToOffersContainer>
      )}
    </>
  );
}
