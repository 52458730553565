import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';

import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';
import SummaryBookingInfo from './components/SummaryBookingInfo';
import BookingDetailsInfo from './components/BookingDetailsInfo';
import NMMSubmitSection from 'view/components/NMMSubmitSection';
import NW2Button from 'view/components/NW2Button';
import BookingChangesStatusModal from '../../BookingChangeOverviewSidebar/components/BookingChangesStatusModal';
import BookingChangeOverviewSidebar from '../../BookingChangeOverviewSidebar/BookingChangeOverviewSidebar';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import useToggle from 'hooks/useToggle';

import {EOrderShowStatus} from 'types/bookingOrders';
import {
  FooterTotalPrice,
  FooterTotalSubTitle,
  FooterTotalTitle,
  FooterWrapper,
} from './BookingDetails.styles';
import {offsetDef} from 'styles/configs/offset';
import {EBookingStatus} from 'types/booking';
import {patchOrderShowStatus} from 'store/bookingsSupplier/apiActions';
import {convertToObject} from 'view/supplier/BookingChangeOverviewSidebar/helpers';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

interface IProps {
  venueTimeZone: string;
  onCloseOrderDetails: () => void;
  getOrders: (props: Record<string, never>) => void;
  onShowConfirmModal: (orderId: string) => (e?: SyntheticEvent) => void;
  onShowDeclineModal: (orderId: string) => (e?: SyntheticEvent) => void;
  isRTC: boolean;
}
const BookingDetails = ({
  venueTimeZone,
  onCloseOrderDetails,
  getOrders,
  onShowConfirmModal,
  onShowDeclineModal,
  isRTC,
}: IProps) => {
  const dispatch = useAppDispatch();

  const bookingDetailsByOrderId = useAppSelector(
    ({bookingsSupplier}) => bookingsSupplier.bookingDetails,
  );
  const bookingChanges = useAppSelector(
    ({bookingsSupplier}) => bookingsSupplier.bookingChanges,
  );
  const isLoading = useAppSelector(
    ({bookingsSupplier}) => bookingsSupplier.isLoading,
  );

  // convert array to object where key is bookingStatus
  const bookingChangesObj = convertToObject(bookingChanges, 'bookingStatus');

  const bookingDetailsFromChangesPending =
    bookingChangesObj[EBookingStatus.RTC_PENDING];

  const bookingDetailsFromChangesConfirmed =
    bookingChangesObj[EBookingStatus.CONFIRMED];

  const bookingDetails = isRTC
    ? bookingDetailsFromChangesPending || bookingDetailsFromChangesConfirmed
    : bookingDetailsByOrderId;

  const bookingStatus = bookingDetails?.bookingStatus;
  const isRTCPendingStatus = bookingStatus === EBookingStatus.RTC_PENDING;
  const [isChangeSidebarVisible, toggleSidebarVisibility] = useToggle();
  const [isChangesStatusModal, handleChangesStatusModal] = useState(isRTC);

  useEffect(() => {
    if (isRTCPendingStatus) {
      toggleSidebarVisibility();
    }
  }, [isRTCPendingStatus, toggleSidebarVisibility]);

  // collecting dates from orderDays
  const dates: string[] = useMemo(() => {
    const orderDays = bookingDetails?.orderDays;
    if (!orderDays) return [];

    const result: string[] = [];

    orderDays.forEach((item) => {
      result.push(item.checkInDate, item.checkOutDate);
    });

    return result;
  }, [bookingDetails?.orderDays]);

  if (!bookingDetails || !bookingStatus) return null;

  const {
    orderId,
    currency,
    isRfp,
    totalPrice,
    showStatus,
    orderNumber,
    nextOrderVersion,
    previousOrderVersion,
    updatedAt,
  } = bookingDetails;

  const isEdited = !!(nextOrderVersion || previousOrderVersion);
  const prevBookingStatus = bookingChanges.find((item) =>
    bookingStatus === EBookingStatus.CONFIRMED && nextOrderVersion
      ? item.orderId === nextOrderVersion
      : item.orderId === previousOrderVersion,
  )?.bookingStatus;

  // Currency
  const totalPriceAndCurrency = (
    <PriceDisplay price={totalPrice} currency={currency} />
  );

  const isRequestStatus = [
    EBookingStatus.RFP_CANCELLED,
    EBookingStatus.RFP_DECLINED,
    EBookingStatus.RFP_EXPIRED,
    EBookingStatus.RFP_PENDING,
  ].includes(bookingStatus);

  const isUnreadShowStatus = showStatus === EOrderShowStatus.UNREAD;

  const onCloseHandler = () => {
    if (orderId && isUnreadShowStatus) {
      const callback = () => getOrders({});
      dispatch(patchOrderShowStatus(orderId, callback));
    }
    if (isChangeSidebarVisible) toggleSidebarVisibility();
  };

  return (
    <>
      <BookingChangesStatusModal
        isShowModal={isChangesStatusModal && !isLoading}
        handleChangesStatusModal={handleChangesStatusModal}
        bookingStatus={prevBookingStatus}
        updatedAt={updatedAt}
        isRTCinSearchParam={isRTC}
      />
      <BookingChangeOverviewSidebar
        isShowModal={isChangeSidebarVisible || isUnreadShowStatus}
        bookingChanges={bookingChanges}
        currency={currency}
        onCloseHandler={onCloseHandler}
        orderNumber={orderNumber}
        handleChangesStatusModal={handleChangesStatusModal}
        bookingChangesObj={bookingChangesObj}
      />
      <NW2ModalTwoColumns
        title={
          isRfp && bookingStatus !== EBookingStatus.CONFIRMED
            ? 'request summary'
            : 'booking summary'
        }
        isShowed
        onToggleShow={onCloseOrderDetails}
        colLeft={
          <SummaryBookingInfo
            bookingDetails={bookingDetails}
            dates={dates}
            totalPriceAndCurrency={totalPriceAndCurrency}
            isRequestStatus={isRequestStatus}
          />
        }
        colRight={
          <BookingDetailsInfo
            bookingDetails={bookingDetails}
            bookingChangesObj={bookingChangesObj}
            prevBookingStatus={prevBookingStatus}
            isEdited={isEdited}
            totalPriceAndCurrency={totalPriceAndCurrency}
            venueTimeZone={venueTimeZone}
            toggleSidebarVisibility={toggleSidebarVisibility}
          />
        }
        footer={
          ((isRfp && bookingStatus === EBookingStatus.RFP_PENDING) || // todo check do we need isRfp here
            isRTCPendingStatus) && (
            <FooterWrapper withPrice={!!totalPrice}>
              <div>
                <FooterTotalTitle>
                  {isRTCPendingStatus ? 'New total' : 'Total request price'}
                </FooterTotalTitle>
                <FooterTotalSubTitle>
                  Includes taxes and fees
                </FooterTotalSubTitle>
              </div>

              <FooterTotalPrice>{totalPriceAndCurrency}</FooterTotalPrice>

              {isRTCPendingStatus ? (
                <NW2Button
                  buttonType='primary'
                  onClick={toggleSidebarVisibility}
                >
                  View & Confirm changes
                </NW2Button>
              ) : (
                <div>
                  <NMMSubmitSection
                    submitLabel='confirm'
                    cancelLabel='decline'
                    handleSubmit={onShowConfirmModal(orderId)}
                    handleCancel={onShowDeclineModal(orderId)}
                    isLoading={false}
                    gap={offsetDef}
                    size='medium'
                    margin='0'
                  />
                </div>
              )}
            </FooterWrapper>
          )
        }
      />
    </>
  );
};

export default BookingDetails;
