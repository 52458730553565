import React from 'react';

import ActionSection from './ActionSection';
import Icon from 'view/components/Icon';
import DeclinedSection from './DeclinedSection';
import ChargeSection from './ChargeSection';
import useGetStatusConfig from './useGetStatusConfig';

import {useAppSelector} from 'store/hooks';

import {
  Container,
  ContentContainer,
  InfoSection,
  InfoSubtitle,
  InfoTitle,
  StyledSubTitle,
  StyledUl,
} from './NW2BookingConfirmationStatus.styles';
import {StyledSpan} from 'view/components/Typography';
import {Group} from 'styles/basicStyledComponents/group';
import {fontWeightBold} from 'styles/configs/typography';
import {offsetDef, offsetXSm} from 'styles/configs/offset';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import {EBookingStatus} from 'types/booking';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

const NW2BookingConfirmationStatus = () => {
  const locale = useAppSelector(({app}) => app.locale);
  const bookingId = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderNumber,
  );

  const isRfp = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.isRfp,
  );

  const declinedReason = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.declinedReason,
  );

  const declinedMessage = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.declinedMessage,
  );

  const createdAt = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.createdAt,
  );

  const {venueDetails} = useVenueDetailsData();

  const accommodationType: EAccommodationType = venueDetails.accommodationType;
  const statusConfig = useGetStatusConfig();

  if (!statusConfig) return null;

  const {
    icon,
    infoTitle,
    infoSubtitle,
    chargeMessage,
    isDeclinedSectionShowed,
    isAskToRegistration,
    isInfoContent,
    currentStatus,
    isEdited,
    offerCreatorName,
  } = statusConfig;

  const isPublicVenue = accommodationType === EAccommodationType.VENUE;

  const isChargeSectionShowed = !!chargeMessage && isPublicVenue;
  const isRequest = currentStatus === EBookingStatus.RTC_PENDING || isRfp;

  return (
    <Container type={currentStatus}>
      <InfoSection>
        <Icon icon={icon} />
        <div>
          <Group direction='column' gap={offsetXSm}>
            <InfoTitle>{infoTitle}</InfoTitle>
            <InfoSubtitle>{infoSubtitle}</InfoSubtitle>
            <InfoSubtitle>
              {isRequest ? 'Request' : 'Booking'} ID:{' '}
              <StyledSpan fontWeight={fontWeightBold}>{bookingId}</StyledSpan>
            </InfoSubtitle>
            {currentStatus === EBookingStatus.CONFIRMED && offerCreatorName && (
              <InfoSubtitle>
                Offer made by: <b>{offerCreatorName}</b>
              </InfoSubtitle>
            )}
          </Group>
        </div>
      </InfoSection>
      {isInfoContent && (
        <ContentContainer>
          <StyledSubTitle>What happens next?</StyledSubTitle>
          <StyledUl>
            <li>The venue has now received your request.</li>
            <li>
              As soon as they respond you will receive a follow-up booking
              confirmation.
            </li>
            {isEdited && (
              <li>
                <b>
                  If the venue is not able to accommodate your changes request,
                  the original booking remains intact unless you choose to
                  cancel it.
                </b>
              </li>
            )}
          </StyledUl>
        </ContentContainer>
      )}
      {isDeclinedSectionShowed && (
        <DeclinedSection reason={declinedReason} message={declinedMessage} />
      )}
      {isChargeSectionShowed && (
        <ChargeSection status={currentStatus} message={chargeMessage} />
      )}
      {isEdited && createdAt && (
        <InfoSubtitle offsetTop={offsetDef}>
          This booking has last been modified on{' '}
          <StyledSpan fontWeight={fontWeightBold}>
            {DateUtils.getFormattedDateTime(
              createdAt,
              venueDetails.location.timeZone,
              DateFormats['01 Jan, 2023'],
              locale,
            )}
          </StyledSpan>
          .
        </InfoSubtitle>
      )}
      {isAskToRegistration && <ActionSection />}
    </Container>
  );
};

export default NW2BookingConfirmationStatus;
