import {message} from 'antd';
import _get from 'lodash/get';

import {AppDispatch, RootState} from 'store/types';
import {TContainer} from 'app';
import {
  IBookingOrderPayload,
  IBookingOrderResponse,
  ICustomerBookedUnits,
  IOrderDay,
  IRecalculationRequest,
  IUnitBooking,
  TAttendee,
} from 'types/dto/IBooking.types';

import {
  setCustomerBookingOrder,
  setIsLoading,
  setCustomerBookingOrderFailure,
  setIsUpdateAttendeesLoading,
  setUpdatedAttendees,
  setCustomerBookedUnits,
  setCustomerOverviewOrders,
  setCancellationPolicyTextLoading,
  getCancellationPolicyTextSuccess,
  setCustomerBookedUnitsToReCalculate,
  setIsCustomerBookedUnitsReCalculateLoading,
  setCustomerPreviousBookingOrder,
} from 'store/customer/customerSlice';
import {getBookingFailure} from 'store/venues/actions';
import {getCustomerBillingAddresses} from 'store/app/apiActions';
import {
  IBookingChanges,
  ICustomerOverviewOrders,
  IGetCustomerOverviewOrdersPayload,
  IUnitBookingDetails,
} from 'types/bookingOrders';
import {setCustomerDefaultBillingAddress} from 'store/app/appSlice';
import {EBookingStatus} from 'types/booking';
import {getSupplierOrdersError} from '../bookingsSupplier/bookingsSupplierSlice';
import {IPackageResponse} from 'types/dto/IPackages.type';
import {getOfferCreator} from 'store/offers/apiActions';
import {IExtraResponse} from 'types/dto/IPublicVenue';
import {EUserRoleCognito} from '../../types/dto/EUserRoleCognito';

export const getCustomerBookedUnits = (
  data: IOrderDay[],
  packages: IPackageResponse[],
) =>
  data.map(
    (day) =>
      ({
        ...day,
        foodAndBeverage: day.foodAndBeverage as IExtraResponse[],
        bedrooms: day.bedrooms as IExtraResponse[],
        unitBookings: day.unitBookings.map((unit) => {
          const chosenPackage = packages.find(
            ({id}) => id && id === unit.packageId,
          );

          return {
            ...unit,
            chosenExtras: unit.bookedExtras,
            chosenPackage: chosenPackage
              ? {
                  ...chosenPackage,
                  price: unit.packagePrice,
                  id: unit.packageId,
                  active: true,
                }
              : null,
          };
        }),
      } as ICustomerBookedUnits),
  );

export const getCustomerBookedUnitsFromOrderDays = (
  data: IBookingOrderResponse,
  packages: IPackageResponse[],
): IOrderDay[] => {
  return data.orderDays.map((orderDay) => {
    return {
      ...orderDay,
      unitBookings: orderDay.unitBookings.map((unitBooking) => {
        const chosenPackage = packages.find(
          ({id}) => id && id === unitBooking.packageId,
        );
        return {
          ...unitBooking,
          chosenExtras: unitBooking.bookedExtras,
          chosenPackage: chosenPackage
            ? {
                ...chosenPackage,
                price: unitBooking.packagePrice,
                id: unitBooking.packageId,
                active: true,
              }
            : null,
        } as IUnitBooking;
      }),
    };
  });
};

export const getCustomerBookingOrder =
  (orderNumber: string, isEditMode?: boolean) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(setIsLoading(true));
    await venuesContainer.getBooking({
      payload: orderNumber,
      onSuccess: (data: IBookingOrderResponse) => {
        dispatch(setCustomerBookingOrder(data));
        // TODO: Remove with updates of Agent View
        const isAgent =
          _get(getState(), 'app.user.role') === EUserRoleCognito.ROLE_AGENT;

        if (!isAgent) {
          dispatch(getOfferCreator(data.orderId));
        }

        const packages = _get(getState(), 'packages.packages');
        const customerBookedUnits = getCustomerBookedUnitsFromOrderDays(
          data,
          packages,
        );
        dispatch(setCustomerBookedUnits(customerBookedUnits));
        if (isEditMode) {
          const defaultBillingAddress = data.billingAddressDto;
          dispatch(setCustomerDefaultBillingAddress(defaultBillingAddress));
        }
      },
      onError: (error: any) => dispatch(setCustomerBookingOrderFailure(error)),
    });
  };

export const getCustomerPreviousBookingOrder =
  (bookingOrder: string, onSuccess?: () => void) =>
  (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
    bookingsContainer.getOrderChanges({
      payload: bookingOrder,
      onSuccess: (data: IBookingChanges[]) => {
        const confirmedBooking = data.find(
          (change) => change.bookingStatus === EBookingStatus.CONFIRMED,
        );
        if (confirmedBooking) {
          dispatch(setCustomerPreviousBookingOrder(confirmedBooking));
        }

        onSuccess?.();
      },
      onError: (error: any) => {
        dispatch(getSupplierOrdersError(error));
      },
    });
  };

const updateCurrentOrderDays =
  (
    customerBookedUnitsToReCalculate: IOrderDay[],
    packages?: IPackageResponse[],
  ) =>
  (day: IOrderDay, index: number) => {
    const currentDay = customerBookedUnitsToReCalculate[index];

    return {
      ...currentDay,
      foodAndBeverage: day.foodAndBeverage,
      bedrooms: day.bedrooms,
      totalPriceForDay: day.totalPriceForDay,
      totalPriceForPackageSet: day.totalPriceForPackageSet,
      unitBookings: day.unitBookings.map((unit) => {
        const currentUnit = (currentDay.unitBookings as IUnitBooking[]).find(
          (currentUnits: IUnitBooking) =>
            currentUnits.unitBookingId === unit.unitBookingId,
        ) as IUnitBooking;
        const chosenPackage = packages?.find(
          ({id}) => id && id === unit.packageId,
        );

        return {
          ...currentUnit,
          ...unit,
          unitInfo: currentUnit.unitInfo,
          unitType: currentUnit.unitType,
          chosenPackage: chosenPackage
            ? {
                ...chosenPackage,
                price: currentUnit.packagePrice,
                id: currentUnit.packageId,
                active: true,
              }
            : null,
        };
      }) as IUnitBooking[] | IUnitBookingDetails[],
    };
  };

export const getRecalculatedUnits =
  (
    orderNumber: string,
    accommodationId: number,
    unitsToRecalculate: IRecalculationRequest[],
    onSuccessHandler: () => void,
    packages?: IPackageResponse[],
  ) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(setIsCustomerBookedUnitsReCalculateLoading(true));
    await venuesContainer.getRecalculatedUnits({
      payload: {orderNumber, accommodationId, unitsToRecalculate},
      onSuccess: (recalculatedDays: IOrderDay[]) => {
        const state = getState();
        const recalculatedData: IOrderDay[] = recalculatedDays.map(
          updateCurrentOrderDays(
            state.customer.customerBookedUnitsToReCalculate,
            packages,
          ),
        );
        dispatch(setIsCustomerBookedUnitsReCalculateLoading(false));
        dispatch(setCustomerBookedUnitsToReCalculate(recalculatedData));
        onSuccessHandler();
      },
      onError: () => {
        dispatch(setIsCustomerBookedUnitsReCalculateLoading(false));
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const handleOnSuccessPostCustomerBookingOrder =
  (response: IBookingOrderResponse) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const customerIdFromStore = getState().app.user.id || '';
    const packages = _get(getState(), 'packages.packages');
    const customerBookedUnits = getCustomerBookedUnitsFromOrderDays(
      response,
      packages,
    );

    dispatch(setCustomerBookingOrder(response));
    dispatch(getOfferCreator(response.orderId));
    dispatch(setCustomerBookedUnits(customerBookedUnits));
    // dispatch(setCustomerBookedUnitsToReCalculate(customerBookedUnits));

    if (customerIdFromStore) {
      dispatch(getCustomerBillingAddresses(+customerIdFromStore)); // refetch billing addresses
    }
  };

export const postCustomerBookingOrder =
  (data: IBookingOrderPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(setIsLoading(true));
    await venuesContainer.confirmBooking({
      payload: data,
      onSuccess: (response: IBookingOrderResponse) => {
        dispatch(handleOnSuccessPostCustomerBookingOrder(response));
      },
      onError: () => {
        dispatch(setIsLoading(false));
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const getCancellationPolicyText =
  (maxParticipants: number, isRtb: boolean, isMultiDay: boolean) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {bookingsContainer}: TContainer,
  ) => {
    dispatch(setCancellationPolicyTextLoading(true));
    await bookingsContainer.getCancellationPolicyText({
      payload: {maxParticipants, isRtb, isMultiDay},
      onSuccess: (data: string) =>
        dispatch(getCancellationPolicyTextSuccess(data)),
      onError: () => dispatch(setCancellationPolicyTextLoading(false)),
    });
  };

export const updateBookingAttendeesAction =
  (bookingId: string, attendees: string[]) =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    {venuesContainer}: TContainer,
  ): void => {
    dispatch(setIsUpdateAttendeesLoading(true));

    venuesContainer.updateBookingAttendees({
      payload: {bookingId, attendees},
      onSuccess: ({data}: {data: TAttendee[]}) => {
        dispatch(setUpdatedAttendees(data));
        dispatch(setIsUpdateAttendeesLoading(false));
      },
      onError: (error: any) => {
        dispatch(getBookingFailure(error));
        dispatch(setIsUpdateAttendeesLoading(false));
      },
    });
  };

export const cancelOrderAction =
  (bookingId: string) =>
  (dispatch: AppDispatch, _: unknown, {venuesContainer}: TContainer) => {
    dispatch(setIsLoading(true));
    venuesContainer.cancelBooking({
      payload: bookingId,
      onSuccess: (data: IBookingOrderResponse) => {
        dispatch(handleOnSuccessPostCustomerBookingOrder(data));
      },
      onError: () => {
        dispatch(setIsLoading(false));
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const cancelRTCOrderAction =
  (orderId: string) =>
  (dispatch: AppDispatch, _: unknown, {venuesContainer}: TContainer) => {
    dispatch(setIsLoading(true));
    venuesContainer.cancelRTCBooking({
      payload: orderId,
      onSuccess: (data: IBookingOrderResponse) => {
        dispatch(handleOnSuccessPostCustomerBookingOrder(data));
      },
      onError: () => {
        dispatch(setIsLoading(false));
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const cancelRTCOrderAllAction =
  (orderNumber: string) =>
  (dispatch: AppDispatch, _: unknown, {venuesContainer}: TContainer) => {
    dispatch(setIsLoading(true));
    venuesContainer.cancelRTCBookingAll({
      payload: orderNumber,
      onSuccess: (data: IBookingOrderResponse) => {
        dispatch(handleOnSuccessPostCustomerBookingOrder(data));
      },
      onError: () => {
        dispatch(setIsLoading(false));
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const getCustomerOrders =
  ({
    page,
    limit,
    searchTab,
    sortBy,
    userId,
    sortingDirection,
  }: IGetCustomerOverviewOrdersPayload) =>
  async (dispatch: AppDispatch, _: any, {bookingsContainer}: TContainer) => {
    dispatch(setIsLoading(true));
    await bookingsContainer.getCustomerOrders({
      payload: {
        page,
        limit,
        searchTab,
        userId,
        sortBy,
        sortingDirection,
      },
      onSuccess: (data: ICustomerOverviewOrders) =>
        dispatch(setCustomerOverviewOrders(data)),

      onError: (error: any) => {
        dispatch(
          setCustomerOverviewOrders({
            pageItems: [],
            size: 0,
            totalFilteredRecords: 0,
          }),
        );
        dispatch(setCustomerBookingOrderFailure(error));
      },
    });
  };
