import {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {message} from 'antd';
import {MessageType} from 'antd/lib/message';
import queryString from 'query-string';

import {Routes} from 'constants/routes';
import {
  setIsFastSearchEnabled,
  setMeetingRequestData,
  setSearchCriteria,
  setSearchListLoaded,
} from 'store/search/searchSlice';
import LocalStorageService from 'infra/common/localStorage.service';
import {addShortList, clearShortList} from 'store/offers/offersSlice';
import {setHeaderCollapsed} from 'store/app/appSlice';
import {
  clearVenuesResults,
  searchVenuesAction,
  setIsSearchTemplatePopupVisible,
} from 'store/venues/actions';
import {searchWorkDesksAction} from 'store/workDesks/apiActions';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useQuery} from 'hooks/useQuery';
import {getVenuesListQuerySearchData} from 'utils/queryUtils';
import {shortListDefaultState} from 'view/venue/Offer/customer/ShortList/constants';
import {
  makeFastSearchFilterDaysPayload,
  makeGroupsFastSearchFilterDaysPayload,
  makeMultiSearchPayload,
} from './components/ExtendedMeetingRoomsPopup/utils';
import {ERoomType} from 'types/dto/ERoomType.type';
import DateUtils from 'utils/dateUtils';
import {useMultiSearchData} from 'view/venue/hooks/useMultiSearchData';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {IExtrasOption} from 'types/dto/IExtras.type';

interface IProps {
  isMinimised?: boolean;
}

export const useSearchVenuesActions = (props?: IProps) => {
  const {isMinimised} = props || {};

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const searchLoading: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.loading'),
  );
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const timeDataFromStore = useAppSelector(
    ({search}) => search.initialTimeData,
  );

  const {isMultiSearchOpen} = useQuery();

  const {
    searchString,
    latitude,
    longitude,
    startDate,
    endDate,
    roomType,
    meetingRoomCapacity,
  } = searchCriteria;

  const openSearchTemplatePopup = useCallback(() => {
    dispatch(setIsSearchTemplatePopupVisible(true));
  }, [dispatch]);

  const groupsRooms = useAppSelector(({search}) => search.groups.rooms);

  const {defaultEquipmentData, defaultSeatsSetup} = useMultiSearchData();

  const isMeetingRooms = roomType === ERoomType.MEETING_ROOM;
  const isGroups = roomType === ERoomType.GROUPS;

  const meetingRequestData = useMemo(() => {
    const meetTimeData = getFilteredUnitsByEventType(timeDataFromStore);

    return meetTimeData.map(({timeStart, timeEnd}, index) => ({
      startDate: timeStart,
      endDate: timeEnd,
      rooms: [
        {
          timeStart,
          timeEnd,
          participants: meetingRoomCapacity,
          equipmentData: defaultEquipmentData,
          seatsSetup: defaultSeatsSetup,
        },
      ],
      foodBeverageData: null,
      accommodationData: null,
      isPreMeet: false,
      isPostMeet: false,
      isSectionExpanded: !index,
      participants: meetingRoomCapacity,
    }));
  }, [
    defaultEquipmentData,
    defaultSeatsSetup,
    meetingRoomCapacity,
    timeDataFromStore,
  ]);

  const validate = useCallback(() => {
    if (!isMultiSearchOpen) {
      if (!searchString || !latitude || !longitude) {
        return message.error({
          type: 'error',
          content: 'Search and select location',
          key: 'errorMessage',
        });
      }
    }

    // ToDo check if this case possible
    if (!startDate || !endDate) {
      return message.error({
        type: 'error',
        content: 'Select booking hours',
        key: 'errorMessage',
      });
    }

    // ToDo check if this case possible
    if (!meetingRoomCapacity && isMeetingRooms) {
      return message.error({
        type: 'error',
        content: 'Select no. of attendees',
        key: 'errorMessage',
      });
    }
  }, [
    endDate,
    isMeetingRooms,
    isMultiSearchOpen,
    latitude,
    longitude,
    meetingRoomCapacity,
    searchString,
    startDate,
  ]);

  const onSearchVenues = useCallback((): MessageType | void => {
    if (validate()) return;

    if (isMeetingRooms || roomType === ERoomType.GROUPS) {
      //cleanup of bedroomsCatering data before Search template
      LocalStorageService.setByKey('bedroomsCatering', '');
      openSearchTemplatePopup();
    }

    if (roomType === ERoomType.WORK_SPACE) {
      const querySearchData = getVenuesListQuerySearchData({searchCriteria});
      const data = {
        ...querySearchData,
        multiRooms: false,
        page: 0,
        offerPage: 0,
        searchString: '',
      };

      dispatch(setIsFastSearchEnabled(false));

      // searchString needed for page refresh till better solution is found
      const search = {...data, searchString};

      // set first page in pagination
      dispatch(setSearchCriteria(search));
      navigate({
        pathname: Routes.venuesList,
        search: queryString.stringify(search),
      });

      // reset shortList items & add dates
      dispatch(
        addShortList({
          ...shortListDefaultState,
          checkIn: search.startDate,
          checkOut: search.endDate,
        }),
      );

      dispatch(searchWorkDesksAction(data));
    }

    // collapse after button 'Search now' pressed
    if (isMinimised && !searchLoading) {
      dispatch(setHeaderCollapsed(true));
    }
  }, [
    validate,
    isMeetingRooms,
    roomType,
    isMinimised,
    searchLoading,
    openSearchTemplatePopup,
    searchCriteria,
    dispatch,
    searchString,
    navigate,
  ]);

  const querySearchData = getVenuesListQuerySearchData({searchCriteria});

  const onFastSearch = useCallback(() => {
    if (validate()) return;

    const {multiSearchPayload, timeData, requestData} = makeMultiSearchPayload({
      meetingRequestData,
      timeDataFromStore,
    });

    const filterDays = isGroups
      ? makeGroupsFastSearchFilterDaysPayload({
          multiSearchPayload,
          groupsRooms,
          extrasOption,
        })
      : makeFastSearchFilterDaysPayload(multiSearchPayload);

    const startDate = DateUtils.normalizeDateToBackendFormat(
      timeData[0].timeStart,
    );
    const endDate = DateUtils.normalizeDateToBackendFormat(
      timeData[timeData.length - 1].timeEnd,
    );

    const search = {
      ...querySearchData,
      startDate,
      endDate,
      page: 0,
      offerPage: 0,
      multiRooms: true,
    };

    // Save multi search data to localstorage in case page refresh
    LocalStorageService.setByKey(
      'multiSearchData',
      JSON.stringify({
        meetingRequestData: requestData.map((day) => ({
          ...day,
          participants: meetingRoomCapacity,
          rooms: day.rooms?.map((room) => ({
            ...room,
            participants: meetingRoomCapacity,
          })),
        })),
        timeData: timeDataFromStore,
      }),
    );

    dispatch(clearVenuesResults());
    dispatch(setSearchListLoaded(true));
    dispatch(setIsFastSearchEnabled(true));

    dispatch(
      searchVenuesAction({
        ...searchCriteria,
        multiRooms: true,
        filterDays,
      }),
    );

    dispatch(setSearchCriteria(search));
    dispatch(setMeetingRequestData(requestData));

    dispatch(clearShortList());

    navigate({
      pathname: Routes.venuesList,
      search: queryString.stringify(search),
    });

    if (isMinimised && !searchLoading) {
      dispatch(setHeaderCollapsed(true));
    }
  }, [
    dispatch,
    extrasOption,
    groupsRooms,
    isGroups,
    isMinimised,
    meetingRequestData,
    meetingRoomCapacity,
    navigate,
    querySearchData,
    searchCriteria,
    searchLoading,
    timeDataFromStore,
    validate,
  ]);

  return {onSearchVenues, onFastSearch};
};
