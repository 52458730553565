export const darkenGrayColor = 'rgba(0, 0, 0, 0.09)';
export const darkGrayColor = '#8c8c8c';
export const lightGrayColor = '#f0f0f0';
export const lighterGrayColor = '#f5f5f5';
export const lightBlueColor = 'rgba(24, 144, 255, 0.08)';
export const lighterBlueColor = '#fafafa';
export const lighterGreenColor = 'rgba(82, 196, 26, 0.08)';

export const blackColor = '#000000';
export const lightBlackColor = '#333333';
export const defaultTextColor = 'rgba(0, 0, 0, 0.85)';
export const lightTextColor = 'rgba(0, 0, 0, 0.65)';
export const whiteColor = '#ffffff';
export const textColor = defaultTextColor;
export const primaryColor = '#1890ff';
export const darkPrimaryColor = '#096dd9';
export const activeColor = lightBlueColor;
export const successColor = '#52c41a';
export const warnColor = '#fcb400';
export const infoColor = '#005e73';
export const errorColor = '#f5222d';
export const lightWarnColor = '#fff8ec';
export const successSecondaryColor = lighterGreenColor;
export const lightErrorColor = '#fddbdd';
export const secondaryColor = darkGrayColor;
export const disabledColor = 'rgba(0, 0, 0, 0.25)';
export const borderColor = lightGrayColor;
export const blockColor = lightBlueColor;
export const cellHoverColor = lighterGrayColor;
export const selectedCellColor = lighterBlueColor;
export const disabledCellColor = 'rgba(0, 0, 0, 0.02)';
export const footerColor = '#001529';
export const iconColor = lightBlackColor;
export const antdLayoutBackgroundColor = '#f0f2f5';

export const NW2Primary = '#00d2ff';
export const NW2Secondary = '#00e696';
export const NW2SecondaryDisabledBgColor = '#f9f9f9';
export const NW2SecondaryDisabledBorderColor = '#ededed';
export const NW2Green50Color = '#e3fcef';
export const NW2Green300Color = '#66f0c0';
export const NW2Green600Color = '#00bf7f';
export const NW2Green500Color = '#00875a';
export const NW2Info50Color = '#e6fcff';
export const NW2Info500Color = '#00a3bf';
export const NW2Gray900Color = '#21272a';
export const NW2Gray800Color = '#343a3f';
export const NW2Gray600Color = '#697077';
export const NW2Gray500Color = '#878d92';
export const NW2Gray400Color = '#a2a9b0';
export const NW2Gray300Color = '#caced2';
export const NW2Gray200Color = '#dde1e6';
export const NW2Gray100Color = '#f2f4f8';
export const NW2Gray50Color = '#fafbfc';
export const NW2Blue50Color = '#E5FBFF';
export const NW2Blue100Color = '#ccf6ff';
export const NW2Error50Color = '#ffebe6';
export const NW2Error500Color = '#de350b';
export const NW2Blue500Color = '#00d2ff';
export const NW2Warn50Color = '#fffae6';
export const NW2Warn500Color = '#ff991f';
export const NW2Blue200Color = '#99edff';
export const NW2Blue800Color = '#0093b3';
export const NW2Surface800Color = '#898989';

export const NW2ErrorLight500Color = '#c8300a';
export const NW2ErrorLight50Color = '#FFF7F5';
export const NW2GrayTextColor = '#595959';
export const NW2SuccessLight50Color = '#F4FEF9';
export const NW2SuccessLight500Color = '#007a51';

export const NW2SecondaryPurple = '#aa50ff';
export const NW2SuccessColor = NW2Green50Color;

export const scrollBarColor = NW2Gray600Color;

export const modalMaskColor = 'rgba(0,0,0,0.45)';

// Components like Typography or Icon handles colors from object below
// so to add color to such components you just need to add color here
export const app_colors = {
  blackColor,
  whiteColor,
  textColor,
  lightTextColor,
  primaryColor,
  darkPrimaryColor,
  activeColor,
  disabledColor,
  successColor,
  warnColor,
  lightWarnColor,
  successSecondaryColor,
  errorColor,
  lightErrorColor,
  secondaryColor,
  blockColor,
  selectedCellColor,
  disabledCellColor,
  cellHoverColor,
  borderColor,
  footerColor,
  iconColor,
  antdLayoutBackgroundColor,
  lightBlueColor,
  lighterBlueColor,
  lightBlackColor,
  darkenGrayColor,

  // NW2 Colors
  NW2Primary,
  NW2Secondary,
  NW2Green300Color,
  NW2Green500Color,
  NW2Green600Color,
  NW2Gray900Color,
  NW2Gray800Color,
  NW2Gray800: NW2Gray800Color,
  NW2Gray600Color,
  NW2Gray600: NW2Gray600Color,
  NW2Gray500Color,
  NW2Gray400Color,
  NW2Gray300Color,
  NW2Gray300: NW2Gray300Color,
  NW2Gray200Color,
  NW2Gray200: NW2Gray200Color,
  NW2Gray100Color,
  NW2Gray50Color,
  NW2Error50Color,
  NW2Blue50Color,
  NW2Blue500Color,
  NW2Blue800Color,
  NW2Surface800Color,
  NW2SecondaryPurple,
  NW2Warn500Color,
  NW2ErrorLight500Color,
  NW2SuccessLight500Color,

  // main colors
  secondary: secondaryColor,
  disabled: disabledColor,
  error: errorColor,
  success: successColor,
  warn: warnColor,
  lightWarn: NW2Warn500Color,
  white: whiteColor,
  info: infoColor,
  transparent: 'inherit',
  lightText: lightTextColor,
  black: blackColor,
};
