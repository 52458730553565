import React from 'react';
import {useDispatch} from 'react-redux';

import TruncateText from 'view/components/TruncateText';
import TimeRangeText from 'view/components/TimeRangeText';

import {findCoverImage, pluralize} from 'utils/helpers';
import {setSpaceDetailsId} from 'store/venues/actions';
import {ERoomSchemaNames, ERoomSchema} from 'types/venue';
import {EAspectRation} from 'view/components/Image/Image';
import {ISummaryUnit} from 'types/dto/IBooking.types';
import {UNIT_NAME_TYPE_MAP} from 'constants/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {Group} from 'styles/basicStyledComponents/group';
import {fontSizeMd, fontWeightBold} from 'styles/configs/typography';
import {TextValue} from 'view/components/TextValue';
import {Link, StyledSpan} from 'view/components/Typography';
import {borderRadiusDef} from 'styles/configs/variables';
import {offsetXSm, offsetXXSm} from 'styles/configs/offset';
import {EResourcesType} from 'types/dto/IExtras.type';
import {
  PreviewExtraList,
  RoomTitle,
  StyledNW2Card,
  Wrapper,
} from './NW2BookingConfirmationUnitsBlock.styles';

type TProps = {
  units: ISummaryUnit[];
  hasCateringAndBedrooms: boolean;
};

function Nw2BookingUnits({units, hasCateringAndBedrooms}: TProps) {
  const dispatch = useDispatch();

  return (
    <>
      {units.map(
        (
          {
            checkOutDate,
            checkInDate,
            participants,
            maxParticipants,
            setupStyle = ERoomSchemaNames.BLOCK,
            unitId = 0,
            unitInfo = {
              roomType: ERoomType.WORK_SPACE,
              unitType: ERoomType.OPEN_SPACE,
              unitName: '',
            },
            chosenExtras,
          },
          index,
        ) => {
          const coverImage = findCoverImage(unitInfo?.documents || []);
          const isWorkDesk = unitInfo.roomType !== ERoomType.MEETING_ROOM;
          const openSpaceDetails = () => dispatch(setSpaceDetailsId(unitId));

          const chosenEquipmentExtras = chosenExtras?.filter(
            ({extraType}) => extraType === EResourcesType.EQUIPMENT,
          );

          return (
            <Wrapper
              key={unitId}
              hasMarginBottom={
                !chosenEquipmentExtras?.length && hasCateringAndBedrooms
              }
            >
              <RoomTitle>Meeting Room {index + 1}</RoomTitle>
              <StyledNW2Card
                image={coverImage.url}
                imageProps={{
                  ratio: EAspectRation['16x9'],
                  borderRadius: borderRadiusDef,
                }}
                bodyText={
                  <Group direction='column' gap={offsetXSm}>
                    <TextValue
                      onClick={openSpaceDetails}
                      fontSize={fontSizeMd}
                      marginTop='0'
                    >
                      <Link>
                        <TruncateText text={unitInfo.unitName} hideToggler />
                      </Link>
                    </TextValue>
                    <Group direction='column' gap={offsetXXSm}>
                      <div>
                        Time:{' '}
                        <b>
                          <TimeRangeText
                            checkIn={checkInDate}
                            checkOut={checkOutDate}
                          />
                        </b>
                      </div>
                      {isWorkDesk ? (
                        <div>
                          Space type:{' '}
                          <StyledSpan fontWeight={fontWeightBold}>
                            {UNIT_NAME_TYPE_MAP[unitInfo.unitType]}
                          </StyledSpan>
                        </div>
                      ) : (
                        <>
                          <div>
                            Room setup:{' '}
                            <StyledSpan fontWeight={fontWeightBold}>
                              {ERoomSchema[setupStyle]}
                            </StyledSpan>
                          </div>
                          <div>
                            Participants:{' '}
                            <StyledSpan fontWeight={fontWeightBold}>
                              {pluralize('people', participants || 0)} (
                              {maxParticipants} max)
                            </StyledSpan>
                          </div>
                        </>
                      )}
                    </Group>
                  </Group>
                }
              />

              {!!chosenEquipmentExtras?.length && (
                <PreviewExtraList hasMarginBottom={hasCateringAndBedrooms}>
                  {chosenEquipmentExtras?.map(({extraName}, idx) => (
                    <span key={extraName}>
                      {!!idx && <> · </>}
                      {extraName}
                    </span>
                  ))}
                </PreviewExtraList>
              )}
            </Wrapper>
          );
        },
      )}
    </>
  );
}

export default Nw2BookingUnits;
