import {useMemo} from 'react';

import LocalStorageService from 'infra/common/localStorage.service';

import {makeMultiSearchPayload} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {setInitialTimeRange} from 'view/components/NW2Datepicker';
import {useQuery} from 'hooks/useQuery';
import {ERoomSchemaNames} from 'types/venue';
import {TBedroomsCatering} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddAccommodationRequest/types';
import {TDay} from 'types/search';
import {ITimeData} from 'types/dto/ISearch.types';

function useInitialMultiRoomData() {
  const {multiRooms, startDate, endDate, seatingPlan} = useQuery();

  const multiSearchData = LocalStorageService.getByKey('multiSearchData');
  const storageBedroomsCatering =
    LocalStorageService.getByKey('bedroomsCatering');

  const parsedMultiSearchData = useMemo(
    (): {meetingRequestData: TDay[]; timeData: ITimeData[]} =>
      multiSearchData ? JSON.parse(multiSearchData) : {},
    [multiSearchData],
  );

  const bedroomsCatering: TBedroomsCatering = useMemo(
    () => (storageBedroomsCatering ? JSON.parse(storageBedroomsCatering) : {}),
    [storageBedroomsCatering],
  );

  const {meetingRequestData = [], timeData} = parsedMultiSearchData;

  const initialTimeData = useMemo(
    () =>
      setInitialTimeRange({
        startTime: startDate as string,
        endTime: endDate as string,
        isMultiSearchRoom: Boolean(multiRooms),
        timeData,
      }),
    [endDate, multiRooms, timeData, startDate],
  );

  const {multiSearchPayload} = useMemo(() => {
    if (meetingRequestData.length) {
      return makeMultiSearchPayload({meetingRequestData});
    }

    const initialData = initialTimeData.map(({timeStart, timeEnd}, index) => ({
      startDate: timeStart,
      endDate: timeEnd,
      rooms: [
        {
          timeStart,
          timeEnd,
          participants: 1,
          equipmentData: {},
          seatsSetup: seatingPlan || ERoomSchemaNames.BLOCK,
        },
      ],
      foodBeverageData: meetingRequestData[index]?.foodBeverageData || null,
      accommodationData: meetingRequestData[index]?.accommodationData || null,
      isSectionExpanded: !index,
    })) as TDay[];

    return makeMultiSearchPayload({meetingRequestData: initialData});
  }, [initialTimeData, meetingRequestData, seatingPlan]);

  return {
    multiSearchPayload,
    meetingRequestData,
    isMultiSearchRoom: multiRooms,
    initialTimeData,
    bedroomsCatering,
  };
}

export default useInitialMultiRoomData;
