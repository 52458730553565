import React from 'react';
import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';

import NW2NoFoundBlock from 'view/components/NW2NoFoundBlock/NW2NoFoundBlock';
import NW2Button from 'view/components/NW2Button';

import {TVenuesQuerySearchData} from 'types/search';
import {getVenueDetailsAction} from 'store/venues/actions';
import {setInitialLocation, setSearchCriteria} from 'store/search/searchSlice';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import useSearchData from 'view/venue/hooks/useSearchData';
import {useGoBackToStoredSearch} from 'view/venue/hooks/useGoBackToStoredSearch';
import {useVenueDetailsId} from 'view/venue/hooks/useVenueDetailsId';
import {ERoomType} from 'types/dto/ERoomType.type';
import {getVenueDetailsWorkDesksAction} from 'store/workDesks/apiActions';

export function NoFoundBlock() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialSearch = useAppSelector(({app}) => app.initialLocation.search);
  const isMultiRooms = useAppSelector(
    ({search}) => search.searchCriteria.multiRooms,
  );
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);

  const [venueId] = useVenueDetailsId();

  const {searchData} = useSearchData();

  const [onGoToSearchResults] = useGoBackToStoredSearch();

  const resetSearchHandler = () => {
    if (initialSearch) {
      const queryData = queryString.parse(initialSearch.slice(1), {
        parseBooleans: true,
      });
      const {
        startDate: queryStartDate,
        endDate: queryEndDate,
        meetingRoomCapacity,
      } = queryData as unknown as TVenuesQuerySearchData;

      const resultStartDate = queryStartDate
        ? queryStartDate.toString()
        : searchData.startDate;

      const resultEndDate = queryEndDate
        ? queryEndDate.toString()
        : searchData.endDate;

      const data = {
        id: venueId,
        isAllSpaces: true,
        ...(queryData as unknown as TVenuesQuerySearchData),
      };
      // get details with updated values from backend

      if (roomType === ERoomType.WORK_SPACE) {
        dispatch(getVenueDetailsWorkDesksAction(data));
      } else {
        dispatch(getVenueDetailsAction(data, isMultiRooms));
      }

      // update url search params
      navigate({search: initialSearch});

      // update data in redux store
      dispatch(
        setSearchCriteria({
          ...searchData,
          meetingRoomCapacity: meetingRoomCapacity,
          startDate: resultStartDate,
          endDate: resultEndDate,
        }),
      );

      // reset initialLocation in redux store
      dispatch(setInitialLocation({search: ''}));
    }
  };

  return (
    <NW2NoFoundBlock
      white
      title='No offers'
      description={
        <div>
          <p>
            Unfortunately, the venue is unable to meet your new search
            requirements. Adjust the search inputs and try again.
            <br />
            Alternatively, go back to results list to see other venues.
          </p>
          <NW2Button
            onClick={onGoToSearchResults}
            buttonType='secondary'
            size='small'
            inverted
          >
            Back to results list
          </NW2Button>
        </div>
      }
      buttons={
        initialSearch && (
          <NW2Button
            inline
            inverted
            size='small'
            buttonType='secondary'
            onClick={resetSearchHandler}
          >
            Reset search inputs
          </NW2Button>
        )
      }
    />
  );
}
