import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import useSearchData from 'view/venue/hooks/useSearchData';
import NW2ActionButton from 'view/components/NW2ActionButton/NW2ActionButton';

import {ShareVenueButton} from 'view/venue/NW2BookingConfirmation/components/ShareVenueButton';
import {
  clearMultiSearchData,
  clearSearchCriteria,
} from 'store/search/searchSlice';
import {getShowCancelBookingButton} from 'utils/venueUtils';
import {setDatepickerDetailsShowed} from 'store/venues/actions';

import {Routes} from 'constants/routes';
import useFlutterWebView from 'utils/bridgeBTC/useFlutterWebView';
import {EBookingStatus} from 'types/booking';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EOfferStatus, ERequestStatus} from 'types/offer';
import {EBookingConfirmationModal} from '../../types';
import {
  ButtonContainer,
  Container,
} from './NW2BookingConfirmationActionButtons.styles';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {useVenueDetailsId} from 'view/venue/hooks/useVenueDetailsId';

enum EActionButton {
  SHARE_BOOKING = 'Invite attendees',
  CANCEL_BOOKING = 'cancel booking',
  CANCEL_REQUEST = 'cancel request',
  NEW_SEARCH = 'Return home',
  SHARE_VENUE = 'share with friends',
  BOOK_AGAIN = 'book again',
  EDIT_BOOKING = 'Edit booking',
}

interface IProps {
  onModalShow: (arg?: EBookingConfirmationModal) => () => void;
  status?: ERequestStatus | EOfferStatus;
  isOfferRequest?: boolean;
  isGroupRequest?: boolean;
}

const NW2BookingConfirmationActionButtons = ({
  onModalShow,
  status,
  isOfferRequest,
  isGroupRequest,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationData = useLocation();

  const orderDays = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderDays,
  );
  const bookingStatus = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.bookingStatus,
  );
  const orderNumber = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderNumber,
  );
  const isEditable = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.isEditable,
  );
  const offerStatus = useAppSelector(({offers}) => offers.offerDetails.status);
  const offerCheckIn = useAppSelector(
    ({offers}) => offers.offerDetails.checkIn,
  );
  const offerCheckOut = useAppSelector(
    ({offers}) => offers.offerDetails.checkOut,
  );
  const offerDetailsDays = useAppSelector(
    ({offers}) => offers.offerDetails.days,
  );
  const isLoggedIntoSystem = useAppSelector(
    ({app}) => app.user.isLoggedIntoSystem,
  );

  const {venueDetails} = useVenueDetailsData();
  const [venueId] = useVenueDetailsId();

  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);

  const {querySearchData} = useSearchData();
  const {postToFlutterApp, isBTCApp} = useFlutterWebView();
  const dayWithUnits = orderDays?.find(
    (day) => day.unitBookings && day.unitBookings.length > 0,
  );

  const singleBookingUnitId = dayWithUnits?.unitBookings[0]?.unitId;
  const unitType = dayWithUnits?.unitBookings[0]?.unitType;

  const showCancelButton = getShowCancelBookingButton(bookingStatus);

  const bookVenueCheckInDate = orderDays && orderDays[0]?.checkInDate;

  const bookVenueCheckOutDate =
    orderDays && orderDays[orderDays.length - 1]?.checkOutDate;
  const isMultiRooms = orderDays && orderDays.length > 1;

  const handleNewSearch = () => {
    if (isBTCApp) {
      postToFlutterApp();
    } else {
      dispatch(clearSearchCriteria());
      dispatch(clearMultiSearchData());
      navigate(Routes.mainLanding, {state: {openSearch: true}});
    }
  };

  const handleBookAgain = () => {
    dispatch(setDatepickerDetailsShowed(true));

    const search = {
      ...querySearchData,
      latitude: venueDetails.location.latitude,
      longitude: venueDetails.location.longitude,
    };

    navigate({
      pathname: `/venue/${venueId}/details`,
      search: queryString.stringify(search),
    });
  };

  const handleBookingEdit = () => {
    if (!orderNumber) return;
    const query: URLSearchParams = new URLSearchParams(locationData.search);

    query.set('venueId', venueId.toString());
    navigate({
      pathname: `/booking/${orderNumber}/edit`,
      search: locationData.search,
    });
  };

  const isSingleParticipant = orderDays
    ? (orderDays[0].unitBookings[0]?.participants ?? 0) === 1
    : searchCriteria.meetingRoomCapacity === 1;

  const isPublic = venueDetails.accommodationType === EAccommodationType.VENUE;

  const isBookingStatusConfirmed = bookingStatus === EBookingStatus.CONFIRMED;

  const isMeetingRoom = unitType === ERoomType.MEETING_ROOM;

  const isShareBookingButtonShowed =
    isMeetingRoom && isBookingStatusConfirmed && !isSingleParticipant;

  const isBookingEditButtonShowed =
    isBookingStatusConfirmed &&
    isPublic &&
    isLoggedIntoSystem &&
    isEditable &&
    ![
      ERoomType.OPEN_SPACE,
      ERoomType.SHARED_SPACE,
      ERoomType.WORK_SPACE,
    ].includes(unitType as ERoomType);

  return (
    <Container>
      {!isMultiRooms &&
        (!isOfferRequest ||
          status === EOfferStatus.OFFER_ACCEPTING_EXPIRED) && (
          <ButtonContainer>
            <ShareVenueButton
              title={EActionButton.SHARE_VENUE}
              unitId={
                isOfferRequest && offerDetailsDays
                  ? offerDetailsDays[0].items?.[0]?.unitId
                  : singleBookingUnitId
              }
              checkInDate={isOfferRequest ? offerCheckIn : bookVenueCheckInDate}
              checkOutDate={
                isOfferRequest ? offerCheckOut : bookVenueCheckOutDate
              }
              isRfp={isOfferRequest}
              status={status}
            />
          </ButtonContainer>
        )}

      {isShareBookingButtonShowed && !isMultiRooms && (
        <ButtonContainer>
          <NW2ActionButton
            icon='NW2_ENVELOP'
            title={EActionButton.SHARE_BOOKING}
            onClick={onModalShow(EBookingConfirmationModal.SHARE_BOOKING)}
            fullWidth
          />
        </ButtonContainer>
      )}

      {!isMeetingRoom && !isOfferRequest && (
        <ButtonContainer>
          <NW2ActionButton
            icon='NW_REFRESH_ARROWS'
            title={EActionButton.BOOK_AGAIN}
            onClick={handleBookAgain}
            fullWidth
          />
        </ButtonContainer>
      )}

      {/*hide return home when we have possibility to edit booking*/}
      {offerStatus !== ERequestStatus.REQUEST_PENDING && !isEditable && (
        <ButtonContainer>
          <NW2ActionButton
            icon='NW2_SEARCH'
            title={EActionButton.NEW_SEARCH}
            onClick={handleNewSearch}
            fullWidth
          />
        </ButtonContainer>
      )}

      {isBookingEditButtonShowed && (
        <ButtonContainer>
          <NW2ActionButton
            icon='NW2_EDIT'
            title={EActionButton.EDIT_BOOKING}
            onClick={handleBookingEdit}
            fullWidth
          />
        </ButtonContainer>
      )}

      {showCancelButton && (
        <ButtonContainer>
          <NW2ActionButton
            icon='NW2_CANCEL_BIN'
            title={
              bookingStatus === EBookingStatus.RTC_PENDING
                ? EActionButton.CANCEL_REQUEST
                : EActionButton.CANCEL_BOOKING
            }
            onClick={onModalShow(
              bookingStatus === EBookingStatus.RTC_PENDING
                ? EBookingConfirmationModal.CANCEL_RTC_BOOKING_MODAL
                : EBookingConfirmationModal.CANCEL_BOOKING_MODAL,
            )}
            fullWidth
          />
        </ButtonContainer>
      )}

      {isOfferRequest && !isGroupRequest && (
        <>
          {offerStatus === ERequestStatus.REQUEST_PENDING && (
            <ButtonContainer>
              <NW2ActionButton
                icon='NW2_CANCEL_BIN'
                title={EActionButton.CANCEL_REQUEST}
                onClick={onModalShow(
                  EBookingConfirmationModal.CANCEL_REQUEST_MODAL,
                )}
                fullWidth
              />
            </ButtonContainer>
          )}
        </>
      )}

      {isGroupRequest && (
        <>
          {offerStatus === ERequestStatus.REQUEST_PENDING && (
            <ButtonContainer>
              <NW2ActionButton
                icon='NW2_CANCEL_BIN'
                title={EActionButton.CANCEL_REQUEST}
                onClick={onModalShow()}
                fullWidth
              />
            </ButtonContainer>
          )}
        </>
      )}

      {/* offerStatus === ERequestStatus.REQUEST_CANCELED && (
            // todo finish with NMM-598
            <ButtonContainer>
              <NW2ActionButton
                icon='NW_REFRESH_ARROWS'
                title='request again'
                onClick={handleRequestAgain}
                fullWidth
              />
            </ButtonContainer>
          ) */}
    </Container>
  );
};

export default NW2BookingConfirmationActionButtons;
