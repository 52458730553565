import React from 'react';
import {useDispatch} from 'react-redux';

import Image from 'view/components/Image';
import NW2Button from 'view/components/NW2Button/NW2Button';
import DeskAvailabilityBlock from './DeskAvailabilityBlock';
import TruncateText from 'view/components/TruncateText';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import RoomCapacityText from './RoomCapacityText';

import useNavigateToBookingPreview from '../../../hooks/useNavigateToBookingPreview';
import {UNIT_NAME_TYPE_MAP} from 'constants/venue';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EVenueUnitStatus, IUnit} from 'types/dto/IUnit.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {borderRadiusDef} from 'constants/styleVars';
import {setSpaceDetailsId} from 'store/venues/actions';
import {
  ImageWrapper,
  Item,
  ItemTitle,
  StyledLink,
  PricesBlock,
  BestPrice,
  RoomStatusText,
  ItemSubTitle,
  CSSTruncatedText,
  ButtonWrapper,
} from './SpaceItem.styles';

type TProps = {
  unit: IUnit;
  isMobile: boolean;
  currency: string;
  searchedRoomCapacity: number;
  accommodationType: EAccommodationType;
};

export function SpaceItem({
  unit,
  isMobile,
  searchedRoomCapacity,
  accommodationType,
  currency,
}: TProps) {
  const dispatch = useDispatch();
  const {
    id,
    name,
    capacity,
    availableCapacity,
    totalSearchPrice,
    type,
    documents,
    unitStatus,
    isRfp,
  } = unit;

  const redirectToBooking = useNavigateToBookingPreview(unit);

  const isMeetingRoom = type === ERoomType.MEETING_ROOM;
  const isPublicVenue = accommodationType === EAccommodationType.VENUE;
  const isNotAvailable = unitStatus !== EVenueUnitStatus.AVAILABLE;
  const isRoomCapacityLow = capacity < searchedRoomCapacity;

  const coverImage = documents.find((img) => img.isCover);

  const buttonType =
    isMeetingRoom && isRoomCapacityLow ? 'secondary' : 'primary';
  const buttonText = isRfp ? 'Request' : 'Book now';
  const buttonSize = isMobile ? 'large' : 'small';

  const venueDetailsSpaceHeight = isMobile ? 230 : 116;

  const openSpaceDetails = () => dispatch(setSpaceDetailsId(id));

  return (
    <Item>
      <ImageWrapper>
        <Image
          height={venueDetailsSpaceHeight}
          borderRadius={borderRadiusDef}
          src={coverImage?.url}
          alt='Space photo'
        />
      </ImageWrapper>
      <ItemTitle>
        {isMobile ? (
          <StyledLink onClick={openSpaceDetails}>
            <CSSTruncatedText>{name}</CSSTruncatedText>
          </StyledLink>
        ) : (
          <StyledLink onClick={openSpaceDetails}>
            <TruncateText text={name} hideToggler />
          </StyledLink>
        )}
      </ItemTitle>
      <ItemSubTitle>
        {isMeetingRoom ? (
          <RoomCapacityText
            capacity={capacity}
            isRoomCapacityLow={isRoomCapacityLow}
            isNotAvailable={isNotAvailable}
          />
        ) : (
          UNIT_NAME_TYPE_MAP[type]
        )}
      </ItemSubTitle>
      {isPublicVenue && (
        <PricesBlock>
          <div>
            <BestPrice>
              <PriceDisplay price={totalSearchPrice} currency={currency} />
            </BestPrice>{' '}
            / per stay
          </div>
        </PricesBlock>
      )}
      {!isMeetingRoom && (
        <DeskAvailabilityBlock
          isPublicVenue={isPublicVenue}
          capacity={availableCapacity}
        />
      )}
      <ButtonWrapper>
        <NW2Button
          size={buttonSize}
          inverted={isMeetingRoom && isRoomCapacityLow}
          disabled={isNotAvailable}
          buttonType={buttonType}
          onClick={redirectToBooking}
          fullWidth={isMobile}
          inline
        >
          {buttonText}
        </NW2Button>
      </ButtonWrapper>
      {isMeetingRoom &&
        (isNotAvailable ? (
          <RoomStatusText>not available</RoomStatusText>
        ) : isRoomCapacityLow ? (
          <RoomStatusText>*capacity lower</RoomStatusText>
        ) : null)}
    </Item>
  );
}
