import React, {useEffect, useMemo} from 'react';
import {useForm} from 'react-final-form';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import UserDetails from 'view/venue/NW2BookingPreview/components/UserDetails/UserDetails';
import Space from 'view/venue/NW2BookingPreview/components/Space/Space';
import NW2BookingAttendees from 'view/venue/NW2BookingPreview/components/NW2BookingAttendees/NW2BookingAttendees';
import AboutMeetingForm from '../AboutMeetingForm/AboutMeetingForm';
import NW2SpaceDetails from 'view/venue/NW2VenueDetails/components/NW2SpaceDetails/NW2SpaceDetails';
import BillingAddressForm from 'view/components/BillingAddress/BillingAddressForm';
import BillingAddressViewWithReferenceField from 'view/components/BillingAddress/BillingAddressViewWithReferenceField';
import BookingLeftSideSpaces from '../Space/components/BookingLeftSideSpaces';
import {CancellationPolicyBlock} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import CancellationPolicyTextElement from 'view/components/CancellationPolicyTextElement/CancellationPolicyTextElement';
import {BookingPayment} from 'view/venue/BookingPayment/BookingPayment';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EBookingFormFields, EFormValidationName} from 'types/venue';
import {
  IPreviewUnitResponse,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {StyledLeftSideContainer} from 'view/venue/components/LeftSideContainer/LeftSideContainer.styles';
import {setOpenLoginPopup} from 'store/app/appSlice';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {getFlatMapUnits} from 'view/customer/helpers';
import useUpdateCancellationPolicyText from 'view/venue/hooks/useUpdateCancellationPolicyText';
import CancelPolicyInfoCard from 'view/components/CancelPolicyInfoCard/CancelPolicyInfoCard';
import {ECancelPolicyInfoCardComponent} from 'view/components/CancelPolicyInfoCard/types';
import {useCustomerOrderUnits} from 'view/venue/hooks/useCustomerOrderUnits';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {EPaymentType} from 'types/bookingOrders';
import {useFeatureToggle} from 'hooks/useFeatureToggle';
import {EFeatureToggles} from 'constants/featureToggles';

type TProps = {
  isShowLoginModal: boolean;
};

function BookPreviewComponents({isShowLoginModal}: TProps) {
  const dispatch = useAppDispatch();
  const form = useForm();

  const token = useAppSelector(({app}) => app.token);
  const user = useAppSelector(({app}) => app.user);
  const filterData = useAppSelector(({search}) => search.multiSearchPayload);
  const billingAddresses = useAppSelector(
    ({app}) => app.user.billingAddress || [],
  );
  const hasBillingAddress = billingAddresses.some(
    (address: ICustomerBillingAddress) => address.isDefault,
  );
  const isRegistrationAction = useAppSelector(
    ({app}) => app.isRegistrationAction,
  );
  const defaultBillingAddress = useAppSelector(
    ({app}) => app.user.defaultBillingAddress,
  );
  const corporateBillingAddress = useAppSelector(
    ({payment}) => payment.corporateBillingAddress,
  );
  const paymentType = useAppSelector(({payment}) => payment.paymentType);
  const isDirectPayment = paymentType === EPaymentType.DIRECT_PAYMENT;
  const isCorporatePayment = paymentType === EPaymentType.CORPORATE_HRS_PAY;

  const {previewDesks, previewUnits} = useCustomerOrderUnits();
  const {isLoggedInUser} = useLoggedInUser();

  const role = user.role;

  useEffect(() => {
    if (isShowLoginModal && !isRegistrationAction) {
      dispatch(setOpenLoginPopup({openLoginPopup: true}));
    }
  }, [dispatch, isShowLoginModal, isRegistrationAction]);

  useEffect(() => {
    if (!previewUnits.length) return;
    form.change(EBookingFormFields.ORDER_DAYS, previewUnits);
    form.change(
      EBookingFormFields.PAYMENT_TYPE,
      EPaymentType.CORPORATE_HRS_PAY,
    );
  }, [form, previewUnits]);

  useEffect(() => {
    const defaultBillingAddressValues = {
      companyName: '',
      costCenter: '',
      country: '',
      city: '',
      postCode: '',
      streetHouseNumber: '',
      uuid: '',
    };
    const billingAddress: Partial<ICustomerBillingAddress> =
      user.billingAddress.find(
        (address: ICustomerBillingAddress) => address.isDefault,
      ) || defaultBillingAddressValues;

    form.batch(() => {
      form.change('firstName', user.firstName);
      form.change('lastName', user.lastName);
      form.change('email', user.email);
      form.change('phone', user.phone);

      // iterate object to set keys and values to form
      for (const key in defaultBillingAddressValues) {
        form.change(key, billingAddress[key as keyof ICustomerBillingAddress]);
      }
    });
  }, [form, user]);

  const isMultiRooms = previewUnits.length > 1;
  const singleVenueUnit =
    !isMultiRooms && previewUnits.length ? previewUnits[0].units[0] : undefined;

  const isDetailsHidden = !!(token && role === EUserRoleCognito.ROLE_GUEST);
  const isMeetingRoom =
    isMultiRooms || singleVenueUnit?.type === ERoomType.MEETING_ROOM;

  const venueUnitAvailableCapacity =
    (singleVenueUnit as IPreviewUnitResponse)?.requestedCapacity ?? 0;

  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );

  const {venueDetails} = useVenueDetailsData();
  const accommodationType = venueDetails.accommodationType;
  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;

  const isAttendeesHidden =
    !isMeetingRoom ||
    venueUnitAvailableCapacity <= 1 ||
    !singleVenueUnit ||
    singleVenueUnit?.isRtb ||
    participants === 1;

  const hasCatering = previewUnits.some((unit) => unit.foodAndBeverage.length);
  const checkIn = previewUnits?.[0]?.checkInDate;

  const unitData = useMemo(
    () => getFlatMapUnits(previewUnits as TGroupedUnitsByDay[]),
    [previewUnits],
  );

  const isRtb = isMultiRooms
    ? unitData.some(({isRtb}) => isRtb)
    : !!unitData[0]?.isRtb;

  useUpdateCancellationPolicyText({
    unitData,
    isRtb,
  });

  const cancellationPolicyText = useAppSelector(
    (state) => state.customer.cancellationPolicyText,
  );

  const eventType = previewUnits.some((day) => !day.unitBookings.length)
    ? ERoomType.GROUPS
    : ERoomType.MEETING_ROOM;

  const maxParticipants = Math.max(
    ...filterData.map((d) => d.maxParticipants || -Infinity),
  );

  const [isHrsPayEnabled] = useFeatureToggle(EFeatureToggles.HRS_PAY);

  return (
    <StyledLeftSideContainer>
      {previewUnits.length > 1 ? (
        <BookingLeftSideSpaces previewUnits={previewUnits} />
      ) : (
        <LeftSideItem
          item={{
            title: 'Your space',
            children: (
              <Space
                units={previewUnits[0]?.units}
                previewFoodAndBeverage={previewUnits[0]?.foodAndBeverage}
                packagesPreview={previewUnits[0]?.selectedPackage}
              />
            ),
          }}
        />
      )}

      <LeftSideItem
        item={{
          title: 'About meeting',
          children: <AboutMeetingForm />,
        }}
        isHidden={!isMeetingRoom}
      />

      <LeftSideItem
        item={{
          title: 'Your details',
          subtitle: '(Required)',
          children: <UserDetails isShowSignInCard={isShowLoginModal} />,
          formValidationName: EFormValidationName.YOUR_DETAILS_BLOCK,
        }}
        isHidden={isDetailsHidden}
      />

      <LeftSideItem
        item={{
          title: 'Payment information',
          subtitle: '(Required)',
          children: <BookingPayment />,
          formValidationName: EFormValidationName.PAYMENT_BLOCK,
        }}
        isHidden={
          !isLoggedInUser || isOffice || !isMeetingRoom || !isHrsPayEnabled
        }
      />

      <LeftSideItem
        item={{
          title: 'Billing address',
          subtitle: '(Required)',
          children:
            hasBillingAddress || !!corporateBillingAddress ? (
              <BillingAddressViewWithReferenceField
                paymentType={paymentType}
                billingAddress={
                  isDirectPayment
                    ? defaultBillingAddress
                    : corporateBillingAddress
                }
              />
            ) : (
              <BillingAddressForm />
            ),
          formValidationName: EFormValidationName.BILLING_ADDRESS_BLOCK,
        }}
        isHidden={isOffice || (isCorporatePayment && !corporateBillingAddress)}
      />

      <LeftSideItem
        item={{
          title: 'Attendees',
          subtitle: '(Optional)',
          children: (
            <NW2BookingAttendees
              unit={singleVenueUnit}
              offerParticipants={participants}
            />
          ),
          formValidationName: EFormValidationName.ATTENDEES_BLOCK,
        }}
        isHidden={isAttendeesHidden}
      />

      {(previewDesks.length || (checkIn && venueDetails.accommodationId)) && (
        <LeftSideItem
          item={{
            title: 'Modification & Cancellation conditions',
            children: previewDesks.length ? (
              <CancelPolicyInfoCard
                text={
                  <CancellationPolicyTextElement
                    text={cancellationPolicyText}
                  />
                }
                componentType={
                  ECancelPolicyInfoCardComponent.ACTION_CARD_CLOSABLE
                }
              />
            ) : (
              <CancellationPolicyBlock
                eventType={eventType}
                participants={maxParticipants}
                hasCatering={hasCatering}
                checkIn={checkIn}
                venueId={venueDetails.accommodationId}
                isRTBText={isRtb}
              />
            ),
          }}
          isHidden={isOffice}
        />
      )}

      <NW2SpaceDetails isFooterHidden />
    </StyledLeftSideContainer>
  );
}

export default BookPreviewComponents;
