import {useMemo} from 'react';

import {useAppSelector} from 'store/hooks';
import {EEventType} from 'types/venue';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';

export const useSearchTypeBedrooms = () => {
  const multiSearchPayload = useAppSelector(
    ({search}) => search.multiSearchPayload,
  );

  const {preArrivals, postEvents} = useMemo(() => {
    return {
      preArrivals: getFilteredUnitsByEventType(
        multiSearchPayload,
        EEventType.PRE_ARRIVAL,
      ),
      postEvents: getFilteredUnitsByEventType(
        multiSearchPayload,
        EEventType.POST_EVENT,
      ),
    };
  }, [multiSearchPayload]);

  return {preArrivals, postEvents};
};
