import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {notification} from 'antd';

import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';
import NMMSubmitSection from 'view/components/NMMSubmitSection';
import RequestFormContactDetails from './components/RequestFormContactDetails/RequestFormContactDetails';
import RequestFormTripDetails from './components/RequestFormTripDetails/RequestFormTripDetails';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {convertExtraItem, getFilteredUnitsByEventType} from 'utils/venueUtils';
import {offsetXLg} from 'styles/configs/offset';
import {IRequestDay, IRequestFormPayload} from 'types/offer';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {IExtraResponse} from 'types/dto/IPublicVenue';
import {EEventType} from 'types/venue';
import {useSearchTypeBedrooms} from 'view/venue/Offer/hooks/useSearchTypeBedrooms';
import {convertArrivalItemsToPayload} from 'view/venue/Offer/helpers';
import {ERequestFormFields, IRequestFormValues} from '../types';
import {sendRequestForm} from 'store/offers/apiActions';

import {
  ButtonContainer,
  MobileWrapper,
  NotificationTitle,
} from './RequestForm.style';

const headerText = 'customer support request template';

interface IProps {
  onCloseOrderDetails: () => void;
}

const RequestForm = ({onCloseOrderDetails}: IProps) => {
  const dispatch = useAppDispatch();
  const {isLoggedInUser} = useLoggedInUser();
  const {postEvents, preArrivals} = useSearchTypeBedrooms();

  const user = useAppSelector(({app}) => app.user);
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const isLoading = useAppSelector(({offers}) => offers.isLoading);

  const multiSearchPayload = useAppSelector(
    ({search}) => search.multiSearchPayload,
  );
  const {isMobile, isTablet, isDesktop} = useAppSelector(
    ({app}) => app.deviceType,
  );

  const {meetingRoomCapacity, searchString} = searchCriteria;

  const onFormSubmit = useCallback(
    (values: IRequestFormValues) => {
      const venueDays = getFilteredUnitsByEventType(multiSearchPayload);

      const getDays = () => {
        const days = venueDays.map((day) => {
          const {
            checkIn,
            checkOut,
            foodAndBeverage = [],
            bedrooms,
            roomFilters,
            maxParticipants,
          } = day;

          return {
            checkIn,
            checkOut,
            eventType: EEventType.DAY,
            foodAndBeverage: convertExtraItem(foodAndBeverage),
            bedrooms: convertExtraItem(bedrooms as IExtraResponse[]),
            participants: maxParticipants,
            items: roomFilters?.map((unit) => ({
              checkIn: unit.checkIn,
              checkOut: unit.checkOut,
              setupStyle: unit.setupStyle,
              participants: unit.capacity || meetingRoomCapacity,
              extras: convertExtraItem(unit.extras),
            })),
          } as IRequestDay;
        });

        if (preArrivals.length) {
          convertArrivalItemsToPayload(preArrivals)
            .sort(
              (first, second) =>
                Number(new Date(second.checkIn)) -
                Number(new Date(first.checkIn)),
            )
            .forEach((item) => {
              days.unshift(item as IRequestDay);
            });
        }

        if (postEvents.length) {
          convertArrivalItemsToPayload(postEvents).forEach((item) => {
            days.push(item as IRequestDay);
          });
        }

        return days;
      };

      const requestDays = getDays();

      const {name, comment, company, destination, email, phone} = values;

      const requestDetails: IRequestFormPayload = {
        days: requestDays,
        customer: {
          id: user.id,
          fullName: name,
          email,
          company,
          phone,
        },
        destination,
        comment,
      };

      dispatch(
        sendRequestForm({
          payload: requestDetails,
          onSuccessHandler: () => {
            onCloseOrderDetails();
            notification.success({
              message: (
                <>
                  <NotificationTitle>
                    request sent successfully
                  </NotificationTitle>
                  <span>
                    Our support agents will contact you. A copy of the request
                    is sent to your email.
                  </span>
                </>
              ),
              duration: 4,
            });
          },
        }),
      );
    },
    [
      dispatch,
      meetingRoomCapacity,
      multiSearchPayload,
      onCloseOrderDetails,
      postEvents,
      preArrivals,
      user.id,
    ],
  );

  const formInitialValues = {
    [ERequestFormFields.DESTINATION]: searchString.split(',')[0],
    ...(isLoggedInUser && {
      [ERequestFormFields.NAME]: `${user.firstName} ${user.lastName}`,
    }),
    ...(isLoggedInUser && {[ERequestFormFields.EMAIL]: user.email}),
  };

  return (
    <>
      <Form
        onSubmit={onFormSubmit}
        initialValues={formInitialValues}
        mutators={{
          setValue: ([field, value], state, {changeValue}) => {
            changeValue(state, field, () => value);
          },
        }}
        keepDirtyOnReinitialize
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            {isMobile ? (
              <NW2ModalDrawer
                header={headerText}
                body={
                  <MobileWrapper>
                    <RequestFormContactDetails
                      isLoggedInUser={isLoggedInUser}
                    />
                    <RequestFormTripDetails
                      searchString={searchString}
                      isDesktop={isDesktop}
                      isTablet={isTablet}
                    />
                  </MobileWrapper>
                }
                footer={
                  <ButtonContainer>
                    <NMMSubmitSection
                      size='medium'
                      cancelLabel='cancel'
                      submitLabel='submit request'
                      handleSubmit={handleSubmit}
                      handleCancel={onCloseOrderDetails}
                      isLoading={isLoading}
                      gap={offsetXLg}
                      justifyContent='center'
                      margin='0'
                    />
                  </ButtonContainer>
                }
                isMobile={isMobile}
                onClose={onCloseOrderDetails}
                isShowed
              />
            ) : (
              <NW2ModalTwoColumns
                title={headerText}
                isShowed
                onToggleShow={onCloseOrderDetails}
                colLeft={
                  <RequestFormContactDetails
                    isLoggedInUser={isLoggedInUser}
                    isTablet={isTablet}
                  />
                }
                colRight={
                  <RequestFormTripDetails
                    searchString={searchString}
                    isDesktop={isDesktop}
                    isTablet={isTablet}
                  />
                }
                footer={
                  <ButtonContainer>
                    <NMMSubmitSection
                      size='medium'
                      cancelLabel='cancel'
                      submitLabel='submit request'
                      handleSubmit={handleSubmit}
                      handleCancel={onCloseOrderDetails}
                      isLoading={isLoading}
                      gap={offsetXLg}
                      justifyContent='center'
                      margin='0'
                    />
                  </ButtonContainer>
                }
              />
            )}
          </form>
        )}
      />
    </>
  );
};

export default RequestForm;
