import React, {useCallback, useMemo, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import _get from 'lodash/get';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import LeftSideItem from '../components/LeftSideContainer/LeftSideItem';

import {OfferRequestTotal} from './components/OfferRequestSecondStep/components/OfferRequestTotal';
import {OfferSteps} from './components/OfferSteps/OfferSteps';
import {WhatIsNext} from './components/WhatIsNext/WhatIsNext';

import {TImage} from 'types/app';
import {IDayResponse, IMultiDayPublicVenue} from 'types/dto/IPublicVenue';
import {ICustomer} from 'types/dto/IUser.types';
import {EEventType} from 'types/venue';
import {IBookingPreviewFormData} from 'store/venues/types';
import {setUserCustomer} from 'store/app/appSlice';
import {getDateTime} from 'utils/dateUtils';
import {findCoverImage} from 'utils/helpers';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {REQUEST_OFFER_LINK_TERMS_TEXT} from 'view/venue/Offer/constants';
import {useIntersectionObserver} from 'hooks/useIntersectionObserver';
import {useBookingInitialData} from '../NW2BookingPreview/hooks/useBookingInitialData';
import {
  StyledHeader,
  StyledMain,
  StyledTitle,
  StyledWrapper,
} from '../NW2BookingPreview/NW2BookingPreview.styles';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import ApiInventoryService from 'infra/common/apiInventoryService';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {convertArrivalItemsToPayload} from './helpers';
import {requestOffer} from 'store/offers/apiActions';
import {IOfferRequestDay, IRequestDay} from 'types/offer';
import {useGroupRequest} from '../hooks/useGroupRequest';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {useVenueDetailsData} from '../hooks/useVenueDetailsData';
import {convertExtraItem, getFilteredUnitsByEventType} from 'utils/venueUtils';
import {useSelectedRoomId} from '../hooks/useSelectedRoomId';
import {OfferRequestLeftSidePreview} from './components/OfferRequestLeftSidePreview/OfferRequestLeftSidePreview';
import {useSearchTypeBedrooms} from './hooks/useSearchTypeBedrooms';
import useParamsVenueId from '../hooks/useParamsVenueId';
import {useMultiDayVenueDetailsData} from '../NW2VenueDetails/useMultiDayVenueDetails';

export function OfferRequest() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {isMultiRequest, multiVenueIds} = useGroupRequest();
  const {isLoggedInUser} = useLoggedInUser();

  const user = useAppSelector(({app}) => app.user);
  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );
  const multiVenueDetails: IMultiDayPublicVenue[] = useSelector(
    (state) => _get(state, 'venuesReducer.multiVenueDetails'), // todo move multiVenueDetails to venueDetails store
  );

  const {venueDetails, isAlternative} = useVenueDetailsData();

  const venueZone = venueDetails?.location?.timeZone;

  const {currency, documents, location, name, accommodationId, totalPrice} =
    venueDetails;
  const {firstName, lastName, email, phone, companyName} = user;

  const venueAddress = getAddressStringFromLocation(location);
  const venueCoverImage = useMemo(
    () => findCoverImage(documents || ([] as TImage[])),
    [documents],
  );

  // get bedrooms
  const {postEvents, preArrivals} = useSearchTypeBedrooms();

  const [getSelectedVenueUnitId] = useSelectedRoomId();

  // request submit
  const onFormSubmit = useCallback(
    async (formData: IBookingPreviewFormData) => {
      const venueIds = isMultiRequest ? multiVenueIds : [accommodationId];
      const venueDays: IDayResponse[] = getFilteredUnitsByEventType(
        venueDetails.days,
      );

      const getDays = (venueId: number) => {
        const days = venueDays.map((day, dayIdx) => {
          const {
            checkIn,
            checkOut,
            foodAndBeverage,
            bedrooms,
            maxParticipants,
          } = day;

          const currentVenue = multiVenueDetails.find(
            ({accommodationId}) => accommodationId === venueId,
          );

          const selectedVenueUnitId = getSelectedVenueUnitId({
            venueId: currentVenue?.accommodationId || venueId,
            checkIn: day.checkIn,
          });

          const units = (
            isMultiRequest && currentVenue
              ? getFilteredUnitsByEventType(currentVenue.days)[dayIdx].rooms
              : day.rooms
          )
            .flatMap(({units}) => units)
            .filter(({isOptimalPrice, unitId}) =>
              selectedVenueUnitId
                ? unitId === selectedVenueUnitId
                : isOptimalPrice,
            );

          return {
            checkIn,
            checkOut,
            eventType: EEventType.DAY,
            foodAndBeverage: convertExtraItem(foodAndBeverage),
            bedrooms: convertExtraItem(bedrooms),
            participants: Math.max(
              maxParticipants,
              bedrooms.reduce((acc, b) => {
                acc += b.quantity;
                return acc;
              }, 0),
            ),
            items: units.map((unit) => ({
              ...(isAlternative ? {} : {unitId: unit.unitId}), // required for perfect match venues
              checkIn: unit.checkIn,
              checkOut: unit.checkOut,
              setupStyle: unit.requestedSetupStyle,
              participants: unit.requestedCapacity || participants, // participants for single request
              extras: convertExtraItem(unit.extras),
            })),
          } as IRequestDay;
        });

        if (preArrivals.length) {
          convertArrivalItemsToPayload(preArrivals)
            .sort(
              (first, second) =>
                Number(new Date(second.checkIn)) -
                Number(new Date(first.checkIn)),
            )
            .forEach((item) => {
              days.unshift(item as IRequestDay);
            });
        }

        if (postEvents.length) {
          convertArrivalItemsToPayload(postEvents).forEach((item) => {
            days.push(item as IRequestDay);
          });
        }

        return days;
      };

      // An optionDate set 18 hours, because this time will be static.
      // To see result of it => go to OfferOptionDate.tsx file.
      const requestDetails = venueIds.map((multiVenueId) => {
        return {
          accommodationId: Number(multiVenueId),
          days: getDays(multiVenueId),
          customer: {
            id: user.id,
          } as ICustomer,
          eventName: formData.aboutMeeting || '',
          optionDate:
            getDateTime(formData.optionDate)
              .setZone(venueZone)
              .set({hour: 18, minute: 0, second: 0, millisecond: 0})
              .toISO() || '',
        };
      });

      if (user.id && !phone && formData.phone) {
        // customer should be updated separately
        const updatedUser = await ApiInventoryService.updateCustomer(user.id, {
          firstName,
          lastName,
          email,
          phone: formData.phone.trim(),
          companyName,
        });

        dispatch(setUserCustomer(updatedUser));
      }

      dispatch(
        requestOffer({
          payload: requestDetails,
          onSuccessHandler: (id: string) => {
            navigate(`/group-overview/${id}`);
          },
        }),
      );
    },
    [
      accommodationId,
      companyName,
      dispatch,
      email,
      firstName,
      getSelectedVenueUnitId,
      isAlternative,
      isMultiRequest,
      lastName,
      multiVenueDetails,
      multiVenueIds,
      navigate,
      participants,
      phone,
      postEvents,
      preArrivals,
      user.id,
      venueDetails.days,
      venueZone,
    ],
  );

  // prevent keypress enter
  const onKeyPress = (e: React.KeyboardEvent) => {
    e.key === 'Enter' && e.preventDefault();
  };

  const {paramsVenueIds} = useParamsVenueId();
  const venueId = String(paramsVenueIds[0] ? paramsVenueIds : multiVenueIds[0]);

  useMultiDayVenueDetailsData(venueId, true);

  const {formInitialValues, onGoToVenueDetails} = useBookingInitialData({
    id: multiVenueIds[0],
    isOfferRequest: true,
    isMeetingRoom: true,
  });

  // handle sticky total behavior
  const headerRef = useRef(null);
  const headerEntry = useIntersectionObserver(headerRef, {});
  const isTotalSticky: boolean | undefined = !headerEntry?.isIntersecting;

  if (!accommodationId) return <NW2Loader label='Loading' height='100%' />;

  return (
    <StyledWrapper>
      <NW2Container>
        <StyledHeader ref={headerRef}>
          <NW2Button
            buttonType='secondary'
            minimized
            size='small'
            onClick={onGoToVenueDetails}
            icon={<Icon transparent icon='NW2_MOVE_BACK' />}
          />
          <StyledTitle>Request offer</StyledTitle>
        </StyledHeader>

        <OfferSteps activeStep={1} />

        <Form
          onSubmit={onFormSubmit}
          initialValues={formInitialValues}
          mutators={{
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          keepDirtyOnReinitialize
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} onKeyDown={onKeyPress}>
              <StyledMain>
                <div>
                  <OfferRequestLeftSidePreview
                    preArrivals={preArrivals}
                    postEvents={postEvents}
                    isShowLoginModal={!isLoggedInUser}
                    isAlternative={isAlternative || isMultiRequest}
                    isOfferRequest
                    isMeetingRoom
                  />

                  <LeftSideItem
                    item={{
                      title: 'What’s next',
                      children: <WhatIsNext isGroupRequest />,
                    }}
                  />
                </div>

                <OfferRequestTotal
                  currency={currency}
                  venueName={name}
                  venueCoverImage={venueCoverImage}
                  preArrivals={preArrivals as IOfferRequestDay[]}
                  postEvents={postEvents as IOfferRequestDay[]}
                  totalPrice={totalPrice}
                  isTotalSticky={isTotalSticky}
                  venueAddress={venueAddress}
                  linkTermsText={REQUEST_OFFER_LINK_TERMS_TEXT}
                  btnSubmitText='request offer'
                  isOfferRequest
                  isGroupRequest
                />
              </StyledMain>
            </form>
          )}
        />
      </NW2Container>
    </StyledWrapper>
  );
}
