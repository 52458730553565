import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import {setPageForVenuesSearch} from 'store/search/searchSlice';
import {VENUE_LIST_ITEMS_PER_PAGE} from 'constants/app';
import {IMergedVenue} from 'types/search';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {useAppSelector} from 'store/hooks';
import {useGetSearchDateDifference} from 'view/venue/hooks/useGetSearchDateDifference';
import {ALLOWED_DAYS_DIFFERENCE_FOR_RFP} from 'constants/days';
import LocalStorageService from 'infra/common/localStorage.service';

type TProps = {
  isFullListShowed: boolean;
};
function useFilterVenuesList({isFullListShowed}: TProps) {
  const dispatch = useDispatch();

  const page = useAppSelector(({search}) => search.searchCriteria.page || 0);
  const venueGlobalList: IMergedVenue[] = useSelector((state) =>
    _get(state, 'venuesReducer.venueGlobalList'),
  );

  const [diffInDays] = useGetSearchDateDifference();

  const [venuesList, setVenuesList] = useState<IMergedVenue[]>([]);

  const filteredGlobalList = useMemo(() => {
    const venuesWithPrice = venueGlobalList.filter(
      ({totalPrice}) => !!totalPrice,
    );

    const venuesWithoutPrice = venueGlobalList.filter(
      ({totalPrice}) => !totalPrice,
    );

    const sortByDistance = (a: IMergedVenue, b: IMergedVenue) => {
      // sorting by location distance
      if (Number(a.location.distance) < Number(b.location.distance)) return -1;
      if (Number(a.location.distance) > Number(b.location.distance)) return 1;

      return 0;
    };

    // venues with total price - lowest to highest
    const venuesSortedByPrice = venuesWithPrice.sort((a, b) => {
      if (a.totalPrice < b.totalPrice) return -1;
      if (a.totalPrice > b.totalPrice) return 1;

      // sort by location distance if same price
      if (Number(a.location.distance) < Number(b.location.distance)) return -1;
      if (Number(a.location.distance) > Number(b.location.distance)) return 1;

      return 0;
    });

    // venues onboarded (registered and PW) - per distance from POI, close to far
    const venuesOnboarded = venuesWithoutPrice
      .filter(({isOnboarded}) => isOnboarded)
      .sort(sortByDistance);

    // venues not onboarded with image - per distance from POI, close to far
    const venuesNotOnboardedWithImage = venuesWithoutPrice
      .filter(
        ({isOnboarded, coverImageLink}) => !isOnboarded && !!coverImageLink,
      )
      .sort(sortByDistance);

    // venues not onboarded without image - per distance from POI, close to far
    const venuesNotOnboardedWithoutImage = venuesWithoutPrice
      .filter(
        ({isOnboarded, coverImageLink}) => !isOnboarded && !coverImageLink,
      )
      .sort(sortByDistance);

    const groupedList = [
      ...venuesSortedByPrice,
      ...venuesOnboarded,
      ...venuesNotOnboardedWithImage,
      ...venuesNotOnboardedWithoutImage,
    ];

    return diffInDays < ALLOWED_DAYS_DIFFERENCE_FOR_RFP
      ? groupedList.filter(
          ({accommodationType, totalPrice}) =>
            !!totalPrice ||
            accommodationType === EAccommodationType.CORPORATE_OFFICE,
        )
      : groupedList;
  }, [diffInDays, venueGlobalList]);

  const splitVenues = (pageNumber: number, venues: IMergedVenue[]) => {
    const endValue = VENUE_LIST_ITEMS_PER_PAGE * (pageNumber + 1);
    return venues.slice(0, endValue);
  };

  const getVenuesWithPagination = useCallback(() => {
    const pageNumber = page + 1;
    const venues = splitVenues(pageNumber, filteredGlobalList);

    setVenuesList(venues);

    dispatch(setPageForVenuesSearch(pageNumber));
  }, [page, filteredGlobalList, dispatch]);

  useEffect(() => {
    if (isFullListShowed) {
      setVenuesList(filteredGlobalList);
    } else {
      const venues = splitVenues(0, filteredGlobalList);
      setVenuesList(venues);
    }

    /**
     * reset selectedVenue unit when we are getting new list
     */
    LocalStorageService.removeItemByKey('selectedVenueUnits');
  }, [isFullListShowed, filteredGlobalList]);

  return {
    getVenuesWithPagination,
    totalRecords: filteredGlobalList.length,
    venuesList,
  };
}

export default useFilterVenuesList;
