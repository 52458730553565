import React from 'react';

import LeftSideItemTitle from 'view/venue/components/LeftSideContainer/LeftSideItemTitle';
import {DefaultRequestSpace} from 'view/venue/NW2BookingPreview/components/Space/DefaultRequestSpace';

import {offsetXXLg} from 'constants/styleVars';
import {useAppSelector} from 'store/hooks';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {DefaultLeftSideItem} from 'view/venue/NW2BookingPreview/components/Space/Space.styles';

type TProps = {
  hasPreArrivals?: boolean;
  hasPostEvents?: boolean;
  hasDots?: boolean;
  isDesktop?: boolean;
};

export const DefaultRequestLeftSideSpaces = ({
  hasPreArrivals,
  hasPostEvents,
  hasDots,
  isDesktop,
}: TProps) => {
  const meetingDays = useAppSelector(({search}) =>
    getFilteredUnitsByEventType(search.multiSearchPayload),
  );

  const isMultiDay = meetingDays.length > 1;

  return (
    <>
      {meetingDays.map((day, dayIndex) => {
        const {
          checkIn,
          checkOut,
          bedrooms,
          roomFilters = [],
          foodAndBeverage = [],
          maxParticipants,
        } = day;

        const isDayWithMeeting = Boolean(roomFilters.length);
        const hasPreOrPostArrivals = Boolean(hasPreArrivals || hasPostEvents);

        const title = (
          <>
            <LeftSideItemTitle
              title={
                isMultiDay || hasPreOrPostArrivals
                  ? `DAY ${dayIndex + 1}`
                  : 'Your Request'
              }
              checkIn={checkIn}
              checkOut={checkOut}
              participants={maxParticipants}
              hasTimeRange={isDayWithMeeting}
            />
          </>
        );

        const children = (
          <DefaultRequestSpace
            units={roomFilters}
            foodAndBeverage={foodAndBeverage}
            bedrooms={bedrooms}
            checkIn={checkIn}
            hasDots={hasDots}
          />
        );

        return (
          <DefaultLeftSideItem
            key={dayIndex}
            item={{
              title,
              children,
            }}
            isHidden={false}
            paddingTop={
              hasPreArrivals && dayIndex === 0
                ? `${offsetXXLg} !important`
                : undefined
            }
            paddingBottom={
              !hasPostEvents && dayIndex === meetingDays.length - 1
                ? undefined
                : `${offsetXXLg} !important`
            }
            isNoBorderTop={hasPreArrivals}
            isDesktop={isDesktop}
          />
        );
      })}
    </>
  );
};
