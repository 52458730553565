import React, {SyntheticEvent, useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';
import SummaryRequestInfo from './components/SummaryRequestInfo';
import RequestDetailsInfo from 'view/venue/Offer/components/OfferRequestDetails/RequestDetailsInfo';
import NMMSubmitSection from 'view/components/NMMSubmitSection';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import {DeclineModal} from 'view/components/RequestDecline/DeclineModal';

import {useAppSelector} from 'store/hooks';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {
  getExpiresIn,
  getHoldUp,
  getTimeFromRequestedToCancelled,
} from 'utils/helpers';
import {useCommonExtras} from 'view/venue/hooks/useCommonExtras';
import {getAllBedrooms} from 'view/venue/Offer/helpers';
import {
  FooterTotalPrice,
  FooterTotalSubTitle,
  FooterTotalTitle,
  FooterWrapper,
} from './BookingDetails.styles';
import {offsetDef} from 'styles/configs/offset';
import {EDeclineItemType, ERequestStatus} from 'types/offer';
import {IRfpHandlers} from 'view/supplier/hooks/useRfpHandlers';
import {IOfferHandlers} from 'view/venue/Offer/hooks/useOfferHandlers';
import {RootState} from 'store/types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

interface IProps {
  venueTimeZone: string;
  alreadyVisitedDetails?: string[];
  isOffer: boolean;
  changeHandlers: {
    rfpHandlers: IRfpHandlers;
    offerHandlers: IOfferHandlers;
  };
  onCloseOrderDetails: () => void;
  onShowDeclineModal: (orderId: string) => (e?: SyntheticEvent) => void;
  onShowOfferCreate: (
    requestId: string,
    hasData?: boolean,
  ) => (e?: SyntheticEvent) => void;
  onShowOrderDetails: (
    orderId: string,
    orderNumber: string,
    isRequest?: boolean,
  ) => () => void;
}

const BookingRequestDetails = ({
  venueTimeZone,
  isOffer,
  changeHandlers,
  onShowDeclineModal,
  onCloseOrderDetails,
  onShowOfferCreate,
  onShowOrderDetails,
}: IProps) => {
  const requestDetails = useAppSelector(({offers}) => offers.offerDetails);

  // TODO: Remove with updates of Agent View
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  // Collecting dates from days
  const dates: string[] = useMemo(() => {
    const requestDays = requestDetails.days;
    const result: string[] = [];

    if (!requestDays) return result;

    const filteredRequestDays = getFilteredUnitsByEventType(requestDays);

    filteredRequestDays.forEach((item) => {
      result.push(item.checkIn, item.checkOut);
    });

    return result;
  }, [requestDetails?.days]);

  useCommonExtras();

  if (!requestDetails.id) return null;

  const {
    id,
    totalRequestPrice,
    price,
    createdAt,
    status,
    cancelledAt,
    customer,
    number,
    checkIn,
    checkOut,
    expirationDate,
    days,
  } = requestDetails;

  const holdUp = getHoldUp(createdAt);
  const expiresIn = getExpiresIn(expirationDate);

  const timeFromRequestedToCancelled = getTimeFromRequestedToCancelled(
    createdAt,
    cancelledAt,
  );

  // First check always must be totalRequestPrice
  const totalPrice = totalRequestPrice?.value || price?.value;
  const totalPriceAndCurrency = totalPrice ? (
    <PriceDisplay price={totalPrice} currency={price.currency} />
  ) : (
    '-'
  );

  const isRequestWithBedroom = !isOffer && !!getAllBedrooms(days).length;

  return (
    <>
      <NW2ModalTwoColumns
        title={isOffer ? 'Offer summary' : 'Request summary'}
        isShowed
        onToggleShow={onCloseOrderDetails}
        colLeft={
          <SummaryRequestInfo
            bookingRequestDetails={requestDetails}
            dates={dates}
            totalPriceAndCurrency={totalPriceAndCurrency}
            expiresIn={expiresIn}
            isOffer={isOffer}
            isRequestWithBedroom={isRequestWithBedroom}
          />
        }
        colRight={
          <RequestDetailsInfo
            currency={price?.currency}
            totalPriceAndCurrency={totalPriceAndCurrency}
            venueTimeZone={venueTimeZone}
            holdUp={timeFromRequestedToCancelled}
            isOffer={isOffer}
            isRequestWithBedroom={isRequestWithBedroom}
          />
        }
        footer={
          status === ERequestStatus.REQUEST_PENDING && (
            <FooterWrapper withPrice={!isRequestWithBedroom}>
              {!isRequestWithBedroom && (
                <>
                  <div>
                    <FooterTotalTitle>Potential worth</FooterTotalTitle>
                    <FooterTotalSubTitle>
                      Includes taxes and fees
                    </FooterTotalSubTitle>
                  </div>

                  <FooterTotalPrice>{totalPriceAndCurrency}</FooterTotalPrice>
                </>
              )}

              {!isAgent && (
                <div>
                  <NMMSubmitSection
                    submitLabel='Propose'
                    cancelLabel='Decline'
                    handleSubmit={onShowOfferCreate(id, true)}
                    handleCancel={onShowDeclineModal(id)}
                    isLoading={false}
                    gap={offsetDef}
                    size='medium'
                    margin='0'
                  />
                </div>
              )}
            </FooterWrapper>
          )
        }
      />

      {!isAgent && (
        <DeclineModal
          orderId={id}
          headerText='cancel offer & decline'
          changeHandlers={changeHandlers}
          checkInDate={checkIn}
          checkOutDate={checkOut}
          company={customer.companyName}
          currency={price.currency}
          holdUp={holdUp}
          declineItem={EDeclineItemType.OFFER}
          isOffer={isOffer}
          orderNumber={number}
          totalPrice={price?.value}
          onShowOrderDetails={onShowOrderDetails}
        />
      )}
    </>
  );
};

export default BookingRequestDetails;
