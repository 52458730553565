import {INITIAL_RADIUS, VENUE_LIST_LIMIT} from 'constants/app';
import {IVenuesListResponse, TSearchVenuesPayload} from 'types/search';
import {ApiType, IApi} from '../common/http.service';
import {IVenueDetailsData} from 'store/venues/actions';

const workDeskRepository = (api: IApi) => ({
  searchWorkDesks: async ({
    searchString,
    latitude,
    longitude,
    startDate,
    endDate,
    limit = VENUE_LIST_LIMIT,
    radius = INITIAL_RADIUS,
    corporateAccountId,
  }: TSearchVenuesPayload): Promise<IVenuesListResponse> =>
    await api.get(
      ApiType.WorkDesksSearch,
      `accommodations/?searchString=${searchString}&latitude=${latitude}&longitude=${longitude}
      &startDate=${startDate}&endDate=${endDate}&page=0&limit=${limit}${
        corporateAccountId ? `&corporate-account-id=${corporateAccountId}` : ''
      }&radius=${radius}`,
    ),

  getWorkDesksDetails: async (data: IVenueDetailsData): Promise<any> => {
    const commonUrl = `venues/details?id=${data.id}&startDate=${data.startDate}&endDate=${data.endDate}`;
    try {
      return await api.get(ApiType.Inventory, commonUrl);
    } catch (error) {
      return Promise.reject(error);
    }
  },
});

export default workDeskRepository;
