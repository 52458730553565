import React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import Icon from 'view/components/Icon';

import {setIsGoogleMapExpanded} from 'store/venues/actions';
import {whiteColor} from 'constants/styleVars';

const Container = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${whiteColor};
  border-radius: 50%;

  &:hover,
  &:hover * {
    cursor: pointer;
  }
`;

const NW2MapExpandedModeToggler = () => {
  const dispatch = useDispatch();

  const isGoogleMapExpanded: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.isGoogleMapExpanded'),
  );

  const onClick = () => dispatch(setIsGoogleMapExpanded(!isGoogleMapExpanded));

  return (
    <Container onClick={onClick}>
      <Icon icon={isGoogleMapExpanded ? 'COLLAPSE' : 'EXPAND'} />
    </Container>
  );
};

export default NW2MapExpandedModeToggler;
